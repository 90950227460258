define('apollo/pods/components/transport/driver-section/component', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        store: service(),

        countries: (function () {
            return this.get('store').findAll('country').then(function (items) {
                return items.sortBy('ibanSymbol');
            });
        }).property(),

        actions: {
            setDriverData: function setDriverData(item) {
                this.get("transport").get("advice").setProperties({
                    driverName: item.driverName,
                    driverIdentifier: item.driverIdentifier,
                    driverMobilePhoneNumber: item.driverMobilePhoneNumber,
                    carRegistrationNumber: item.carRegistrationNumber,
                    trailerRegistrationNumber: item.trailerRegistrationNumber
                });
            },

            setDriverName: function setDriverName(name) {
                this.get("transport").get("advice").set("driverName", name);
            }
        }
    });
});