define("apollo/pods/task/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {
    exports["default"] = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {

        i18n: Ember.inject.service(),

        workflow: _emberData["default"].belongsTo('workflow', { async: true, inverse: 'tasks' }),

        enabled: _emberData["default"].attr('boolean'),
        canBeConfirmed: _emberData["default"].attr('boolean'),
        canBeConfirmedByGuard: _emberData["default"].attr('boolean'),
        taskGroupIdentifier: _emberData["default"].attr('number'),
        idxInGroup: _emberData["default"].attr('number'),
        tracked: _emberData["default"].attr('boolean'),
        stepType: _emberData["default"].attr('string'),

        checkpointName: _emberData["default"].attr('string'),
        checkpointNameEn: _emberData["default"].attr('string'),
        stageName: _emberData["default"].attr('string'),
        stageNameEn: _emberData["default"].attr('string'),
        color: _emberData["default"].attr('string'),

        blocksTransportEdition: _emberData["default"].attr('boolean'),
        blocksWindowEdition: _emberData["default"].attr('boolean'),

        expectedDateInterval: _emberData["default"].attr('string'),
        expectedDateConstantHourAndMinute: _emberData["default"].attr('string'),
        expectedDateReference: _emberData["default"].attr('string'),
        acceptableDelayInterval: _emberData["default"].attr('string'),

        validations: {
            stepType: {
                custom: [{
                    validation: function validation(key, value, model) {
                        if (model.get('taskGroupIdentifier') === 1 && !value) {
                            return false;
                        }
                        return true;
                    },
                    message: 'blank'
                }]
            },
            checkpointName: {
                presence: { message: 'blank' }
            },
            expectedDateReference: {
                presence: { message: 'blank' }
            }
        },

        expectedDateIntervalSign: (function () {
            var interval = this.get('expectedDateInterval');
            if (!interval) {
                return null;
            }

            return interval[0] === '-' ? 'before' : 'after';
        }).property('expectedDateInterval'),

        expectedDateIntervalUnit: (function () {
            var interval = this.get('expectedDateInterval');
            if (!interval) {
                return null;
            }

            return interval[interval.length - 1];
        }).property('expectedDateInterval'),

        expectedDateIntervalModulo: (function () {
            var interval = this.get('expectedDateInterval');
            if (!interval) {
                return null;
            }

            var unit = this.get('expectedDateIntervalUnit');
            return parseInt(interval.replace('-', '').replace(unit, ''));
        }).property('expectedDateInterval'),

        expectedDateIntervalUnitChanged: (function () {
            var unit = this.get('expectedDateIntervalUnit');
            var sign = this.get('expectedDateIntervalSign') === 'before' ? '-' : '';
            var number = this.get('expectedDateIntervalModulo');
            var newInterval = sign + number + unit;

            this.set('expectedDateInterval', newInterval);
        }).observes('expectedDateIntervalUnit', 'expectedDateIntervalSign', 'expectedDateIntervalModulo'),

        expectedDateMode: (function () {
            return this.get('expectedDateConstantHourAndMinute') ? 'exact' : 'reference';
        }).property('expectedDateConstantHourAndMinute'),

        defaultStageName: (function () {
            return this.get('checkpointName') ? this.get('stageName') || this.get('i18n').t('task.defaultStagePrefix') + ' ' + this.get('checkpointName') : this.get('i18n').t('task.stageName');
        }).property('stageName', 'checkpointName'),

        checkpointNameInAppropriateLanguage: (function () {
            if (this.get('i18n.locale') !== 'pl' && this.get('checkpointNameEn')) {
                return this.get('checkpointNameEn');
            } else {
                return this.get('checkpointName');
            }
        }).property('checkpointName', 'checkpointNameEn')
    });
});