define('apollo/pods/users/show/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        proxiedWarehouses: (function () {
            var self = this;

            return this.get('model').get('warehouses').map(function (w) {
                var proxiedRamps = self.get('model').get('ramps').filter(function (r) {
                    return r.get('warehouse.id') === w.get('id');
                });

                return _ember['default'].ObjectProxy.create({
                    content: w,
                    ramps: proxiedRamps
                });
            });
        }).property('model.warehouses.@each', 'model.ramps.@each.id')
    });
});