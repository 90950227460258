define("apollo/pods/ramps/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        model: function model(params) {
            return this.store.findRecord('ramp', params.id, { reload: true });
        },

        renderTemplate: function renderTemplate() {
            this.render('ramps.create');
        },

        setupController: function setupController(controller, model) {
            this.controllerFor('ramps.create').setProperties({ content: model });
        },

        actions: {
            willTransition: function willTransition() {
                this.controllerFor('ramps.create').setProperties({
                    success: false,
                    errors: []
                });
                return true;
            }
        }
    });
});