define("apollo/pods/companies/create/controller", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        sessionAccount: service('session-account'),
        i18n: _ember["default"].inject.service(),

        companiesIndexController: _ember["default"].inject.controller('companies/index'),

        isEditing: (function () {
            return this.get('model').get('id') != null;
        }).property('model.id'),

        errors: [],

        countries: (function () {
            return this.store.findAll('country');
        }).property(),

        availableTypes: (function () {
            return this.store.findAll('partnershipType');
        }).property(),

        actions: {
            setCountry: function setCountry(country) {
                this.get('model').setProperties({
                    oldCountry: this.get('model').get('country'),
                    country: country
                });
            },

            selectCountry: function selectCountry(id) {
                var self = this;
                this.store.findRecord("country", id).then(function (country) {
                    self.get("model").set("country", country);
                });
            },

            save: function save() {
                var self = this;
                var companyModel = this.get('model');

                if (!companyModel.validate()) {
                    return;
                }

                companyModel.save().then(function (company) {
                    self.set('errors', []);
                    console.log('company saved');
                    if (self.get('isEditing')) {
                        self.transitionToRoute('companies.show', company.id);
                    } else {
                        self.transitionToRoute('companies.index');
                    }
                })["catch"](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.get('model').unloadRecord();
                this.transitionToRoute('companies.index');
            },

            disable: function disable() {
                var self = this;
                var companyModel = this.get('model');

                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/companies/' + companyModel.get('id') + '/disable',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    companyModel.set('enabled', false);
                    var msg = self.get('i18n').t('company.deletedMessage', { companyName: companyModel.get('shortName') });
                    self.get('companiesIndexController').set('successMessage', msg);
                    console.log("Poprawnie usunęliśmy firmę " + companyModel.get('name') + ".");
                    self.transitionToRoute('companies.index');
                }, function (response) {
                    self.set('errors', response.errors);
                });
            }
        }
    });
});