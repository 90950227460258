define('apollo/pods/settings/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        success: false,
        errors: [],

        actions: {
            save: function save() {
                var self = this;
                var setting = this.get('model');
                if (setting.get('settingValueType') === 'boolean') {
                    setting.set('value', $('#settingCheckbox').is(':checked'));
                }
                setting.save().then(function () {
                    self.set('success', true);
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.get('model').rollbackAttributes();
                this.transitionToRoute('settings.index');
            }
        }
    });
});