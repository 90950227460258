define('apollo/pods/components/popup-dialog/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            closeDialog: function closeDialog() {
                this.sendAction('removePopupDialog');
            }
        },
        show: (function () {
            this.$('.popup-dialog').dialog({
                dialogClass: 'popup-dialog-container',
                modal: false,
                backdrop: 'static',
                position: { my: "left top", at: "right bottom" }
            }).on('dialogclose', (function () {
                this.sendAction('close');
            }).bind(this));
        }).on('didInsertElement')
    });
});