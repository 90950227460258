define('apollo/pods/workflow/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            tasks: { embedded: 'save' }
        },

        serializeIntoHash: function serializeIntoHash(data, type, record, options) {
            console.log('serializeIntoHash');
            var object = this.serialize(record, options);
            for (var key in object) {
                data[key] = object[key];
            }
        },

        serializeHasMany: function serializeHasMany(record, json, relationship) {
            console.log('serializeHasMany');
            var hasManyRecords, key;
            key = relationship.key;
            hasManyRecords = record.hasMany(key);
            if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === "save") {
                json[key] = [];
                hasManyRecords.forEach(function (item) {
                    // use includeId: true if you want the id of each model on the hasMany relationship
                    var data = item.attributes();
                    data.id = item.id;
                    json[key].push(data);
                });
            } else {
                this._super(record, json, relationship);
            }
        }
    });
});