define("apollo/pods/auth/login/route", ["exports", "ember", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin["default"], {

        renderTemplate: function renderTemplate(controller, model) {
            this.render('auth/login', {
                into: 'application',
                outlet: 'login',
                controller: controller,
                model: model
            });
        },

        setupController: function setupController(controller) {
            controller.set('errorMessage', null);
        }
    });
});