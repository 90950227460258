define("apollo/pods/components/window-note-button/component", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        openNoteWindow: "openNoteWindow",
        actions: {
            openNoteWindow: function openNoteWindow() {
                this.window.oldNote = this.window.get('note');
                this.sendAction("openNoteWindow", this.window);
            }
        }
    });
});