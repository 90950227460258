define('apollo/services/clock', ['exports', 'ember', 'ember-cli-clock/services/legacy'], function (exports, _ember, _emberCliClockServicesLegacy) {
  exports['default'] = _emberCliClockServicesLegacy['default'].extend({
    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].deprecate('Usage of the `clock` service is deprecated. ' + 'Please generate your own clock service with `ember generate clock <name>`.', false, {
        id: 'ember-cli-clock.deprecate-legacy-clock-service',
        until: '3.0.0'
      });
    }
  });
});