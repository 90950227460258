define('apollo/pods/setting/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        name: _emberData['default'].attr('string'),
        value: _emberData['default'].attr('string'),
        dateCreated: _emberData['default'].attr('date'),
        lastUpdated: _emberData['default'].attr('date'),

        settingValueType: (function () {
            var settingValue = this.get('value');
            if (settingValue === 'true' || settingValue === 'false') {
                return 'boolean';
            }
        }).property('value'),

        isChecked: (function () {
            var settingValue = this.get('value');
            return settingValue === 'true';
        }).property('value')
    });
});