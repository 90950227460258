define("apollo/pods/warehouses/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        sessionAccount: service('session-account'),
        access: ['ROLE_SETTINGS_TAB'],
        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },
        model: function model(params) {
            return this.store.findRecord('warehouse', params.id);
        },

        renderTemplate: function renderTemplate() {
            this.render('warehouses.create');
        },

        setupController: function setupController(controller, model) {
            this.controllerFor('warehouses.create').setProperties({ content: model });
        },

        actions: {
            willTransition: function willTransition() {
                this.get('controller').setProperties({
                    success: false,
                    errors: []
                });
                return true;
            }
        }
    });
});