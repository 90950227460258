define("apollo/pods/users/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {

        sessionAccount: service('session-account'),
        access: ['ROLE_EDIT_USER'],

        model: function model(params, transition) {
            var currentUser = this.get('sessionAccount').get('currentUser');
            var hasAnyOfTheRoles = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));

            if (params.user_id === currentUser.get('id') || hasAnyOfTheRoles) {
                return this.store.findRecord('user', params.user_id);
            } else {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        renderTemplate: function renderTemplate() {
            this.render('users.create');
        },

        setupController: function setupController(controller, model) {
            this.controllerFor('users.create').setProperties({ content: model });
        }
    });
});