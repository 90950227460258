define('apollo/pods/components/transport/steps-section/component', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        store: _ember['default'].inject.service(),
        sessionAccount: service('session-account'),

        actions: {
            addStepAt: function addStepAt(type, idx) {
                // TODO: [pawel] spr., czy type jest poprawny
                console.log('idx: ' + idx);
                var store = this.get('store');
                var transport = this.get('transport');

                idx = idx !== undefined ? idx : transport.get('steps.length'); // TODO: [pawel] poprawić, gdy będzie opcja dodawania stepów dowolnego typu (na razie zawsze jest to 'load')

                var address = store.createRecord('address', {});

                var step = this.get('store').createRecord('step', {
                    ownedByPrincipal: false,
                    stepTypeName: type,
                    orderInTransport: idx,
                    address: address
                });

                // Musimy przesunąć niektóre stepy (np. po dodaniu załadunku, wszystkie rozładunki muszą zmienić kolejność)
                transport.get('steps').filter(function (s) {
                    return s.get('orderInTransport') >= idx;
                }).forEach(function (s) {
                    var currentIdx = s.get('orderInTransport');
                    s.set('orderInTransport', currentIdx + 1);
                });

                console.log('Dodajemy step do transportu ' + transport.id + ' na miejscu ' + idx + '..');
                transport.get('steps').pushObject(step);
            }
        }
    });
});