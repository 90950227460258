define('apollo/pods/transport-log/model', ['exports', 'ember-data/model', 'ember-data'], function (exports, _emberDataModel, _emberData) {
    exports['default'] = _emberDataModel['default'].extend({
        transport: _emberData['default'].belongsTo('transport', { async: true }),
        newValue: _emberData['default'].attr('string'),
        oldValue: _emberData['default'].attr('string'),
        dateCreated: _emberData['default'].attr('date'),
        propertyName: _emberData['default'].attr('string'),
        actor: _emberData['default'].attr('string'),
        className: _emberData['default'].attr('string'),
        eventName: _emberData['default'].attr('string'),
        propertyNameType: _emberData['default'].attr('string'),

        newValueFormat: (function () {
            if (this.get('propertyNameType') == "java.util.Date") {
                var date = this.get('newValue') ? this.get('newValue').replace(/CET|CEST/, "") : '';
                return date ? moment(date).format("DD-MM-YYYY HH:mm") : 'X';
            } else if (this.get('propertyNameType') == "java.lang.Boolean") {
                return this.get('newValue') === 'true' ? '✓' : 'x';
            } else {
                return this.get('newValue');
            }
        }).property('propertyNameType', 'newValue'),

        oldValueFormat: (function () {
            if (this.get('propertyNameType') == "java.util.Date") {
                var date = this.get('oldValue') ? this.get('oldValue').replace(/CET|CEST/, "") : '';
                return date ? moment(date).format("DD-MM-YYYY HH:mm") : 'X';
            } else if (this.get('propertyNameType') == "java.lang.Boolean") {
                return this.get('oldValue') === 'true' ? '✓' : 'x';
            } else {
                return this.get('oldValue');
            }
        }).property('propertyNameType', 'oldValue')
    });
});