define("apollo/pods/workflow/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {
    exports["default"] = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {

        tasks: _emberData["default"].hasMany('task', { async: true }),

        name: _emberData["default"].attr('string'),
        transportType: _emberData["default"].belongsTo('transportType', { async: true }),

        validations: {
            tasks: {
                relations: ['hasMany']
            }
        },

        sortedTasks: (function () {
            return this.get('tasks').sortBy('taskGroupIdentifier', 'idxInGroup');
        }).property('tasks.@each.idxInGroup')
    });
});