define("apollo/mixins/schedule-mixin", ["exports", "ember", "apollo/mixins/persistence-mixin"], function (exports, _ember, _apolloMixinsPersistenceMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Mixin.create(_apolloMixinsPersistenceMixin["default"], {

        cookies: service(),

        DEFAULT_WINDOW_LENGTH_IN_MINUTES: (function () {
            return 60;
        }).property().readOnly(),

        showErrors: (function () {
            if (this.get('errors.length')) {
                this.send('showPopupDialog', 'dialogs.message-popup-dialog', this.get('errors'));
            }
        }).observes('errors'),

        hideErrors: (function () {
            this.send('removePopupDialog');
        }).observes('warehouse.id'),

        shownDays: (function () {
            var days = [];
            for (var i = 0; moment(this.scheduleStartDate).add(i, 'days') < this.scheduleEndDate; i++) {
                days[i] = {
                    date: moment(this.scheduleStartDate).add(i, 'days'),
                    name: moment(this.scheduleStartDate).add(i, 'days').format("YYYY-MM-DD"),
                    longName: moment(this.scheduleStartDate).add(i, 'days').format("DD.MM.YYYY dddd")
                };
            }
            return days;
        }).property('scheduleStartDate', 'scheduleEndDate'),

        rampsIds: (function () {
            var ids = [];
            this.get('warehouse.ramps').forEach(function (r) {
                ids.push(r.get('id'));
            });
            return ids;
        }).property('warehouse.ramps.@each.id'),

        maxDate: (function () {
            return moment().startOf('day').add(14, 'days');
        }).property(),

        rampStyle: (function () {
            var width = this.get('rampWidth') + '%';
            return _ember["default"].String.htmlSafe("width: " + width);
        }).property('rampWidth'),

        rampWidth: (function () {
            return "" + 100 / this.get('sortedRamps.length');
        }).property('sortedRamps.length'),

        dayStyle: _ember["default"].computed('dayWidth', function () {
            var width = this.get('dayWidth') + '%';
            return _ember["default"].String.htmlSafe("width: " + width);
        }),

        dayWidth: (function () {
            return 100 / this.get("shownDays.length");
        }).property('scheduleStartDate', 'scheduleEndDate'),

        weekStyle: _ember["default"].computed('sortedRamps', 'shownDays', function () {
            var width = 100 + '%';
            if (this.get('sortedRamps.length') > 8 && this.get('shownDays.length') > 1) {
                width = 200 + '%';
            }
            return _ember["default"].String.htmlSafe("width: " + width);
        }),

        selectedDateISO: (function () {
            return moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
        }).property('scheduleStartDate'),

        filteredWindows: (function () {
            var self = this;
            return this.get('windows').filter(function (w) {
                return w.get('start') >= self.get('scheduleStartDate') && w.get('start') < self.get('scheduleEndDate') || w.get('stop') > self.get('scheduleStartDate') && w.get('stop') <= self.get('scheduleEndDate');
            });
        }).property('scheduleStartDate', 'scheduleEndDate', 'windows.@each'),

        getStartDate: function getStartDate(div) {
            var $div = $(div);
            var minutes = $div.data('schedule-minute');
            var $hourDiv = $div.parent();
            var hours = $hourDiv.data('schedule-hour');
            var $dayDiv = $hourDiv.parent();
            var date = $dayDiv.parent().data('schedule-date');
            var time = moment(date, 'YYYY-MM-DD');
            time.add(hours, 'hours');
            time.add(minutes, 'minutes');
            return time.toDate();
        },

        timeWindowOffsetIntervalSetting: (function () {
            var principalCompanyId = this.get('cookies').read('principalId');
            return this.store.queryRecord('setting', {
                principalCompanyId: principalCompanyId,
                name: 'TIME_WINDOW_OFFSET_INTERVAL'
            });
        }).property(),

        minutesArray: (function () {
            var self = this;
            this.get('timeWindowOffsetIntervalSetting').then(function (s) {
                self.set('minutesArray', new Array(0));
                for (var i = 0; i < 60 / s.get('value'); i++) {
                    self.get('minutesArray').push(i * s.get('value'));
                }
            });
        }).property('timeWindowOffsetIntervalSetting'),

        actions: {
            spotClicked: function spotClicked(d, r, h, m) {
                console.log("spotClicked");
                var selector = "div[data-schedule-date='" + d + "'] div[data-schedule-ramp='" + r + "'] div[data-schedule-hour='" + h + "'] div[data-schedule-minute='" + m + "']";
                var $div = $(selector);

                this.send('handleClick', $div);
            },

            dedicatedSpotClicked: function dedicatedSpotClicked(event) {
                console.log("dedicatedSpotClicked");
                var $dedicatedWindowDiv = $(document.elementFromPoint(event.clientX, event.clientY));
                if (!$dedicatedWindowDiv.closest(".window-state-dedicated")) {
                    return;
                }
                $dedicatedWindowDiv.hide();
                var $spotDiv = this.findMinutesDiv(event.clientX, event.clientY);
                $dedicatedWindowDiv.show();

                this.send('handleClick', $spotDiv);
            },

            handleClick: function handleClick($div) {
                if (!$div.hasClass('minutes-' + this.get('timeWindowOffsetIntervalSetting.value'))) {
                    // akceptujemy tylko (w tej metodzie) kliknięcia w puste miejsce w harmonogramie
                    return;
                }
                if (($div.find(".window-state-dedicated").length || $div.find(".window-state-dedicated-template").length) && !this.get('transportToSave')) {
                    // kliknięcie w okno dedykowane tworzy awizację tylko, gdy jesteśmy w trakcie zapisywania awizacji
                    return;
                }
                var start = this.getStartDate($div);
                var stop = moment(start).add(this.get("DEFAULT_WINDOW_LENGTH_IN_MINUTES"), "minutes").toDate();
                var rampId = this.getRampId($div);
                var initialStart = this.getStartDate($div);
                console.log("createWindow: " + start + " - " + stop + ", " + rampId + "..");
                this.send('createWindow', start, stop, rampId, initialStart);
            },

            onResize: function onResize(event, ui) {
                var self = this;

                var element = $(ui.element);
                var windowId = element.data("schedule-window");

                var left = element.position().left;
                var top = element.offset().top + element.outerHeight() - $(document).scrollTop();

                var div = this.findMinutesDiv(left + 10, top + 2);
                var newStop;
                if (div) {
                    newStop = this.getDate(div);
                } else {
                    newStop = moment(this.get('scheduleStartDate')).startOf('d').add(this.get('warehouse').get('workEndHour'), 'h').toDate();
                }

                var windowModel = this.store.peekRecord('timeWindow', windowId);
                var oldStop = windowModel.get('stop');
                windowModel.set('stop', newStop);

                var canResize = false;
                if (windowModel.get('isDedicated') && this.get('sessionAccount').hasRole('ROLE_RESIZE_DEDICATED_WINDOWS')) {
                    canResize = true;
                } else if (windowModel.get('isBlocked') && this.get('sessionAccount').hasRole('ROLE_RESIZE_BLOCKED_WINDOWS')) {
                    canResize = true;
                } else if (this.get('sessionAccount').hasRole('ROLE_RESIZE_ADVICE_WINDOWS')) {
                    canResize = true;
                }

                if (!canResize) {
                    windowModel.set('stop', oldStop);
                    self.replaceTimeWindow(windowModel);
                    return;
                }

                if (self.get('isTemplate')) {
                    if (this.modelOverlapsAnyWindow(windowModel)) {
                        console.log("windows overlap");
                        windowModel.set('stop', oldStop);
                    }

                    self.replaceTimeWindow(windowModel);
                    self.send('removePopupDialog');
                    self.set("inProgress", false);
                    return;
                }

                windowModel.save().then(function (w) {
                    self.send('removePopupDialog');
                    self.reloadTimeWindow(windowId);

                    w.get('transport').then(function (t) {
                        self.unloadDeletedRecords('transport', t.id, 'checkpoints').then(function () {
                            if (t) {
                                t.reload();
                            }
                        });
                    });
                }, function (error) {
                    console.log("error: " + error);
                    windowModel.rollbackAttributes();
                    windowModel.save();
                    self.reloadTimeWindow(windowId);
                });
            },

            onDraggableStop: function onDraggableStop(event) {
                var self = this,
                    target = $(event.target),
                    windowId = target.data("schedule-window"),
                    left = target.position().left,
                    top = target.position().top - $(document).scrollTop(),
                    bottom = target.height() + top,
                    isSupplement = target.data('is-supplement');

                this.set("inProgress", true);

                var windowModel = this.store.peekRecord('timeWindow', windowId);

                var oldRamp = windowModel.get('ramp'),
                    oldStart = windowModel.get('start'),
                    oldStop = windowModel.get('stop'),
                    currentLengthInMS = windowModel.get('stop') - windowModel.get('start');

                var minutesDiv, $minutesDiv, newStart, newStop;

                if (!isSupplement) {
                    minutesDiv = this.findMinutesDiv(left + 10, top + 2);
                    $minutesDiv = $(minutesDiv);
                    newStart = this.getDate($minutesDiv);
                    newStop = moment(newStart).add(currentLengthInMS, 'milliseconds').toDate();
                }

                if (!minutesDiv) {
                    minutesDiv = this.findMinutesDiv(left + 10, bottom + 2);
                    $minutesDiv = $(minutesDiv);
                    newStop = this.getDate($minutesDiv);
                    newStart = moment(newStop).subtract(currentLengthInMS, 'milliseconds').toDate();
                }
                var rampId = this.getRampId($minutesDiv);

                if (newStart.getTime() === oldStart.getTime() && newStop.getTime() === oldStop.getTime() && oldRamp.get('id') === rampId.toString()) {
                    console.log("brak zmian; przerywamy");
                    return;
                }

                if (!minutesDiv) {
                    self.reloadTimeWindow(windowId);
                    return;
                }
                self.get('windows').removeObject(windowModel);

                windowModel.setProperties({
                    ramp: self.store.peekRecord('ramp', rampId),
                    start: newStart,
                    stop: newStop
                });

                var r = windowModel.get('ramp');
                console.log("Próba przesunięcie okna " + windowId + ": \n                    [" + windowModel.get('start') + ", " + oldRamp.get('name') + " (#" + oldRamp.get('id') + ")] \n                    -> [" + newStart + ", " + r.get('name') + " (#" + r.get('id') + ")]");

                if (self.get('isTemplate')) {
                    var warehouseOpenTime = this.get('warehouse').getStartOnDay(this.get('scheduleStartDate'));
                    var warehouseCloseTime = this.get('warehouse').getEndOnDay(this.get('scheduleStartDate'));
                    if (this.modelOverlapsAnyWindow(windowModel) || newStart < warehouseOpenTime || newStop > warehouseCloseTime) {
                        windowModel.setProperties({
                            ramp: oldRamp,
                            start: oldStart,
                            stop: oldStop
                        });
                    }

                    self.replaceTimeWindow(windowModel);
                    self.send('removePopupDialog');
                    self.set("inProgress", false);
                    return;
                }

                windowModel.save().then(function (w) {
                    self.send('removePopupDialog');
                    self.replaceTimeWindow(w);
                    self.setProperties({
                        inProgress: false,
                        errors: []
                    });

                    w.get('transport').then(function (t) {
                        console.log("Unloading checkpoints from transport " + t.id + "..");
                        self.unloadDeletedRecords('transport', t.id, 'checkpoints').then(function () {
                            if (t) {
                                t.reload();
                            }
                        });
                    });
                })["catch"](function (response) {
                    console.log("move window error: " + response);

                    windowModel.setProperties({
                        start: oldStart,
                        stop: oldStop,
                        ramp: oldRamp
                    });

                    self.setProperties({
                        inProgress: false,
                        errors: response.errors
                    });

                    self.replaceTimeWindow(windowModel);
                });
            },

            onStart: function onStart(event) {
                // to jest potrzebne do unikania klikania na okno w momencie gdy jest przeciagane
                $(event.target).addClass('noclick');
            },

            setCompany: function setCompany(item) {
                var self = this;
                this.store.findRecord('company', item.id).then(function (companyPayload) {
                    self.set('company', companyPayload);
                });
            },

            clearCompany: function clearCompany() {
                this.set('company', null);
            }
        },

        modelOverlapsAnyWindow: function modelOverlapsAnyWindow(windowModel) {
            var windowData = {
                id: windowModel.get('id'),
                start: windowModel.get('start'),
                stop: windowModel.get('stop'),
                ramp: {
                    id: windowModel.get('ramp').get('id')
                }
            };
            return this.overlapsAnyWindow(windowData);
        },

        overlapsAnyWindow: function overlapsAnyWindow(window) {
            var rampId = window.ramp.id;
            var newStart = window.start;
            var newStop = window.stop;
            var overlappingWindows = this.get('windows').filter(function (w) {
                return parseInt(w.get('id')) !== parseInt(window.id) && parseInt(w.get('ramp').get('id')) === parseInt(rampId) && !(w.get('stop') <= newStart || w.get('start') >= newStop);
            });
            return overlappingWindows.length > 0;
        },

        replaceTimeWindow: function replaceTimeWindow(result) {
            var self = this;
            var changeIdx = self.get('windows').indexOf(result);
            if (changeIdx >= 0) {
                self.get('windows').replace(changeIdx, 1, [result]);
            } else {
                self.get('windows').pushObject(result);
            }
        },

        reloadTimeWindow: function reloadTimeWindow(windowId) {
            var self = this;
            this.store.findRecord('timeWindow', windowId, { reload: true }).then(function (data) {
                var changeIdx = self.get('windows').indexOf(data);
                self.get('windows').replace(changeIdx, 1, [data]);
                self.set("inProgress", false);
                console.log("after reload / id: " + data.get('id') + " / changeIdx: " + changeIdx + " / start: " + data.get('start') + " / stop: " + data.get('stop') + " / ramp: " + data.get('ramp').get('name'));
            });
        },

        findMinutesDiv: function findMinutesDiv(left, top) {
            try {
                if (left < 50) {
                    left += 50; //zabezpieczenie przed dojechaniem do lewej krawedzi ekranu
                }
                var topElement = $(document.elementFromPoint(left, top));
                var visibility = topElement.css('visibility');
                if (topElement.hasClass('ember-application')) {
                    return null; // okno wyjechalo poza harmonogram, nie chce wywalac bledu, zeby wykonaly sie wszystkie
                    // topElement.css('visibility', visibility);
                }
                if (!topElement.hasClass('minutes-' + this.get('timeWindowOffsetIntervalSetting.value'))) {
                    topElement.css('visibility', 'hidden');
                    var bottomElement = this.findMinutesDiv(left, top);
                    topElement.css('visibility', visibility);
                    topElement = bottomElement;
                }
                return topElement;
            } catch (e) {
                console.error(e);
                return null;
            }
        },

        getDate: function getDate(div) {
            var $minuteDiv = $(div);
            var $hourDiv = $minuteDiv.parent();
            var $rampDiv = $hourDiv.parent();
            var $dayDiv = $rampDiv.parent();
            var date = moment($dayDiv.data('schedule-date'), 'YYYY-MM-DD');
            date.add($hourDiv.data('schedule-hour'), 'hours');
            date.add($minuteDiv.data('schedule-minute'), 'minutes');
            return date.toDate();
        },

        getRampId: function getRampId(div) {
            return $(div).closest(".ramp").data('schedule-ramp');
        }
    });
});
//noinspection JSLint,NpmUsedModulesInstalled