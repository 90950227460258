define("apollo/pods/invitations/review/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {

        queryParams: {
            token: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return this.store.queryRecord("invitation", { token: params.token });
        }
    });
});