define('apollo/pods/transport/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            steps: { embedded: 'save' },
            advice: { embedded: 'save' },
            carrierCompany: { embedded: 'save' },
            customFields: { embedded: 'save' }
        },

        serializeIntoHash: function serializeIntoHash(data, type, record, options) {
            console.log('serializeIntoHash');
            var object = this.serialize(record, options);
            for (var key in object) {
                data[key] = object[key];
            }
        },

        serializeHasMany: function serializeHasMany(record, json, relationship) {
            console.log('serializeHasMany');
            var hasManyRecords, key;
            key = relationship.key;
            hasManyRecords = record.hasMany(key);
            if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === "save") {
                json[key] = [];
                hasManyRecords.forEach(function (item) {
                    // use includeId: true if you want the id of each model on the hasMany relationship
                    var data = item.attributes();
                    data.id = item.id;
                    if (relationship.options.deepEmbedded) {
                        relationship.options.deepEmbedded.forEach(function (deepKey) {
                            if (item.belongsTo(deepKey)) {
                                var deepRecord = item.belongsTo(deepKey);
                                if (deepKey === "company" && !deepRecord.attr('name')) {
                                    return true;
                                }
                                data[deepKey] = deepRecord.attributes();
                                data[deepKey].id = deepRecord.id;
                                if (deepKey === "timeWindow") {
                                    data[deepKey].start = moment(deepRecord.attr('start')).format();
                                    data[deepKey].stop = moment(deepRecord.attr('stop')).format();
                                    data[deepKey].initialStart = moment(deepRecord.attr('initialStart')).format();
                                    data[deepKey].firstSavedStart = moment(deepRecord.attr('firstSavedStart')).format();
                                    data[deepKey].ramp = deepRecord.belongsTo('ramp') ? { id: deepRecord.belongsTo('ramp').id } : null;
                                } else if (deepKey === "company") {
                                    data[deepKey].country = deepRecord.belongsTo('country') ? {
                                        id: deepRecord.belongsTo('country').id
                                    } : null;
                                } else if (deepKey === "address") {
                                    data[deepKey].country = deepRecord.belongsTo('country') ? {
                                        id: deepRecord.belongsTo('country').id
                                    } : null;
                                } else if (deepKey === "step") {
                                    data[deepKey].deliveryDate = moment(deepRecord.attr('arrivalDate')).format();
                                    data[deepKey].warehouse = deepRecord.belongsTo('warehouse') ? {
                                        id: deepRecord.belongsTo('warehouse').id
                                    } : null;
                                }
                            }
                        });
                    }
                    json[key].push(data);
                });
            } else {
                this._super(record, json, relationship);
            }
        },

        serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
            console.log('serializeBelongsTo');
            var embeddedRecord, key;
            key = relationship.key;
            embeddedRecord = record.belongsTo(key);
            if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === "save") {
                if (key === "carrierCompany" && !embeddedRecord.attr('name')) {
                    return true;
                }
                var data = embeddedRecord.attributes();
                data.id = embeddedRecord.id;
                if (relationship.options.deepEmbedded) {
                    relationship.options.deepEmbedded.forEach(function (deepKey) {
                        if (embeddedRecord.belongsTo(deepKey)) {
                            data[deepKey] = { id: embeddedRecord.belongsTo(deepKey).id };
                        }
                    });
                }
                json[key] = data;
            } else {
                this._super(record, json, relationship);
            }
        }
    });
});