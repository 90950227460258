define("apollo/pods/components/transport/add-company/component", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Component.extend({

        sessionAccount: service('session-account'),
        store: service(),
        cookies: service(),
        i18n: service(),

        countries: (function () {
            return this.get('store').findAll('country');
        }).property(),

        cooperatingCompanies: [],
        errors: [],

        postKey: (function () {
            var self = this;
            var searchText = this.get('companyName');
            self.send('searchCompanies', searchText);
        }).observes('companyName'),

        principalCompanyId: (function () {
            return this.get('cookies').read('principalId');
        }).property(),

        existingCompany: {},
        existingCompanyAddresses: [],
        onlyCompanyExist: false,
        companyAndPartnershipExist: false,

        sendInvitation: true,

        actions: {

            showAddCompanyForm: function showAddCompanyForm() {
                this.set('errors', []);
                var $el = $(this.element);
                $el.find('.add-company-popover').toggle();
                var searchText = this.get('companyName');
                if (searchText) {
                    this.send('searchCompanies', searchText);
                }
            },

            addCompany: function addCompany(partnershipTypeName) {

                var self = this;
                var token = this.get('sessionAccount').get('token');
                var email = this.get('email');
                var taxIdNumber = this.get('taxIdNumber');
                var companyName = this.get('companyName');
                var country = this.get('country');

                if (this.get('sendInvitation')) {
                    if (!email) {
                        var message = this.get('i18n').t('transport.addNewCompany.emailRequired');
                        this.set('errors', [message]);
                        return;
                    }
                    if (!email.match(/\S+@\S+\.\S+/)) {
                        var message = this.get('i18n').t('transport.addNewCompany.wrongEmail');
                        this.set('errors', [message]);
                        return;
                    }
                }

                var companyData = {
                    token: token,
                    name: companyName,
                    country: country,
                    taxIdNumber: taxIdNumber,
                    partnershipType: partnershipTypeName,
                    principalCompanyId: self.get('principalCompanyId')
                };

                (0, _icAjax["default"])({
                    type: "POST",
                    url: _apolloConfigEnvironment["default"].serverURL + '/companies',
                    data: JSON.stringify(companyData),
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    },
                    contentType: 'application/json; charset=utf-8'
                }).then(function (response) {
                    if (response.onlyCompanyExist) {
                        self.set('existingCompany', response.company);
                        self.set('existingCompanyAddresses', response.addresses);
                        self.set('onlyCompanyExist', true);
                    } else if (response.companyAndPartnershipExist) {
                        self.set('existingCompany', response.company);
                        self.set('existingCompanyAddresses', response.addresses);
                        self.set('companyAndPartnershipExist', true);
                    } else {
                        self.send('setCarrierOrSupplierCompany', response.company.id, partnershipTypeName);
                        if (self.get('sendInvitation')) {
                            self.send('inviteCompany', response.partnership);
                        }
                    }
                }, function (error) {
                    self.set('errors', JSON.parse(error.jqXHR.responseText).errors);
                });
            },

            inviteCompany: function inviteCompany(partnership) {

                var self = this;

                self.get('store').findRecord('partnershipType', partnership.partnershipType.id).then(function (partnershipType) {

                    var origin = window.location.origin;
                    var typeId = partnershipType.get('id');
                    var roleGroupId = partnershipType.get('availableRoleGroups').get('firstObject').get('id');
                    var token = self.get('sessionAccount').get('token');

                    var invitationData = {
                        token: token,
                        email: self.get('email'),
                        roleGroup: roleGroupId,
                        partnershipType: typeId,
                        origin: origin,
                        companyId: self.get('existingCompany.id'),
                        partnershipId: partnership.id
                    };

                    (0, _icAjax["default"])({
                        type: "POST",
                        url: _apolloConfigEnvironment["default"].serverURL + '/invitations',
                        data: JSON.stringify(invitationData),
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        },
                        contentType: "application/json"
                    });
                });
            },

            searchCompanies: function searchCompanies(searchText) {

                var self = this;
                var token = this.get('sessionAccount').get('token');

                if (!searchText) {
                    self.set('cooperatingCompanies', []);
                } else {
                    (0, _icAjax["default"])({
                        type: "GET",
                        url: _apolloConfigEnvironment["default"].serverURL + '/companies',
                        data: { query: searchText, principalCompanyId: self.get('principalCompanyId') },
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        },
                        contentType: "application/json"
                    }).then(function (resp) {
                        self.set('cooperatingCompanies', resp.companies);
                    });
                }
            },

            cancelAssignCompany: function cancelAssignCompany() {
                this.set('existingCompany', {});
                this.set('companyAndPartnershipExist', false);
                this.set('onlyCompanyExist', false);
                var $el = $(this.element);
                $el.find('.add-company-popover').hide();
            },

            assignCompanyAndCreatePartnership: function assignCompanyAndCreatePartnership(partnershipTypeName) {

                var self = this;
                var token = this.get('sessionAccount').get('token');
                var partnershipData = {
                    token: token,
                    partnershipTypeName: partnershipTypeName,
                    principalCompanyId: self.get('principalCompanyId'),
                    contractorCompany: self.get('existingCompany'),
                    status: "NO_INVITATION"
                };

                (0, _icAjax["default"])({
                    type: "POST",
                    url: _apolloConfigEnvironment["default"].serverURL + '/partnerships',
                    data: JSON.stringify(partnershipData),
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    },
                    contentType: 'application/json; charset=utf-8'
                }).then(function (response) {
                    self.send('setCarrierOrSupplierCompany', self.get('existingCompany.id'), partnershipTypeName);
                    if (self.get('sendInvitation')) {
                        self.send('inviteCompany', response.partnership);
                    }
                }, function (error) {
                    self.set('errors', JSON.parse(error.jqXHR.responseText).errors);
                });
            },

            setCarrierOrSupplierCompany: function setCarrierOrSupplierCompany(companyId, partnershipTypeName) {
                var self = this;
                self.get('store').findRecord('company', companyId).then(function (company) {
                    if (partnershipTypeName === "CARRIER") {
                        self.get("transport").set('carrierCompany', company);
                    } else if (partnershipTypeName === "SUPPLIER") {
                        self.get("step").set('company', company);
                    }
                    self.set('companyAndPartnershipExist', false);
                    self.set('onlyCompanyExist', false);
                    var $el = $(self.element);
                    $el.find('.add-company-popover').hide();
                });
            },

            toggleSendInvitation: function toggleSendInvitation() {
                this.set('sendInvitation', !this.get('sendInvitation'));
            }
        }
    });
});