define('apollo/pods/invitations/create/controller', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend({

        sessionAccount: service('session-account'),
        store: service(),

        roleGroups: (function () {
            var self = this;
            var partnershipType = this.get('model.invitation').get('partnershipType');
            if (partnershipType.get('id')) {
                return partnershipType.get('availableRoleGroups');
            } else {
                return this.get('sessionAccount').get('currentUser').get('company').get('groups').map(function (group) {
                    return self.get('store').findRecord('role-group', group.id);
                });
            }
        }).property('model.invitation.partnershipType', 'sessionAccount.currentUser.company.groups.@each'),

        actions: {
            selectPartnershipType: function selectPartnershipType(id) {
                var type = id ? this.store.peekRecord("partnershipType", id) : null;
                this.get('model.invitation').set('partnershipType', type);
            },

            selectRoleGroup: function selectRoleGroup(id) {
                this.get('model.invitation').set('roleGroup', this.store.peekRecord("roleGroup", id));
            },

            sendAnInvitation: function sendAnInvitation() {
                var self = this;

                this.set('invitationSuccess', false);

                this.get('model.invitation').save().then(function () {
                    self.set('invitationSuccess', true);
                })['catch'](function (response) {
                    self.set('invitationError', response.errors[0]);
                });
            },

            cancel: function cancel() {
                this.get('model.invitation').rollbackAttributes();
                this.get('model.invitation').unloadRecord();
                this.transitionToRoute('invitations');
            }
        }
    });
});