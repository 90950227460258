define("apollo/router", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {

    var Router = _ember["default"].Router.extend({
        location: _apolloConfigEnvironment["default"].locationType
    });

    Router.map(function () {
        this.route("users", function () {
            this.route("show", {
                path: "/:user_id"
            });
            this.route("create");
            this.route('edit', {
                path: "/:user_id/edit"
            });
        });

        this.route("auth", function () {
            this.route("login");
            this.route('remind');
        });

        this.route("dummy", function () {
            this.route("create");
        });

        this.route("companies", function () {

            this.route("show", {
                path: "/:company_id"
            });

            this.route("create");

            this.route("edit", {
                path: "/:company_id/edit"
            });
        });

        this.route("transports", function () {
            this.route("show");
            this.route("create");
            this.route('archive', {
                path: '/archive/:type'
            });
        });

        this.route("warehouses", function () {
            this.route("schedule", {
                path: "/schedule"
            });
            this.route('template', {
                path: '/:warehouse_id/:date_string/template'
            });
            this.route('edit', {
                path: '/:id'
            });
            this.route('create');
            this.route('addRamp', {
                path: '/:warehouse_id/addRamp'
            });
        });

        this.route("services", function () {
            this.route("show");
        });

        this.route('settings', function () {
            this.route('edit', {
                path: '/:id'
            });
        });

        this.route('ramps', function () {
            this.route('create');
            this.route('edit', {
                path: '/:id'
            });
        });

        this.route('register', function () {
            this.route('after-register');
        });

        this.route('workflows', function () {
            this.route('edit', {
                path: '/:id'
            });
        });

        this.route('notifications', function () {
            this.route('edit', {
                path: '/notifications/:id'
            });
        });

        this.route('algorithmSettings', function () {
            this.route('edit', {
                path: '/algorithmSettings/:id'
            });
            this.route('create');
        });

        this.route('invitations', function () {
            this.route('create');
            this.route('handle');
            this.route('review');
            this.route('show', {
                path: '/:id'
            });
        });

        this.route('transport-types', function () {
            this.route('edit', {
                path: '/:id/edit'
            });
            this.route('permissions', {
                path: '/:id/permissions'
            });
        });

        this.route('algorithmSettings', function () {
            this.route('edit', {
                path: '/algorithmSettings/:id'
            });
            this.route('create');
        });

        this.route('reset-password');

        this.route('forbidden');
    });

    exports["default"] = Router;
});