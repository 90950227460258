define('apollo/pods/components/file-upload/component', ['exports', 'ember', 'apollo/config/environment'], function (exports, _ember, _apolloConfigEnvironment) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        action: 'fileUploadSuccess',
        sessionAccount: service('session-account'),

        didInsertElement: function didInsertElement() {

            var self = this;
            var token = this.get('sessionAccount').get('token');
            var transportId = this.get('transport.id');
            this.$().find(".file-upload-input").attr("data-url", _apolloConfigEnvironment['default'].serverURL + '/files/').fileupload({
                autoUpload: true,
                formData: function formData() {
                    return [{ name: "transportId", value: transportId }, { name: "token", value: token }];
                },
                progress: function progress(e, data) {
                    console.log("prog");
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('.progress').find('.bar').css('width', progress + '%');
                },
                done: function done() {
                    self.sendAction('action', transportId);
                }
            });
        },

        change: function change(e) {
            console.log('change ' + e);
            var event = jQuery.Event('change');
            event.target = e.target;
            this.$().find(".file-upload-input").trigger(event);
        },

        actions: {
            fileUploadSuccess: function fileUploadSuccess(transportId) {
                this.sendAction("action", transportId);
            }
        }
    });
});