define("apollo/pods/auth/remind/controller", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({

        i18n: _ember["default"].inject.service(),

        scheduleController: _ember["default"].inject.controller('auth/login'),

        remindEmail: '',

        actions: {
            sendRemindEmail: function sendRemindEmail() {
                var self = this;
                self.set('errorMessage', null);
                $(".reminding-progress").show();

                var url = _apolloConfigEnvironment["default"].serverURL + '/remind';
                var identification = this.get('identification');
                var email = this.get('remindEmail');
                var data = {
                    email: email,
                    identification: identification
                };

                (0, _icAjax["default"])({
                    url: url,
                    type: 'POST',
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    data: JSON.stringify(data)
                }).then(function (resp) {
                    $('.reminding-progress').hide();
                    if (resp.errors) {
                        self.set('errorMessage', resp.errors);
                        console.error(resp.errors);
                    } else {
                        var msg = self.get('i18n').t('auth.passwordRemindedMessage', { username: resp.user.name, email: email });
                        self.get('scheduleController').set('message', msg);
                        self.transitionToRoute('auth.login');
                    }
                });
            }
        }
    });
});