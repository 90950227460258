define("apollo/helpers/group-components", ["exports", "ember"], function (exports, _ember) {

    var get = _ember["default"].get,
        set = _ember["default"].set,
        EmberHandlebars = _ember["default"].Handlebars;

    function groupComponentsHelper(options) {
        var data = options.data,
            fn = options.fn,
            view = data.view,
            childView;

        childView = view.createChildView(_ember["default"]._MetamorphView, {
            context: get(view, 'context'),

            template: function template(context, options) {
                options.data.insideGroup = true;
                return fn(context, options);
            }
        });

        view.appendChild(childView);
    }
    exports["default"] = groupComponentsHelper;
});