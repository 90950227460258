define('apollo/helpers/format-date', ['exports', 'ember'], function (exports, _ember) {
    exports.formatDate = formatDate;

    function formatDate(rawData) {
        var date = rawData[0];
        var dateFormat = rawData[1] || 'DD.MM.YYYY HH:mm';
        var dateIsDateType = Object.prototype.toString.call(date) === '[object Date]';
        var dateIsStringType = Object.prototype.toString.call(date) === '[object String]';

        var dateIsValidDate = dateIsDateType || dateIsStringType;

        if (!dateIsValidDate) {
            return '';
        }
        return moment(date).format(dateFormat ? dateFormat : "DD.MM.YYYY HH:mm");
    }

    exports['default'] = _ember['default'].Helper.helper(formatDate);
});