define('apollo/pods/transport-type/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            customFieldDefinitions: { deserialize: 'records', embedded: 'save' },
            roleGroups: { deserialize: 'records', embedded: 'save' },
            tableColumns: { deserialize: 'records', embedded: 'save' }
        },

        serializeHasMany: function serializeHasMany(record, json, relationship) {
            console.log('serializeHasMany');
            var hasManyRecords, key;
            key = relationship.key;
            hasManyRecords = record.hasMany(key);
            if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === "save") {
                json[key] = [];
                hasManyRecords.forEach(function (item) {
                    // use includeId: true if you want the id of each model on the hasMany relationship
                    var data = item.attributes();
                    data.id = item.id;
                    json[key].push(data);
                });
            } else {
                this._super(record, json, relationship);
            }
        }
    });
});