define('apollo/pods/transport-types/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        i18n: _ember['default'].inject.service(),

        transportTypes: (function () {
            return this.store.findAll('transport-type');
        }).property(),

        errors: [],

        actions: {

            addNewField: function addNewField() {
                this.get('store').createRecord('customFieldDefinition', {
                    transportType: this.get('model')
                });
            },

            save: function save() {
                var self = this;
                var transportType = self.get('model');
                transportType.save().then(function () {
                    self.setProperties({
                        success: true,
                        errors: []
                    });
                    var msg = self.get('i18n').t('customFieldDefinition.saveSuccessMessage');
                    self.set('successMessage', msg);
                    transportType.get('customFieldDefinitions').map(function (cfd) {
                        cfd.rollbackAttributes();
                    });
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            },

            deleteCustomFieldDefinition: function deleteCustomFieldDefinition(cfd) {
                var self = this;
                cfd.destroyRecord().then(function () {
                    self.setProperties({
                        success: true,
                        errors: []
                    });
                    var msg = self.get('i18n').t('customFieldDefinition.deleteSuccessMessage');
                    self.set('successMessage', msg);
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.get('model').get('customFieldDefinitions').map(function (cfd) {
                    cfd.rollbackAttributes();
                });
            }
        }
    });
});