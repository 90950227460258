define("apollo/pods/checkpoint/model", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        i18n: _ember["default"].inject.service(),

        transport: _emberData["default"].belongsTo('transport', { async: true, inverse: 'checkpoints' }),
        step: _emberData["default"].belongsTo('step', { async: true }),
        task: _emberData["default"].belongsTo('task', { async: true }),
        confirmator: _emberData["default"].belongsTo('user', { async: true }),

        expectedDate: _emberData["default"].attr('isodate'),
        alertDate: _emberData["default"].attr('isodate'),
        executionDate: _emberData["default"].attr('isodate'),
        informationStoreDate: _emberData["default"].attr('isodate'),
        idx: _emberData["default"].attr('number'),
        checkpointName: _emberData["default"].attr('string'),

        isLate: (function () {
            return !this.get('isConfirmed') && this.get('expectedDate').getTime() <= new Date().getTime();
        }).property('executionDate', 'expectedDate'),

        wasLate: (function () {
            if (this.get('executionDate') === null) {
                return false;
            } else {
                return this.get('expectedDate') < this.get('executionDate');
            }
        }).property('executionDate', 'expectedDate'),

        isConfirmed: (function () {
            return this.get('executionDate') != null;
        }).property('executionDate'),

        name: (function () {
            return this.get('task').get('checkpointName');
        }).property('task.checkpointName'),

        stageName: (function () {
            return this.get('task.stageName') || this.get('i18n').t('task.defaultStagePrefix') + ' ' + this.get('name');
        }).property('task.stageName', 'name'),

        color: (function () {
            return this.get('task').get('color');
        }).property('task.color'),

        checkpointGroup: (function () {
            return this.get('task.checkpointGroup');
        }).property('task.checkpointGroup'),

        idxInGroup: (function () {
            return this.get('task.idxInGroup');
        }).property('task.idxInGroup'),

        tracked: (function () {
            return this.get('task.tracked');
        }).property('task.tracked'),

        hasAlert: (function () {
            return !this.get('isConfirmed') && this.get('alertDate') && this.get('alertDate').getTime() <= new Date().getTime();
        }).property('isConfirmed', 'alertDate')
    });
});
//noinspection JSLint