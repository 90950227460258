define("apollo/services/session-account", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Service.extend({

        session: service('session'),
        store: service(),
        errors: [],
        cookies: service(),

        loadCurrentUser: function loadCurrentUser() {
            console.log("loadCurrentUser");
            var self = this;

            var username = this.get('session.data.authenticated.username');
            var token = this.get('session.data.authenticated.token');

            if (_ember["default"].isEmpty(username)) {
                return;
            }

            return new _ember["default"].RSVP.Promise(function (resolve, reject) {
                (0, _icAjax["default"])({
                    url: _apolloConfigEnvironment["default"].serverURL + '/users/account/' + username,
                    type: 'GET',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function (response) {
                    if (_ember["default"].isEmpty(response.user)) {
                        reject('No user provided in response.');
                    }

                    self.get('store').pushPayload(response);

                    var user = self.get('store').peekRecord('user', response.user.id);

                    self.set("token", token);
                    self.set("resetPasswordIsDisabled", response.resetPasswordIsDisabled);
                    user.get('tableColumns').then(function (columns) {
                        user.get('transportTypes').then(function () {
                            self.set("currentUser", user);

                            var columnNames = columns.map(function (c) {
                                return c.get("name");
                            });
                            self.set("columnNames", columnNames);

                            if (response.company.isPrincipal) {
                                self.get('cookies').write('principalId', response.company.id);
                                self.set('currentUser.company.partnershipType', null);
                            } else {
                                if (!self.get('cookies').read('principalId')) {
                                    self.get('cookies').write('principalId', response.user.principals.sortBy('name').get('firstObject').id);
                                }

                                self.get('currentUser.company.acceptedPartnershipsByContractor').then(function (partnerships) {
                                    partnerships.map(function (p) {
                                        if (p.get('principalCompany.id') === self.get('cookies').read('principalId')) {
                                            self.set('currentUser.company.partnershipType', p.get('partnershipType'));
                                        }
                                    });
                                });
                            }
                            resolve(user);
                        });
                    });
                }, function () {
                    self.get('session').invalidate();
                });
            });
        },

        isPrincipal: (function () {
            return this.get('currentUser').get('isPrincipal');
        }).property('currentUser.isPrincipal'),

        hasRoleTransportTable: (function () {
            return this.hasRole('ROLE_TRANSPORT_TABLE');
        }).property('currentUser.roles'),

        hasAnyOfTheRoles: function hasAnyOfTheRoles(roles) {
            var self = this;
            var hasAnyOfTheRoles = false;
            roles.forEach(function (role) {
                if (self.hasRole(role)) {
                    hasAnyOfTheRoles = true;
                    return false;
                }
            });
            return hasAnyOfTheRoles;
        },

        hasRole: function hasRole(name) {
            if (!this.get('session').get('isAuthenticated')) {
                return false;
            }

            return this.get('currentUser').get('roles').findBy('name', name);
        }
    });
});