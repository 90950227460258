define('apollo/pods/algorithm-settings/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        settingsIndexController: _ember['default'].inject.controller('settings/index'),
        i18n: _ember['default'].inject.service(),

        errors: [],

        actions: {
            save: function save() {
                var self = this;
                var algorithmSetting = this.get('model');
                algorithmSetting.save().then(function () {
                    self.setProperties({
                        success: true,
                        errors: []
                    });

                    var msg = self.get('i18n').t('algorithmSettings.saveSuccessMessage');
                    self.get('settingsIndexController').set('successMessage', msg);

                    self.transitionToRoute('settings.index');
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.get('model').unloadRecord();
                this.transitionToRoute('settings.index');
            },

            'delete': function _delete() {
                var self = this;
                var algorithmSetting = this.get('model');
                algorithmSetting.destroyRecord().then(function (a) {
                    console.log('Poprawnie usuneliśmy ustawienia algorytmu dla ' + a.get('numberOfPallets') + ' palet.');
                    self.transitionToRoute('settings.index');
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            }
        }
    });
});