define("apollo/pods/components/show-transport-button/component", ["exports", "ember"], function (exports, _ember) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Component.extend({

        store: _ember["default"].inject.service(),
        sessionAccount: service('session-account'),

        actions: {
            openTransportModal: function openTransportModal(model) {
                this.set("action", "showModal");
                this.sendAction('action', 'transports.modals.create-transport', model);
            }
        }
    });
});