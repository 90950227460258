define("apollo/pods/companies/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        model: function model(params) {
            return this.store.findRecord('company', params.company_id);
        },

        renderTemplate: function renderTemplate() {
            this.render('companies.create');
        },

        setupController: function setupController(controller, model) {
            this.controllerFor('companies.create').setProperties({ content: model });
        }
    });
});