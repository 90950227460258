define("apollo/pods/components/time-window/component", ["exports", "ember", "apollo/pods/mixins/jqui-widget"], function (exports, _ember, _apolloPodsMixinsJquiWidget) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Component.extend(_apolloPodsMixinsJquiWidget["default"], {
        sessionAccount: service('session-account'),
        uiWidgets: ['draggable', 'resizable'],
        uiOptions: {
            draggable: ['disabled', 'addClasses', 'appendTo', 'axis', 'cancel', 'connectToSortable', 'containment', 'cursor', 'cursorAt', 'delay', 'distance', 'grid', 'handle', 'snap', 'snapMode', 'stack'],
            resizable: ['alsoResize', 'animate', 'animateDuration', 'animateEasing', 'aspectRatio', 'autoHide', 'cancel', 'containment', 'delay', 'disabled', 'distance', 'ghost', 'grid', 'handles', 'helper', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth']
        },

        uiEvents: {
            draggable: ['create', 'start', 'drag', 'stop'],
            resizable: ['create', 'start', 'stop', 'resize']
        },

        tagName: "div",
        classNames: ["time-window"],
        classNameBindings: ["windowStateClass"],

        "draggable-snap": ".minutes",
        "draggable-distance": 1,

        attributeBindings: ['data-schedule-window', 'data-is-supplement'],

        'data-schedule-window': (function () {
            return "" + this.window.id;
        }).property('window.id'),

        'data-is-supplement': (function () {
            return this.get('isSupplement') === true;
        }).property('window.id'),

        isDedicated: (function () {
            return !!this.get("window").get("company").get("id");
        }).property('window.id'),

        showLongName: (function () {
            return this.get('viewedColumns') < 8;
        }).property('viewedColumns'),

        showContent: (function () {
            return !this.get('isOnDisabledState') && this.window.get('durationInMinutes') >= 60 && this.get('viewedColumns') < 8;
        }).property('window.durationInMinutes', 'viewedColumns', 'windowState'),

        'resizable-handles': "s",
        'resizable-containment': ".schedule-body",

        windowStateClass: (function () {
            var state = this.get("window.state");

            if (!this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_DEDICATED_WINDOWS') && this.get('isDedicated') && !this.get('isDedicatedForLoggedCompany')) {
                state = this.get('window.STATE_NAMES.disabled');
            }

            return "window-" + state;
        }).property('window.state'),

        color: (function () {
            return this.get('window').get('color');
        }).property('window.color'),

        isOnDisabledState: (function () {
            return this.get("window.isDisabled");
        }).property('window.state'),

        canSeeNote: (function () {
            return this.get('isOnDisabledState') && !this.get('isDedicated') && this.get('sessionAccount').hasRole('ROLE_READ_WINDOW_NOTES');
        }).property('window'),

        canAccessDedicatedWindow: (function () {
            return this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_DEDICATED_WINDOWS') || this.get('isDedicatedForLoggedCompany');
        }).property('window.id'),

        isDedicatedForLoggedCompany: (function () {
            var windowCompanyId = this.get("window").get("company").get("id");
            var loggedCompanyId = this.get('sessionAccount').get('currentUser.company.id');
            return windowCompanyId === loggedCompanyId;
        }).property('window.id'),

        windowChanges: (function () {
            var self = this;
            _ember["default"].run.once(function () {
                self.afterRenderEvent();
            });
        }).observes('window.start', 'window.stop'),

        removeBlockedWindow: 'removeBlockedWindow',
        showTransportDetails: 'showTransportDetails',
        dedicatedSpotClicked: 'dedicatedSpotClicked',
        createWindow: 'createWindow',

        click: function click(event) {
            if ($(event.target).hasClass('noclick') || $(event.target).parent().hasClass('noclick')) {
                $(event.target).removeClass('noclick');
                $(event.target).parent().removeClass('noclick');
            } else {
                if (this.get('transportToSave')) {
                    if (this.canAdviceAccessDedicatedWindow(this.get('transportToSave'))) {
                        this.sendAction('dedicatedSpotClicked', event);
                    }
                } else if (this.get('isOnDisabledState') || this.get('isDedicated') === true) {
                    this.sendAction('removeBlockedWindow', this.window);
                } else if (this.window.get('step').get('transport')) {
                    this.sendAction('showTransportDetails', this.window.get('step').get('transport'));
                }
            }
            event.stopPropagation();
        },

        canAdviceAccessDedicatedWindow: function canAdviceAccessDedicatedWindow(advice) {
            var _this = this;

            if (this.get('isDedicated')) {
                var _ret = (function () {
                    var companyId = _this.get('window.company.id');
                    if (advice.get('carrierCompany') && advice.get('carrierCompany.id') === companyId) {
                        return {
                            v: true
                        };
                    }
                    var contractors = advice.get('contractors').filter(function (c) {
                        return c.get('id') === companyId;
                    });
                    return {
                        v: !!contractors.length
                    };
                })();

                if (typeof _ret === "object") return _ret.v;
            } else {
                return true;
            }
        },

        scheduleRender: (function () {
            try {
                _ember["default"].run.scheduleOnce('afterRender', this, this.afterRenderEvent);
            } catch (e) {
                console.error(e);
            }
        }).on('didInsertElement', 'didUpdate'),

        afterRenderEvent: function afterRenderEvent() {
            var $el = this.$();

            if (!$el) {
                return;
            }

            if (!$(".time-slot").length) {
                // wait for warehouse data to load
                _ember["default"].run.later(this, function () {
                    this.afterRenderEvent();
                });
                return;
            }

            try {
                var rampId = this.window.get("ramp").get("id");
                var startDate = this.window.get("start");
                var startDay = moment(startDate).format('YYYY-MM-DD');
                var startHourSelector = moment(startDate).format('H');
                var startMinuteSelector = moment(startDate).format('m');
                var endDate = this.window.get("stop");
                var endHourSelector = moment(endDate).format('H');
                var endMinuteSelector = moment(endDate).format('m');
                var endDay = moment(endDate).format('YYYY-MM-DD');
                var startSelector = undefined;
                var endSelector = undefined;
                var startAndStopInTheSameDay = moment(startDate).format('DD.MM.YYYY') === moment(endDate).format('DD.MM.YYYY');
                var isSupplement = this.get('isSupplement');

                var windowEndSelector = "div[data-schedule-date='" + endDay + "'] div[data-schedule-ramp='" + rampId + "'] div[data-schedule-hour='" + endHourSelector + "'] div[data-schedule-minute='" + endMinuteSelector + "']";

                // supplement - to 'końcówka' okna, która zaczyna się innego dnia (por. schedule/template.hbs); jego początek to zawsze pierwsza dostępna godzina na danej rampie
                if (isSupplement) {
                    startSelector = "div[data-schedule-date='" + endDay + "'] div[data-schedule-ramp='" + rampId + "'] div[data-schedule-hour='0'] div[data-schedule-minute='0']";
                    endSelector = windowEndSelector;
                } else {
                    startSelector = "div[data-schedule-date='" + startDay + "'] div[data-schedule-ramp='" + rampId + "'] div[data-schedule-hour='" + startHourSelector + "'] div[data-schedule-minute='" + startMinuteSelector + "']";

                    // koniec okna musi znajdować się tego samego dnia, co start (startAndStopInTheSameDay) oraz w obrębie czasu pracy magazynu ($(windowEndSelector).length);
                    // pierwszy warunek jest konieczny, bo w widoku tygodniowym widać zwykle kolejny dzień i $(windowEndSelector) istnieje - choć nie możemy go wykorzystać
                    // drugi warunek można rozwiązać porównując koniec okna z danymi magazynu - do sprawdzenia, która opcja lepsza
                    if (startAndStopInTheSameDay && $(windowEndSelector).length) {
                        endSelector = windowEndSelector;
                    } else {
                        // jeżeli powyższe warunki nie są spełnione - ustawiamy koniec okna na najpóźniejszą dostępną godzinę (koniec dnia lub koniec czasu pracy magazynu)
                        endSelector = "div[data-schedule-date='" + startDay + "'] div[data-schedule-ramp='" + rampId + "'] .later-hours";
                    }
                }

                var endSlot = $(endSelector);
                var startSlot = $(startSelector);

                // console.log(`id: ${this.window.id} / r: ${rampId} / start: ${startDate} / end: ${endDate} // startSlot: ${startSelector} / endSelector: ${endSelector}`);

                if (startSlot.length && endSlot.length) {
                    var $sourceStartSlot = $("div[data-schedule-window=" + this.window.id + "]").parent();

                    var currentWindowDiv = startSlot.find("div[data-schedule-window=" + this.window.id + "]");
                    // jeżeli nie zmieniamy pozycji aktualizowanego okna - nie chcemy dodawać dodatkowego div-u, bo może się zepsuć ustawienie offsetu okna dedykowanego
                    if (currentWindowDiv.length) {
                        currentWindowDiv.replaceWith(this.$());
                    } else {
                        $el.appendTo(startSlot);
                    }

                    var offset = startSlot.offset();
                    $el.offset({ top: offset.top, left: offset.left });

                    var height = endSlot.offset().top - offset.top;
                    $el.css('height', height);

                    // jeżeli mamy okno dedykowane z awizacją i przeniesiemy awizację gdzieś indziej - musimy przeliczyć offset okna dedykowanego (jeżeli istnieje)
                    if ($sourceStartSlot.find(".time-window").length === 1) {
                        $sourceStartSlot.find(".time-window").css("top", 0);
                    }
                } else {
                    console.log("Nie znaleziono miejsca na okno " + this.window.get('id') + ": [" + startDate + "-" + endDate + "] / r: " + rampId + " / isSupplement: " + isSupplement + " / startAndStopInTheSameDay: " + startAndStopInTheSameDay + "..");
                }
            } catch (e) {
                console.error(e);
            }
        },

        willDestroyElement: function willDestroyElement() {
            $(this.element).remove(); // TODO: [pawel] nie wiem, czy to jest poprawne podejście
        },

        openNoteWindow: 'openNoteWindow',

        actions: {
            openNoteWindow: function openNoteWindow() {
                this.sendAction("openNoteWindow", this.window);
            }
        }
    });
});