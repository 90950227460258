define('apollo/pods/transport-types/permissions/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        i18n: _ember['default'].inject.service(),

        errors: [],

        actions: {
            save: function save() {

                var self = this;
                var transportType = self.get('model.transportType');
                transportType.save().then(function () {
                    self.setProperties({
                        success: true,
                        errors: []
                    });
                    var msg = self.get('i18n').t('settings.transportTypes.permissionsSaveSuccess');
                    self.set('successMessage', msg);
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            }
        }
    });
});