define('apollo/pods/components/service-auto-complete/component', ['exports', 'ember', 'apollo/pods/components/custom-auto-complete/component'], function (exports, _ember, _apolloPodsComponentsCustomAutoCompleteComponent) {
    exports['default'] = _apolloPodsComponentsCustomAutoCompleteComponent['default'].extend({
        valueProperty: "id",
        urlAddress: "/advices/find",
        field: "companyName",

        options: [],

        suggestions: (function () {
            var inputVal = this.get("inputVal") || "";
            return this.get("options").filter(function (item) {
                return item.companyName.toLowerCase().indexOf(inputVal.toLowerCase()) > -1;
            });
        }).property("inputVal", "options.@each"),

        optionsToMatch: (function () {
            var caseInsensitiveOptions = [];
            this.get("options").forEach(function (item) {
                var value = item.companyName;
                caseInsensitiveOptions.push(value);
                caseInsensitiveOptions.push(value.toLowerCase());
            });
            return caseInsensitiveOptions;
        }).property("options.@each"),

        actions: {
            selectItem: function selectItem(item) {
                if (item.companyName == undefined) return;

                this.set("selectedFromList", true);
                this.set("selectedValue", item.companyName);

                this.sendAction('selectItem', item);
            }
        }
    });
});