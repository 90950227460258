define('apollo/pods/components/ramp-schedule/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['ramp-header'],
        attributeBindings: ['data-schedule-ramp', 'style'],

        'data-schedule-ramp': (function () {
            return "" + this.ramp.id;
        }).property(),

        style: (function () {
            return ("width:" + this.width + "%").htmlSafe();
        }).property("ramps"),

        moreThan8Columns: (function () {
            return this.get('viewedColumns') > 8;
        }).property('viewedColumns'),

        toggleWholeRampWindow: "toggleWholeRampWindow",

        click: function click(event) {
            var div = event.target;
            var $div = $(div);
            var date = $div.closest('.day-header').data('schedule-date');
            var time = moment(date, 'YYYY-MM-DD').toDate();
            var rampId = this.ramp.id;
            this.sendAction("toggleWholeRampWindow", time, rampId);
        }

    });
});