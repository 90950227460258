define('apollo/helpers/has-access-to-column', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Helper.extend({

        sessionAccount: service('session-account'),

        compute: function compute(params) {
            this.set('value', params[0]);
            this.set('transportTypeColumnNames', params[1]);

            return this.get('content');
        },

        valueDidChange: _ember['default'].observer('value', 'transportTypeColumnNames', function () {
            var value = this.get('value');

            if (_ember['default'].isEmpty(value)) {
                _ember['default'].defineProperty(this, 'content', false);
                return;
            }

            var self = this;
            _ember['default'].defineProperty(this, 'content', _ember['default'].computed('value', 'transportTypeColumnNames', function () {
                var value = self.get('value');
                var sessionAccount = self.get('sessionAccount');
                var transportTypeColumnNames = self.get('transportTypeColumnNames');
                var transportTypeHasAccessToColumn = false;

                if (transportTypeColumnNames) {
                    transportTypeHasAccessToColumn = transportTypeColumnNames.includes(value);
                    return sessionAccount.get('columnNames').includes(value) && transportTypeHasAccessToColumn;
                }
            }));
        }),

        contentDidChange: _ember['default'].observer('content', function () {
            this.recompute();
        })
    });
});