define("apollo/pods/custom-field-value/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {
    exports["default"] = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {

        transport: _emberData["default"].belongsTo('transport', { async: true, embedded: 'save' }),
        definition: _emberData["default"].belongsTo('customFieldDefinition'),

        value: _emberData["default"].attr('string'),

        validations: {
            value: {
                custom: [{
                    validation: function validation(key, value, model) {
                        var isObligatory = model.get('definition').get('obligatory');
                        if (isObligatory && !value) {
                            return false;
                        }
                        return true;
                    },
                    message: 'customFieldBlank'
                }]
            }
        }
    });
});