define("apollo/authenticators/custom", ["exports", "ember", "ember-simple-auth/authenticators/base", "apollo/config/environment"], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _emberSimpleAuthAuthenticatorsBase["default"].extend({

        cookies: service(),

        restore: function restore(data) {
            return new _ember["default"].RSVP.Promise(function (resolve, reject) {
                if (!_ember["default"].isEmpty(data.token)) {
                    _ember["default"].run(function () {
                        resolve({ token: data.token, username: data.username });
                    });
                } else {
                    reject();
                }
            });
        },

        authenticate: function authenticate(credentials) {
            var tokenEndpoint = _apolloConfigEnvironment["default"].serverURL + '/login';

            var data = { username: credentials.identification, password: credentials.password };
            return new _ember["default"].RSVP.Promise(function (resolve, reject) {
                _ember["default"].$.support.cors = true;
                _ember["default"].$.ajax({
                    url: tokenEndpoint,
                    type: 'POST',
                    data: JSON.stringify(data),
                    contentType: 'application/json'
                }).then(function (response) {
                    _ember["default"].run(function () {
                        resolve({ token: response.access_token, username: response.username });
                    });
                }, function (xhr) {
                    var response = xhr.statusText || xhr.responseText;
                    _ember["default"].run(function () {
                        reject(response);
                    });
                });
            });
        },

        invalidate: function invalidate(data) {
            var self = this;
            var logoutEndpoint = _apolloConfigEnvironment["default"].serverURL + '/logout';
            return new _ember["default"].RSVP.Promise(function (resolve) {
                _ember["default"].$.ajax({ url: logoutEndpoint, type: 'POST', data: { access_token: data.token } }).always(function () {
                    self.get('cookies').clear('principalId');
                    resolve();
                });
            });
        },

        actions: {
            sessionAuthenticationFailed: function sessionAuthenticationFailed(error) {
                console.log("sessionAuthenticationFailed..");
                this.controllerFor('application').set('loginErrorMessage', error.message);
            }
        }
    });
});