define("apollo/pods/companies/create/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        sessionAccount: service('session-account'),
        access: ['ROLE_CREATE_COMPANY'],
        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },
        model: function model() {
            var company = this.store.createRecord('company', {});
            company.set("country", this.get("sessionAccount.currentUser.company.country"));
            return company;
        }
    });
});