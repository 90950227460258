define("apollo/pods/components/company-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (exports, _apolloPodsComponentsCustomAutoCompleteComponent) {
    exports["default"] = _apolloPodsComponentsCustomAutoCompleteComponent["default"].extend({
        valueProperty: "id",
        urlAddress: "/company/find",
        field: "name",

        options: [],

        suggestions: (function () {
            var inputVal = this.get("inputVal") || "";
            return this.get("options").filter(function (item) {
                return item.name.toLowerCase().indexOf(inputVal.toLowerCase()) > -1;
            });
        }).property("inputVal", "options.@each"),

        optionsToMatch: (function () {
            var caseInsensitiveOptions = [];
            this.get("options").forEach(function (item) {
                var value = item.name;
                caseInsensitiveOptions.push(value);
                caseInsensitiveOptions.push(value.toLowerCase());
            });
            return caseInsensitiveOptions;
        }).property("options.@each"),

        actions: {
            selectItem: function selectItem(item) {
                this.set("selectedFromList", true);
                this.set("selectedValue", item.name);

                this.sendAction('selectItem', item);
            }
        }
    });
});