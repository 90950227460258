define("apollo/pods/components/show-statuses/component", ["exports", "apollo/pods/components/show-statuses/template", "apollo/pods/components/custom-popover/component"], function (exports, _apolloPodsComponentsShowStatusesTemplate, _apolloPodsComponentsCustomPopoverComponent) {
    exports["default"] = _apolloPodsComponentsCustomPopoverComponent["default"].extend({
        layout: _apolloPodsComponentsShowStatusesTemplate["default"],
        action: 'confirm',
        actions: {
            showStatuses: function showStatuses() {
                this.showPopover(this.element, '.statuses-button', '.statuses-popover');
            },

            confirm: function confirm(checkpoint) {
                this.set('action', 'confirm');
                this.sendAction('action', checkpoint);
                this.destroyPopovers();
            },

            revoke: function revoke(checkpoint) {
                this.set('action', 'revoke');
                this.sendAction('action', checkpoint);
                this.destroyPopovers();
            }
        }
    });
});