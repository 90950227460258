define("apollo/pods/transport-type/model", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({

        i18n: _ember["default"].inject.service(),

        TRANSPORT_TYPES: {
            TRANSPORT: 'TRANSPORT',
            SERVICE: 'SERVICE',
            DELIVERY: 'DELIVERY',
            COLLECTION: 'COLLECTION'
        },

        customFieldDefinitions: _emberData["default"].hasMany('customFieldDefinition', { async: true }),
        roleGroups: _emberData["default"].hasMany('roleGroup', { async: true }),
        tableColumns: _emberData["default"].hasMany('tableColumn', { async: true }),

        workflow: _emberData["default"].belongsTo('workflow'),

        name: _emberData["default"].attr('string'),
        typeGroup: _emberData["default"].attr('string'),
        requiresTimeWindow: _emberData["default"].attr('boolean'),
        canBeCreatedByRoleGroup: _emberData["default"].attr('boolean'),

        label: (function () {
            return this.get('i18n').t("transport.type." + this.get('name'));
        }).property('name'),

        tableColumnNames: (function () {
            return this.get('tableColumns').map(function (c) {
                return c.get("name");
            });
        }).property('tableColumns.@each.id')
    });
});