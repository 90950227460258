define("apollo/pods/register/index/controller", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({

        session: _ember["default"].inject.service('session'),

        loginController: _ember["default"].inject.controller('auth/login'),

        i18n: _ember["default"].inject.service(),

        actions: {
            register: function register() {
                var self = this;
                var user = this.get('model.user');
                var company = this.get('model.company');

                var data = user.serialize();
                data.company = company.serialize();

                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + "/register",
                    type: 'POST',
                    data: JSON.stringify(data),
                    contentType: 'application/json'
                }).then(function (jsonResponse) {

                    if (jsonResponse.errors) {
                        self.set('errors', jsonResponse.errors);
                        return;
                    }

                    self.setProperties({
                        inProgress: false,
                        errors: []
                    });

                    var credentials = {
                        identification: jsonResponse.user.username,
                        password: user.get('password')
                    };

                    self.get('session').authenticate('authenticator:custom', credentials).then(null, function (error) {
                        if (error !== undefined) {
                            console.error("error: " + error);
                        }
                    });
                }, function (response) {
                    self.set('errors', response.errors);
                });
            }
        }
    });
});