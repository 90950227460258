define('apollo/initializers/pagefront-beacon', ['exports', 'ember-pagefront/initializers/pagefront-beacon'], function (exports, _emberPagefrontInitializersPagefrontBeacon) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPagefrontInitializersPagefrontBeacon['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberPagefrontInitializersPagefrontBeacon.initialize;
    }
  });
});