define("apollo/pods/companies/index/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {

        sessionAccount: service('session-account'),
        cookies: service(),

        access: ['ROLE_COMPANIES_TAB'],
        queryParams: {
            query: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        model: function model(params) {
            if (params) {
                return this.store.query('company', { query: params.query, principalCompanyId: this.get('cookies').read('principalId') });
            } else {
                return this.store.query('company', { principalCompanyId: this.get('cookies').read('principalId') });
            }
        },

        actions: {
            willTransition: function willTransition(transition) {
                this.get('controller').setProperties({
                    query: '',
                    info: null,
                    errors: null,
                    successMessage: null
                });
                if (transition.targetName !== 'companies.index') {
                    this.get('controller').setProperties({
                        queryInput: '',
                        filterActive: false
                    });
                }
                return true;
            }
        }
    });
});