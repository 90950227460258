define("apollo/pods/users/create/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {

        sessionAccount: service('session-account'),
        access: ['ROLE_CREATE_USER'],
        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },
        model: function model() {
            return this.store.createRecord('user');
        }
    });
});