define('apollo/pods/settings/edit/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return this.store.query('setting', params.id);
        },

        actions: {
            willTransition: function willTransition() {
                this.get('controller').setProperties({
                    success: false,
                    errors: []
                });
                return true;
            }
        }
    });
});