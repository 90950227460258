define("apollo/pods/settings/index/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        sessionAccount: service('session-account'),

        model: function model() {
            var notifications = this.get('sessionAccount').get('currentUser').get('roleGroup').get('notifications');

            return _ember["default"].RSVP.hash({
                settings: this.store.findAll('setting', { reload: true }).then(function (data) {
                    return data.sortBy('name');
                }),
                warehouses: this.store.findAll('warehouse', { reload: true }).then(function (data) {
                    return data.sortBy('name');
                }),
                notifications: notifications,
                workflows: this.get('sessionAccount').get('currentUser').get('workflows'),
                algorithmSettings: this.store.findAll('algorithm-setting', { reload: true }).then(function (data) {
                    return data;
                })
            });
        },

        actions: {
            willTransition: function willTransition() {
                this.get('controller').setProperties({
                    info: null,
                    errors: null,
                    successMessage: null
                });
            }
        }
    });
});