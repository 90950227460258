define("apollo/pods/transports/index/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/config/environment", "apollo/mixins/persistence-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _apolloConfigEnvironment, _apolloMixinsPersistenceMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _apolloMixinsPersistenceMixin["default"], {

        cookies: service(),

        model: function model() {
            return this.store.query('transport', { archived: false, principalId: this.get('cookies').read('principalId') });
        },

        setupController: function setupController(controller, model) {
            controller.setProperties({ content: model });
            controller.set('archive', false);
        },

        activate: function activate() {
            this.set('timer', this.schedule(this.get('onPoll')));
        },
        deactivate: function deactivate() {
            _ember["default"].run.cancel(this.get('timer'));
        },

        schedule: function schedule(f) {
            return _ember["default"].run.later(this, function () {
                f.apply(this);
                this.set('timer', this.schedule(f));
            }, this.get('interval'));
        },

        onPoll: function onPoll() {
            var _this = this;

            try {
                (function () {
                    var self = _this;
                    var lastPollFormatted = moment(_this.get('lastPoll')).format();

                    var token = _this.get('session.data.authenticated.token');

                    _ember["default"].$.ajax({
                        url: _apolloConfigEnvironment["default"].serverURL + '/transports/updates',
                        data: { lastPoll: lastPollFormatted },
                        type: 'GET',
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        }
                    }).then(function (json) {
                        json.transports.forEach(function (transport) {

                            console.log("Unloading checkpoints from transport " + transport.id + "..");
                            self.unloadDeletedRecords('transport', transport.id, 'checkpoints').then(function () {

                                // [pawel] przy store.findRecord() po prostu nie działało; podejrzewam, że to jakiś bug ember-data - w przyszłości warto będzie to poprawić
                                self.get('store').query('transport', { id: transport.id }).then(function (t) {
                                    if (self.controller.get('model').includes(t.get('firstObject'))) {
                                        console.log("Odświeżamy dostawę " + transport.id + "..");
                                    } else {
                                        self.controller.get('model').addObjects(t.get('content'));
                                    }
                                });
                            });
                        });
                        var pollTime = json.pollTime;
                        self.set('lastPoll', pollTime);
                    });
                })();
            } catch (e) {
                console.error(e);
            }
        },

        lastPoll: (function () {
            var interval = this.get('interval');
            return moment().subtract(interval, 'milliseconds');
        }).property(),

        interval: (function () {
            return 5000; // Time between polls (in ms)
        }).property().readOnly()
    });
});
//noinspection NpmUsedModulesInstalled