define("apollo/mixins/persistence-mixin", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Mixin.create({

        session: service('session'),
        sessionAccount: service('session-account'),

        /**
         * Ember-data nie potrafi automatycznie usunąć obiektów z relacji, jeśli te zostaną
         * usunięte po stronie serwera - musimy więc to zrobić ręcznie.
         * Poniższa metoda zapyta serwer o aktualne relacje dla podanego modelu i usunie te
         * obiektu, które już nie istnieją.
         *
         * Uwaga: serwer musi potrafić obsłużyć konkretne zapytanie ajax.
         *
         * @param modelName - nazwa modelu; np. 'transport' lub 'timeWindow'
         * @param modelId - id modelu
         * @param relationship - nazwa relacji zdefiniowana jako DS.hasMany; np. 'checkpoints' lub 'timeWindows'
         */
        unloadDeletedRecords: function unloadDeletedRecords(modelName, modelId, relationship) {
            var self = this;
            return new _ember["default"].RSVP.Promise(function (resolve) {
                var model = self.store.peekRecord(modelName, modelId);

                if (!model) {
                    resolve();
                }

                var currentRecords = model.get(relationship);

                var token = self.get('session.data.authenticated.token');

                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + ("/" + modelName + "s/") + modelId + ("/" + relationship), // url może mieć postać np. 'host:port/transports/123456/checkpoints'
                    type: 'GET',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function (json) {
                    currentRecords.forEach(function (r) {
                        if (!json[relationship].filterBy('id', parseFloat(r.id)).length) {
                            r.deleteRecord();
                            console.log("Deleted relationship " + relationship + " #" + r.id + " from " + modelName + " #" + modelId + ".");
                        }
                    });
                    resolve();
                });
            });
        }
    });
});
/**
 * Created by pawel on 15.11.16.
 */
//noinspection NpmUsedModulesInstalled

//noinspection JSFileReferences