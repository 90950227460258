define("apollo/pods/auth/login/controller", ["exports", "ember", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Controller.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin["default"], {
        session: _ember["default"].inject.service('session'),
        i18n: _ember["default"].inject.service(),
        actions: {
            authenticate: function authenticate() {
                var self = this;

                self.set("errorMessage", null);

                var credentials = this.getProperties('identification', 'password');
                if (credentials.identification) {
                    credentials.identification = credentials.identification.trim();
                }
                if (credentials.password) {
                    credentials.password = credentials.password.trim();
                }
                if (!credentials.identification || !credentials.password) {
                    var message = this.get('i18n').t('auth.noLoginAndPassword');
                    this.set('errorMessage', message);
                    return;
                }

                $(".login-progress").show();
                this.get('session').authenticate('authenticator:custom', credentials).then(null, function (error) {
                    if (error !== undefined) {
                        console.error("error: " + error);
                    }
                    self.set("password", null);
                    $(".login-progress").hide();
                    $(".password").focus();
                    var message = undefined;
                    if (error === "Unauthorized") {
                        message = self.get('i18n').t('auth.invalidLoginOrPassword');
                    } else {
                        message = self.get('i18n').t('auth.serverError');
                    }
                    self.set('errorMessage', message);
                });
            },

            loginAs: function loginAs(username) {
                this.setProperties({
                    identification: username,
                    password: "Logintegra"
                });

                this.send("authenticate");
            }
        }
    });
});