define("apollo/pods/advice/modals/create-advice/controller", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        sessionAccount: service('session-account'),
        store: service(),

        errors: [],

        transport: (function () {
            return this.get('model.transport');
        }).property('model.transport'),

        initialStart: (function () {
            return this.get('model.initialStart');
        }).property('model.initialStart'),

        firstStepWithWarehouse: (function () {
            return this.get('transport').get('stepsWithWarehouse').get('firstObject');
        }).property('transport.stepsWithWarehouse.@each'),

        timeWindow: (function () {
            var self = this;

            return self.store.findRecord('ramp', self.get('model.rampId')).then(function (ramp) {
                var timeWindow = self.store.createRecord('timeWindow', {
                    start: self.get('model.start'),
                    stop: self.get('model.stop'),
                    ramp: ramp,
                    initialStart: self.get('model.initialStart'),
                    step: self.get('firstStepWithWarehouse')
                });
                self.get('firstStepWithWarehouse').set('timeWindow', timeWindow);
                return timeWindow;
            });
        }).property('model', 'firstStepWithWarehouse'),

        actions: {
            createAdviceAndTimeWindow: function createAdviceAndTimeWindow() {
                var self = this;
                var token = this.get('sessionAccount').get('token');

                self.get('model.transport.advice').then(function (advice) {

                    self.get('timeWindow').then(function (timeWindow) {

                        var data = {
                            advice: advice,
                            timeWindow: timeWindow,
                            transport: self.get('transport')
                        };

                        (0, _icAjax["default"])({
                            type: "POST",
                            url: _apolloConfigEnvironment["default"].serverURL + '/advices',
                            data: JSON.stringify(data),
                            beforeSend: function beforeSend(xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                            },
                            contentType: 'application/json; charset=utf-8'
                        }).then(function () {
                            _ember["default"].$('.modal').modal('hide');
                        }, function (error) {
                            self.set('errors', JSON.parse(error.jqXHR.responseText).errors);
                        });
                    });
                });
            },

            cancel: function cancel() {
                console.log("cancelCreateAdvice..");

                this.get('transport.advice').then(function (a) {
                    if (a) {
                        a.unloadRecord();
                    }
                });

                this.set('errors', []);
                _ember["default"].$('.modal').modal('hide');
                this.transitionToRoute('transports.index');
            }
        }
    });
});