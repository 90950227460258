define('apollo/helpers/format-calendar', ['exports', 'ember'], function (exports, _ember) {
    exports.formatCalendar = formatCalendar;

    function formatCalendar(params) {
        var date = params[0];
        var localeCode = params[1];
        var dateIsValidDate = date && Object.prototype.toString.call(date) === '[object Date]';
        if (!dateIsValidDate) {
            return '';
        }
        moment.locale(localeCode);
        return moment(date).calendar();
    }

    exports['default'] = _ember['default'].Helper.helper(formatCalendar);
});