define('apollo/pods/note/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        transport: _emberData['default'].belongsTo('transport', { async: true, embedded: 'save' }),
        creator: _emberData['default'].belongsTo('user', { async: true }),
        readOffBy: _emberData['default'].hasMany('user', { async: true }),

        note: _emberData['default'].attr('string'),
        date: _emberData['default'].attr('date'),
        dateCreated: _emberData['default'].attr('date'),
        autoGenerated: _emberData['default'].attr('boolean'),
        sentByEmail: _emberData['default'].attr('boolean'),

        canBeSentByEmail: (function () {
            return !this.get('autoGenerated') && !this.get('sentByEmail');
        }).property('autoGenerated', 'sentByEmail'),

        hasBeenReadBy: function hasBeenReadBy(account) {
            var userId = account.get('currentUser.id');

            return this.get('readOffBy').filter(function (u) {
                return u.id === userId;
            }).length > 0;
        }
    });
});