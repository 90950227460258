define("apollo/pods/register/index/route", ["exports", "ember", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin["default"], {

        model: function model() {
            var countries = this.store.findAll("country");

            var company = this.store.createRecord("company", { enabled: true, isPrincipal: true });
            var user = this.store.createRecord("user", {});

            return _ember["default"].RSVP.hash({
                countries: countries,
                user: user,
                company: company
            });
        },

        renderTemplate: function renderTemplate(controller, model) {
            this.render('register/index', {
                into: 'application',
                outlet: 'unauthenticated',
                controller: controller,
                model: model
            });
        }
    });
});