define('apollo/locales/en/translations', ['exports'], function (exports) {
    exports['default'] = {
        common: {
            search: 'Search',
            noResults: 'No results',
            cancel: 'Cancel',
            confirmMessage: 'Are you sure?',
            save: 'Save',
            saving: 'Saving',
            update: 'Update',
            'delete': 'Delete',
            disable: 'Disable',
            enable: 'Enable',
            addFile: 'Add file',
            phone: 'Phone',
            oldBrowser: 'You are using old browser. Our application will work better on modern browser such as Google Chrome, Firefox, Opera, Vivaldi, Safari, or IE Edge.',
            today: 'Today',
            day: 'Day',
            week: 'Week',
            edit: 'Edit',
            hint: 'Hint',
            company: 'Company',
            role: 'Role',
            back: 'Back',
            address: 'Address',
            type: 'Type',
            value: 'Value',
            name: 'Name',
            shortName: 'Short name',
            desc: 'Description',
            idx: 'Index',
            dateCreated: 'Date of creation',
            lastUpdated: 'Date of latest change',
            description: 'Description',
            send: 'Send',
            clear: 'Clear',
            noAdviceAddedYet: 'No advice added yet',
            uploadFile: 'Upload file',
            browseAndUploadDocuments: 'Browse and upload documents',
            downloadManualHeader: 'Download manual',
            transportDeletionDisabled: 'You can not delete this transport anymore. Please contact an administrator.',
            deletingProgress: 'deleting',
            deleteTitle: 'Delete this transport (deleted transport will appear in the archive).',
            newMessagesTitle: 'You have new messages!',
            selectDate: 'Select date',
            exportAsXLS: 'Export as XLS.',
            instructionalVideo: 'instructional video',
            forDateRange: {
                M: 'month',
                Y: 'year'
            },
            daysOfWeek: {
                label: 'for days',
                1: 'Mon',
                2: 'Tue',
                3: 'We',
                4: 'Thu',
                5: 'Fr',
                6: 'Sat',
                7: 'Sun'
            },
            notSpecified: 'not specified',
            from: 'from',
            to: 'to',
            active: 'Active',
            settings: 'Settings',
            timeSign: {
                'before': 'before',
                'after': 'after'
            },
            timeUnit: {
                s: 'seconds',
                m: 'minutes',
                h: 'hours',
                d: 'days'
            },
            add: 'Add',
            register: 'Join',
            accept: 'Accept',
            accepted: 'Accepted',
            reject: 'Reject',
            rejected: 'Rejected',
            waiting: 'Waiting',
            by: 'by',
            byYou: 'by you',
            details: 'details',
            check: 'check',
            fromCompany: 'from company',
            recipient: 'Recipient',
            yes: 'Yes',
            no: 'No'
        },

        help: {
            contactUs: 'or contact us',
            contactWithWarehouse: 'Contact with warehouse',
            statute: 'Statute',
            deleteTitle: 'Deleted this transport (deleted transport will appear in the archive).',
            newMessagesTitle: 'You have %@1 new messages!',
            selectRamp: 'Select load group.'
        },

        auth: {
            login: 'Login',
            notRegistered: 'You don\'t have an account yet?',
            alreadyRegistered: 'You already have an account?',
            loginPlaceholder: 'Enter login',
            password: 'Password',
            confirmPassword: 'Confirm password',
            passwordPlaceholder: 'Enter password',
            emailPlaceholder: 'Enter e-mail address',
            description: 'System for advices',
            fail: 'Logging failed',
            footer: 'Prepared by',
            noLoginAndPassword: 'Please provide login and password.',
            invalidLoginOrPassword: 'Invalid login or password.',
            serverError: 'Server is not responding. Try again in few minutes or contact us at info@logintegra.com.',
            logout: 'Logout',
            remindLink: 'Forgot password?',
            remind: 'Remind',
            remindEmail: 'Enter the e-mail connected with Your account, on which we will send a temporary password',
            remindFail: 'Reminding failed',
            passwordRemindedMessage: 'A new password for user {{username}} has been send at {{email}}.',
            username: 'User name',
            remindingInProgress: 'We\'re sending an e-mail'
        },

        resetPasswordWindow: {
            header: 'Changing the password to the system',
            info: 'Reset your password is required by the policy of the system.',
            info2: 'The new password must be different from the previously entered password and should contain 8 characters (digits, small and capital letters).',
            enteredPasswordsIsNotIdentical: 'Entered passwords must be identical.'
        },

        columns: {
            term: {
                full: 'Cooled chain product',
                short: 'Cooled'
            },
            rampNumber: 'Ramp&nbsp;no.',
            number: 'Number',
            group: 'Group',
            contact: 'Contact',
            supplier: 'Customer',
            cargo: 'Pallets',
            unload: 'Unload',
            driver: 'Driver',
            phone: 'Phone',
            car: 'Car',
            trailer: 'Trailer',
            status: 'Status',
            arrival: 'Arrival',
            departure: 'Departure',
            carrier: 'Carrier',
            company: 'Company',
            hour: 'Hour',
            description: 'Description',
            serviceman: 'Serviceman',
            deliveryPlace: 'Delivery place',
            collectionPlace: 'Collection place',
            country: 'Country',
            city: 'City',
            advice: 'Advice',
            timeWindowDate: 'Load date',
            creationDate: 'Creation date',
            arrivalDate: 'Delivery date',
            identifier: 'Number',
            tradesman: 'Tradesman'
        },

        services: {
            label: 'Services',
            add: 'Plan service'
        },

        delivery: {
            label: 'Delivery'
        },

        deliveries: {
            label: 'Deliveries',
            add: 'Add delivery'
        },

        table: {
            loadMore: 'Load more data',
            loading: 'Loading'
        },

        statuses: {
            initial: 'Advice',
            deleted: 'Deleted',
            finished: 'Completed',
            waitingForConfirmation: 'Waiting for confirmation of'
        },

        checkpoints: {
            plan: 'plan',
            confirmedMessage: 'confirmed on {{date}} by {{person}}',
            empty: 'No statuses',
            confirm: 'Confirm',
            confirmStatus: 'Confirm status',
            revokeConfirm: 'Are you sure you want to revoke the confirmation of this status?',
            revokeManyConfirm: 'Warning: as it is not the last confirmed status, we are going to also revoke the confirmations of all latter statuses. Are you sure you want to revoke those confirmations?',
            processFinished: 'Process is finished!'
        },

        transport: {
            type: {
                TRANSPORT: 'Transport',
                COURIER: 'Courier',
                COLLECTION: 'Collection',
                DELIVERY: 'Delivery',
                TRANSPORT_ORDER: 'Order transport',
                CONTAINER: 'Container',
                OWN_COLLECTION: 'Own collection'
            },
            createdBy: 'Created by',
            plan: 'Plan',
            description: 'Notes',
            dateOfAdvice: 'Date of advice',
            documents: {
                label: 'Documents',
                header: 'Documents for delivery {{identifier}}',
                empty: 'No documents have been added to this delivery yet.'
            },
            quantity: 'Quantity of load units',
            group: 'Group of delivery',
            erpIdentifier: 'Number of Apollo order',
            adviceFor: 'Supplier',
            trailerRegistrationNumber: 'Trailer registration number',
            carRegistrationNumber: 'Car registration number',
            documentNumber: 'Document number',
            driverLanguage: 'Driver language',
            driverIdentifier: "Driver's id",
            driverMobilePhoneNumber: 'Mobile phone number',
            driverName: 'Name and surname of driver',
            canNotEditBecauseOfOtherContractors: 'You can not edit data of an advice with multiple contractors.',
            canNotEditAfterProcessStarts: 'You can only edit data of an advice you created and before the arrival of a driver.',
            createTitle: 'Plan delivery',
            details: 'Details of the delivery',
            selectSupplier: 'Select supplier',
            createdMessage: 'Created {{date}} by {{person}} ({{company}}).',
            title: 'Transport {{ident}}',
            noFilesForOldBrowsers: 'You can not add files in this view with Your browser. Please add file in table if necessary.',
            selectUnloadStartDate: 'Min. date of unload',
            selectUnloadStartDateTitle: 'Select minimal unload date.',
            selectUnloadStopDate: 'Max. date of unload',
            selectUnloadStopDateTitle: 'Select maximum unload date.',
            selectTransportType: 'Select supply type',
            warehouse: 'Warehouse',
            selectWarehouse: 'Select warehouse',
            selectCountry: 'Country',
            deleteTransport: 'Are you sure you want to delete this transport?',
            logs: {
                label: 'History of changes',
                start: 'Start of time window',
                stop: 'End of time window',
                ramp: 'Ramp',
                name: 'File',
                initialStartInfo: 'Time window was pulled from hour {{initialDate}} to hour {{startDate}}',
                dateOfDeletion: 'Transport has been removed',
                dateOfArchiving: 'Transport has been archived'
            },
            carrier: {
                label: 'Carrier'
            },
            bigQuantityWarning: 'Are you sure, you want to make advice for more than one trailer?',
            packs: {
                label: 'Package',
                pallets: 'Pallets',
                boxes: 'Boxes'
            },
            contractors: {
                add: {
                    title: 'Add another contractor'
                },
                'delete': {
                    title: 'Delete contractor from this transport'
                }
            },
            errors: {
                warehouse: {
                    blank: 'Please, select a warehouse'
                },
                erpIdentifier: {
                    blank: 'Please, provide an ERP identifier'
                },
                dateOfAdvice: {
                    blank: 'Please, provide a date of advice',
                    isInPast: 'You can not schedule deliveries in the past'
                },
                summaryQuantity: {
                    nonPositiveInteger: 'Number of pallets must be integer greater than 0'
                },
                summaryQuantityBoxes: {
                    summaryQuantityBlank: 'Estimate how many pallets you will need to send the boxes and enter the amount - minimum 1 pallet',
                    nonPositiveInteger: 'Number of boxes must be integer greater than 0'
                },
                contractors: {
                    notUnique: 'Please provide a unique list of contractors'
                }
            },
            addNewCompany: {
                label: 'Add new company.',
                emailInfo: 'An invitation for cooperation will be sent at given e-mail address.',
                cooperatingCompanies: 'You already cooperate with a company of a similar name',
                existingCompany: 'We already have a company with selected Taxpayer Identification Number in our database',
                existingCompanyAndPartnership: 'The company with Taxpayer Identification Number {taxIdNumber} is already registered in the system and belongs to your colleagues',
                assignmentCompany: 'You want to assign this company as a transport contractor?',
                sendInvitation: 'Send a cooperate invitation',
                emailRequired: 'If you want to send an invitation to cooperate, you must provide an email address',
                wrongEmail: "Entered address email is not correct"
            },
            advice: 'Advice',
            load: 'Load',
            unload: 'Unload',
            weight: 'Weight',
            volume: 'Volume',
            arrivalDate: 'Arrival date',
            wzDocument: 'WZ doc. numbers',
            tradesman: {
                label: 'Tradesman'
            }
        },

        service: {
            company: 'Company',
            selectCompany: 'Select company',
            serviceman: 'Name of serviceman',
            registrationNumber: 'Registration number',
            description: 'Description of service',
            arrival: 'Arrival',
            departure: 'Departure',
            title: 'Service #%@1',
            createTitle: 'Plan service',
            contractorCompanyPlaceholder: 'Company name'
        },

        tabs: {
            deliveries: 'Deliveries',
            deliveriesAndServices: 'Transports',
            archive: 'Archive',
            schedule: 'Schedule',
            users: 'Users',
            companies: 'Companies',
            settings: 'Settings',
            invitations: 'Invitations'
        },

        warehouses: {
            label: 'Warehouses'
        },

        warehouse: {
            label: 'Warehouse',
            backToDeliveries: 'Back to table of deliveries',
            hint: 'To select time of advice, please click on the desired, free (light gray) hour.',
            firstEarlierFreeStart: 'The earliest possible time of advice is',
            firstSubsequentFreeStart: 'The earliest possible subsequent time of advice is',
            selectCompany: 'After clicking a free spot create a dedicated window for company',
            sumOfPallets: 'Sum of pallets',
            windowNoteLabel: 'Note content',
            windowNoteTitle: 'Enter your note',
            templates: {
                label: 'Templates',
                header: 'Templates of time windows for warehouse',
                copyFrom: 'Copy templates from day',
                saveFor: 'save template for',
                savedMessage: 'The template has been saved successfully.',
                datesWithErrorMessage: 'Attention: because of daylight savings we could not save some of the windows in following days: {{windows}}.'
            },
            hdOff: 'Turn off HD',
            maxPallets: 'Daily limit of pallets',
            workStartHour: 'Open hour',
            workEndHour: 'Close hour',
            numberOfRamps: 'Number of ramps',
            errors: {
                name: {
                    blank: 'Please, provide a name.'
                },
                maxPallets: {
                    nonPositiveInteger: 'Daily limit of pallets must be a positive integer.'
                },
                workStartTime: {
                    invalidFormat: 'Please, provide an hour in format hh:mm, ie. 06:00.'
                },
                workEndTime: {
                    invalidFormat: 'Please, provide an hour in format hh:mm, ie. 22:00. It is now only possible to select a full hour.',
                    endBeforeStart: 'Warehouse\'s close time must take place after the open time.'
                }
            },
            updateSuccessMessage: 'Settings of warehouse {{warehouseName}} have been updated.',
            disableConfirm: 'Are you sure you want to disable this warehouse?',
            enableConfirm: 'Are you sure you want to enable this warehouse?',
            disabledMessage: 'We successfully deleted warehouse "{{warehouseName}}".',
            addRamp: 'Add ramp',
            createRampsAfterSave: 'It will be possible to configure ramps after the warehouse is saved.',
            create: 'Create warehouse',
            windowIsLoad: 'L',
            windowIsUnload: 'U',
            selectWindowInfo: 'Select a free spot in the schedule to add an advice to transport',
            reserveFreePlace: 'Reserve free place in the schedule for transport {{internalIndex}} on'
        },

        ramps: {
            label: 'Ramps'
        },

        ramp: {
            label: 'Ramp',
            updateSuccessMessage: 'Ramp\'s settings have been updated.',
            disableConfirm: 'Are you sure you want to disable this ramp?',
            enableConfirm: 'Are you sure you want to enable this ramp?',
            disabledMessage: 'We successfully deleted ramp "{{rampName}}".',
            enabledMessage: 'We successfully enabled ramp "{{rampName}}".',
            idx: 'Order in a warehouse',
            errors: {
                name: {
                    blank: 'Please provide a name of the ramp.'
                },
                shortName: {
                    blank: 'Please provide a short name of the ramp.',
                    sizeTooBig: 'Maximum size of short name is 10 characters.'
                },
                idx: {
                    nonPositiveInteger: 'The order of the ramp must be a positive integer.',
                    idxTooBig: 'The order of the ramp must not be greater than number of ramps in this warehouse.'
                }
            },
            backToWarehouse: 'Back to warehouse'
        },

        users: {
            label: 'Users'
        },

        user: {
            name: 'Name and surname',
            username: 'Login',
            email: 'Email',
            mobilePhoneNumber: 'Phone',
            availableRamps: 'Available ramps',
            noAvailableRamps: 'No available ramps',
            myAccount: 'My account',
            createUser: 'Create user',
            editUser: 'Edit user',
            roleDescription: 'Description of role',
            list: 'List of users',
            availableWarehouses: 'Available warehouses',
            deleteConfirm: 'Are you sure you want to delete this user?',
            deletedMessage: 'Deleted on {{date}} by {{person}} from the {{company}} company.',
            selectRole: 'Select role',
            activeNotifications: 'Active notifications',
            noActiveNotifications: 'No active notifications',
            invitedBy: 'Invited: {{date}} by {{person}}.',
            accountCreatedBy: 'Account created by'
        },

        company: {
            label: 'Company',
            list: 'List of companies',
            createCompany: 'Create company',
            employees: 'Employees',
            shortName: 'Short name',
            selectCountry: 'Select country',
            selectType: 'Select type',
            editCompany: 'Edit company',
            name: 'Name',
            address: 'Address',
            taxIdNumber: 'Taxpayer ID Number',
            country: 'Country',
            noEmployees: 'This company has no employees',
            deletedMessage: 'We successfully deleted company "{{companyName}}".',
            partnershipStatus: {
                label: 'Status',
                NO_INVITATION: 'No invitation',
                WAITING: 'Waiting',
                ACCEPTED: 'Accepted',
                REJECTED: 'Rejected'
            },
            type: {
                label: 'Type',
                SUPPLIER: 'Supplier',
                CARRIER: 'Carrier',
                SERVICE: 'Service',
                producer: 'Producer'
            },
            errors: {
                name: {
                    blank: 'Please, provide a name of the company'
                },
                shortName: {
                    blank: 'Please, provide a short name of the company'
                },
                companyType: {
                    blank: 'Please, select a typ of the company'
                },
                country: {
                    blank: 'Please, select a country'
                }
            }
        },

        roleGroup: {
            label: 'Role',
            placeholder: 'Select a role',
            CHOOSE_COMPANY: {
                desc: 'Choose company and role, please.'
            },
            ADMIN: {
                label: 'Admin',
                desc: 'User with all possible privileges. Has access to all tabs. Can create and edit users and companies.'
            },
            PRODUCER: {
                label: 'Logistician',
                desc: 'Can create and edit deliveries, services and time windows.'
            },
            GUARD: {
                label: 'Guard',
                desc: 'Can neither create nor edit deliveries and services.'
            },
            SUPPLIER: {
                label: 'Supplier',
                desc: 'Can create deliveries only for his own company. Do not see time windows of other suppliers.'
            },
            CARRIER: {
                label: 'Carrier',
                desc: 'Carrier. Can create an advice for many suppliers.'
            },
            WAREHOUSEMAN: {
                label: 'Warehouse',
                desc: 'This user can access deliveries and schedule, but can not create or update deliveries.'
            },
            SERVICEMAN: {
                label: 'Service',
                desc: 'Can create and edit services'
            },
            OBSERVER: {
                label: 'Observer',
                desc: 'This user can only see deliveries.'
            },
            selectCompany: 'Select company',
            name: 'Name'
        },

        roles: {
            admin: 'Admin',
            adminDesc: 'User with all possible privileges. Has access to all tabs. Can create and edit users and companies.',
            logistician: 'Logistician / warehouseman',
            logisticianDesc: 'Can create and edit deliveries, services and time windows. Has access to tabs: Deliveries and Services, Schedule and Archive.',
            guard: 'Guard',
            guardDesc: 'Can neither create nor edit deliveries and services. Has access to tabs: Deliveries and Services and Archive.',
            supplier: 'Supplier',
            supplierDesc: 'Can create deliveries only for his own company. Do not see time windows of other suppliers. Has access to tabs: Deliveries, Schedule and Archive.',
            carrier: 'Carrier',
            carrierDesc: 'Supplier with ability to create advices for other suppliers. Has access to tabs: Deliveries, Schedule and Archive.',
            serviceman: 'Serviceman',
            servicemanDesc: 'Can create and edit services. Has access to tabs: Deliveries and Services. Do not see deliveries.'
        },

        settings: {
            label: 'Settings',
            emails: {
                label: 'Notifications',
                lateDocuments: 'delay in adding documents'
            },
            backToSettings: 'Back to settings',
            updateSuccessMessage: 'Settings have been updated.',
            desc: {
                ARCHIVING_JOB_INTERVAL: 'How long after the confirmation of the final status we should move the transport to the archive.',
                DAYS_TO_RESET_PASSWORD: 'How often, in days, should user see a prompt to change a password.',
                DELETE_TRANSPORT_INTERVAL: 'Up to what point in relation to the estimated date of unloading it should be possible to remove transport.',
                DOCUMENTS_MONITORING_INTERVAL: 'The size of the delay in adding the documents, after which the mail will be sent to the customer.',
                FOREIGN_DELIVERY_MULTIPLIER: 'By which to multiply the size of unloading in case of deliveries abroad.',
                INVITATION_EXPIRATION_INTERVAL: 'The period of validity of the invitation.',
                RESET_PASSWORD_IS_DISABLED: 'Disable obligatory password reset.',
                XLS_TOP_ROW_ENABLED: 'Turn header for XLS reports.',
                WINDOW_CHANGE_MAIL_DELAY_INTERVAL: 'The size of delay, after which an e-mail will be sent when you move the time window.',
                WINDOW_CONSTANT_TIME: 'Constant value in minutes added to the length of the unloading in the windows.',
                TIME_WINDOW_OFFSET_INTERVAL: 'Number of minutes by which time windows can be moved. Possible values are 15, 30 or 60'
            },
            transportTypes: {
                label: 'Transport types settings',
                header: 'Transport type settings',
                permissions: 'Permissions',
                workflow: 'Workflow',
                permissionsSaveSuccess: 'We successfully saved permissions settings.'

            }
        },

        page: {
            pageTitle: 'Advices Apollo'
        },

        notification: {
            ADVICE_CREATED: {
                label: 'Creation of an advice',
                desc: 'The notification sent to the creator after saving of an advice.'
            },
            ADVICE_UPDATED: {
                label: 'Change of an advice',
                desc: 'The notification sent to stakeholders (eg. If the creator of an advice is a supplier, e-mail will be sent to the logistics and carriers) after the change of an advice.'
            },
            LATE_ADVICE: {
                label: 'Delay of an advice',
                desc: 'The notification sent in case of delay in the driver\'s arrival.'
            },
            LATE_DOCUMENTS: {
                label: 'Delay in handing over documents',
                desc: 'The notification sent if the supplier does not add shipping documents in a timely manner.'
            }
        },

        notifications: {
            label: 'Notifications',
            selectRole: 'Please, select a role first.',
            noNotificationsForRole: 'There is no notifications available for selected role.'
        },

        workflows: {
            label: 'Workflow',
            addTask: 'Add task',
            updateDisclaimer: 'Note: changes in time will only apply to NEW transports.',
            saveSuccessMessage: 'We successfully saved workflow.'
        },

        tasks: {
            empty: 'There is no tasks here.'
        },

        task: {
            label: 'Stage',
            shouldBeConfirmedBy: 'should be confirmed by',
            checkpointName: 'Checkpoint name',
            stageName: 'Stage name',
            defaultStagePrefix: 'Confirmed',
            expectedDateReference: 'Reference',
            unit: 'Unit',
            sign: 'Before/After',
            canBeConfirmedByGuard: 'Can be confirmed by guard',
            afterConfirmation: {
                willChangeStateTo: 'After confirmation of this stage, transport will change state to',
                willChangeColorTo: 'and its table row and time window will turn'
            },
            group: {
                0: 'Preparation',
                1: 'Loads and unloads',
                2: 'Ending'
            },
            expectedDateMode: {
                reference: 'not later than',
                exact: 'at',
                onDay: 'on the day of'
            },
            reference: {
                before: {
                    transportCreated: 'transport creation',
                    earliestStepStart: 'start of first load/unload',
                    latestStepStop: 'end of first load/unload',
                    stepStart: 'start of',
                    stepStop: 'end of'
                },
                after: {
                    transportCreated: 'transport creation',
                    earliestStepStart: 'start of first load/unload',
                    latestStepStop: 'end of first load/unload',
                    stepStart: 'start of',
                    stepStop: 'end of'
                }
            },
            stepType: {
                placeholder: 'Action type',
                load: 'load',
                unload: 'unload'
            },
            tracked: {
                enabled: {
                    label: 'Supervision of punctuality',
                    title: 'If not approved on time, the transport enters \'late\' state - row in the table and the time window will change to red and will be send emails with information about the delay. Click on the icon to change the settings.'
                },
                disabled: {
                    label: '',
                    title: 'Expected time of confirmation of the stage will only be pictorial information - in the case of non-approval we do not change the color of the line or the window and we will not send notifications about the delay. Click on' + ' the icon to change the settings.'
                }
            },
            errors: {
                checkpointName: {
                    blank: 'Please, provide a checkpoint name.'
                },
                stepType: {
                    blank: 'Please, provide a step type'
                },
                expectedDateReference: {
                    blank: 'Please, provide a reference'
                }
            }
        },

        algorithmSettings: {
            label: 'Setting the algorithm for calculating the size of the time window',
            numberOfPallets: 'Number of pallets',
            loadTimeInMinutes: 'Load time in minutes',
            deleteSetting: 'Are you sure you want to delete this setting?',
            saveSuccessMessage: 'We successfully saved algorithm settings.'
        },

        invitations: {
            label: 'Invitations',
            received: 'Received',
            sent: 'Sent',
            emptyReceived: 'You did not receive an invitation yet.',
            emptySent: 'You did not send an invitation yet.'
        },

        invitation: {
            label: 'An Invitation',
            creator: 'Creator',
            invite: 'Invite',
            notFound: 'Invitation does not exist or has expired.',
            alreadyAccepted: 'This invitation is already accepted.',
            createdBy: '{{personName}} invites You to join company {{companyName}} as {{roleName}}.',
            registrationComplete: 'Thank you. Your account has been registered. Please log-in using username {{username}}.',
            sendTo: 'Send an invitation',
            sent: 'Invitation sent at {{email}}.',
            acceptedSummary: 'Accepted',
            rejectedSummary: 'Rejected',
            alreadyHandled: 'This invitation has been reviewed',
            acceptedByAnotherPerson: 'The invitation has already been accepted by another person in your company.',
            registeredAccount: 'Registered account',
            'for': {
                JOIN_COMPANY: 'To your company',
                SUPPLIER: 'Supplier',
                CARRIER: 'Carrier',
                SERVICE: 'Service'
            },
            type: {
                JOIN_COMPANY: 'Join company',
                SUPPLIER: 'Join as supplier',
                CARRIER: 'Join as carrier',
                SERVICE: 'Join as service'
            }
        },

        afterRegistration: {
            label: 'Hello!',
            title: 'You are on the right way to say goodbye queues!',
            info: 'Start your own warehouse and invite co-workers to plan transports and advices.',
            invitationButton: 'Invitation list',
            addWarehouseButton: 'Add warehouse'
        },

        customFieldDefinition: {
            settingsFields: 'Settings fields',
            type: {
                number: 'Number',
                text: 'Text',
                date: 'Date'
            },
            addNewField: 'Add new field',
            saveSuccessMessage: 'We successfully saved fields settings.',
            deleteSuccessMessage: 'We successfully deleted field setting.',
            'delete': 'Are you sure you want to delete this field?',
            cancel: 'Are you sure you want to cancel your changes?',
            typeField: 'Type field',
            name: 'Name',
            englishName: 'English name',
            obligatory: 'Obligatory',
            visibleInTable: 'Visible in table'
        },

        wrongUrl: "No access or page you requested does not exist",

        address: {
            label: 'Address',
            street: 'Street',
            city: 'City',
            postal: 'Postal code',
            country: 'Country',
            addNewAddress: 'Add new address'
        }
    };
});