define("apollo/pods/invitations/handle/controller", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({

        loginController: _ember["default"].inject.controller('auth/login'),

        i18n: _ember["default"].inject.service(),

        actions: {
            join: function join() {
                var self = this;
                var user = this.get('model.user');
                var company = this.get('model.company');

                var data = user.serialize();
                data.company = company.serialize();
                var invitationId = this.get('model.invitation.id');

                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + ("/invitations/" + invitationId + "/join"),
                    type: 'POST',
                    data: JSON.stringify(data),
                    contentType: 'application/json'
                }).then(function (jsonResponse) {

                    if (jsonResponse.errors) {
                        self.set('errors', jsonResponse.errors);
                        return;
                    }

                    self.setProperties({
                        inProgress: false,
                        errors: []
                    });

                    var msg = self.get('i18n').t('invitation.registrationComplete', { username: jsonResponse.user.username });
                    self.get('loginController').set('message', msg);
                    self.transitionToRoute('auth.login');
                }, function (response) {
                    self.set('errors', response.errors);
                });
            }
        }
    });
});