define("apollo/pods/advice/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {
    exports["default"] = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {
        transport: _emberData["default"].belongsTo('transport', { async: true }),
        creator: _emberData["default"].belongsTo('user', { async: true, embedded: 'save' }),
        driverLanguage: _emberData["default"].belongsTo('country', { async: true, embedded: 'save' }),

        dateCreated: _emberData["default"].attr(),
        driverName: _emberData["default"].attr(),
        driverIdentifier: _emberData["default"].attr(),
        driverMobilePhoneNumber: _emberData["default"].attr(),
        carRegistrationNumber: _emberData["default"].attr(),
        trailerRegistrationNumber: _emberData["default"].attr(),
        documentNumber: _emberData["default"].attr()
    });
});