define('apollo/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend({

        session: service('session'),
        sessionAccount: service('session-account'),
        hdMode: false,
        cookies: service(),

        scheduleController: _ember['default'].inject.controller('warehouses/schedule'),

        transportType: (function () {
            var self = this;
            self.get('principal').then(function (company) {
                self.set('transportType', company.get('transportTypes').get('firstObject'));
            });
        }).property('principal'),

        principal: (function () {
            return this.get('store').findRecord('company', this.get('cookies').read('principalId'));
        }).property(),

        actions: {
            invalidateSession: function invalidateSession() {
                var _this = this;

                console.log('User logged out.');
                this.get('session').invalidate(this.get('sessionAccount')).then(function () {
                    return _this.transitionToRoute('auth.login');
                });
            },
            goToTodaySchedule: function goToTodaySchedule() {
                var scheduleStartDate = moment().startOf('day');
                var scheduleEndDate = moment().endOf('day');
                this.get('scheduleController').setProperties({
                    selectedDate: scheduleStartDate.toDate(),
                    scheduleStartDate: scheduleStartDate,
                    scheduleEndDate: scheduleEndDate,
                    ramps: [],
                    info: null
                });
                this.transitionToRoute('warehouses.schedule');
            },
            setPrincipalAndRefreshCurrentRoute: function setPrincipalAndRefreshCurrentRoute(principal) {
                this.get('cookies').write('principalId', principal.get('id'));
                this.set('principal', principal);
                this.get('target.router').refresh();
            }
        }
    });
});