define("apollo/pods/invitations/handle/route", ["exports", "ember", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin["default"], {

        queryParams: {
            token: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var invitation = this.store.queryRecord("invitation", { token: params.token });
            var countries = this.store.findAll('country');

            var company = this.store.createRecord("company", { enabled: true });
            var user = this.store.createRecord("user", {
                invitation: invitation
            });

            return _ember["default"].RSVP.hash({
                invitation: invitation,
                countries: countries,
                user: user,
                company: company
            });
        },

        renderTemplate: function renderTemplate(controller, model) {
            this.render('invitations/handle', {
                into: 'application',
                outlet: 'unauthenticated',
                controller: controller,
                model: model
            });
        },

        actions: {
            error: function error(reason) {
                if (reason.status === 404) {
                    console.log("set('', )");
                    return true;
                } else {
                    console.log("345345");
                }
            }
        }
    });
});