define("apollo/mixins/attachment-mixin", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    exports["default"] = _ember["default"].Mixin.create({
        uploadAttachment: function uploadAttachment(id, token) {
            var self = this;
            var files = [];
            var filesNames = [];
            var attachments = self.get('attachments');

            return new _ember["default"].RSVP.Promise(function (resolve) {
                attachments.map(function (file) {
                    resolve(self.readFile(file, files, filesNames));
                });
            }).then(function () {
                (0, _icAjax["default"])({
                    type: "POST",
                    url: _apolloConfigEnvironment["default"].serverURL + '/files/',
                    data: JSON.stringify({
                        transportId: id,
                        files: files,
                        filesNames: filesNames,
                        token: token
                    }),
                    contentType: "application/json"
                });
            });
        },

        readFile: function readFile(file, files, filesNames) {
            return new _ember["default"].RSVP.Promise(function (resolve) {
                var reader = new moxie.file.FileReader();

                reader.onloadend = function () {
                    resolve(reader.result);
                    files.pushObject(reader);
                    filesNames.pushObject(file.name);
                };

                reader.readAsDataURL(file);
            });
        }
    });
});