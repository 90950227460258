define("apollo/pods/transports/index/controller", ["exports", "ember", "apollo/config/environment", "ic-ajax"], function (exports, _ember, _apolloConfigEnvironment, _icAjax) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        sessionAccount: service('session-account'),
        cookies: service(),
        i18n: service(),
        store: service(),

        scheduleController: _ember["default"].inject.controller('warehouses/schedule'),
        createTransportController: _ember["default"].inject.controller('transports/modals/create-transport'),

        query: '',
        archiveQuery: '',
        filterStart: null,
        filterStop: null,

        loadingMore: false,
        filterActive: false,

        page: 1,

        totalPages: null,

        hasMore: (function () {
            return this.get('page') < this.get('totalPages');
        }).property('page', 'totalPages'),

        principalTransportTypes: (function () {
            var self = this;
            this.store.findRecord('company', this.get('cookies').read('principalId')).then(function (company) {
                self.set('principalTransportTypes', company.get('transportTypes'));
            });
        }).property(),

        setTransportTypesInTableOrArchive: (function () {
            var self = this;
            var principalId = this.get('cookies').read('principalId');
            self.store.findRecord('company', principalId).then(function (company) {
                if (self.get('archive')) {
                    var transportTypeId = self.get('model').get('query.transportTypeId');
                    self.set('transportTypesInTableOrArchive', company.get('transportTypes').filterBy('id', transportTypeId));
                } else {
                    self.set('transportTypesInTableOrArchive', company.get('transportTypes'));
                }
            });
        }).observes('archive', 'model.query'),

        actions: {
            confirm: function confirm(checkpoint) {
                this.get('createTransportController').send('cancelCreateWindow');

                var checkpointId = checkpoint.get('id');
                var token = this.get('sessionAccount').get('token');
                console.log("confirming checkpoint " + checkpointId);
                var self = this;
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/checkpoints/' + checkpointId + '/confirm',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    self.store.findRecord('checkpoint', checkpointId).then(function (data) {
                        data.get('transport').then(function (t) {
                            t.reload();
                        });
                    });
                    console.log("confirmed checkpoint " + checkpointId);
                });
            },

            revoke: function revoke(checkpoint) {
                var checkpointId = checkpoint.get('id');
                var token = this.get('sessionAccount').get('token');
                console.log("revoke confirmation of checkpoint " + checkpointId);
                var self = this;
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/checkpoints/' + checkpointId + '/revoke',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    self.store.findRecord('checkpoint', checkpointId).then(function (data) {
                        data.reload();
                    });
                    console.log("confirmed checkpoint " + checkpointId);
                });
            },

            addNote: function addNote(noteContent, transportId) {
                console.log("addNote: " + noteContent + ", transportId: " + transportId);
                var self = this;
                var transport = this.store.peekRecord("transport", transportId);
                var note = self.store.createRecord("note", {
                    transport: transport,
                    note: noteContent
                });
                note.save().then(function (n) {
                    transport.get('notes').pushObject(n);
                });
            },

            sendNoteByEmail: function sendNoteByEmail(note) {
                console.log("sendNoteByEmail: " + note);
                var noteId = note.get('id');
                console.log("sending note " + noteId);
                var token = this.get('sessionAccount').get('token');
                var self = this;
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/notes/' + noteId + '/sendByEmail',
                    type: 'POST',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    self.store.findRecord('note', noteId).then(function (data) {
                        data.reload();
                    });
                });
            },

            markNotesAsReadOff: function markNotesAsReadOff(transport) {
                var token = this.get('sessionAccount').get('token');
                (0, _icAjax["default"])({
                    url: _apolloConfigEnvironment["default"].serverURL + '/transports/' + transport.id + '/markNotesAsReadOff',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    transport.get("notes").then(function (notes) {
                        notes.reload().then(function () {
                            transport.notifyPropertyChange('notes');
                        });
                    });
                });
            },

            goToSchedule: function goToSchedule(timeWindow) {
                var _this = this;

                timeWindow.get('ramp').then(function (ramp) {
                    if (!_this.get('sessionAccount').get('currentUser').get('rampsIds').includes(ramp.get('id'))) {
                        // todo: komunikat dla użytkownika
                        return;
                    }
                    var scheduleStartDate = moment(timeWindow.get('start')).startOf('day');
                    var scheduleEndDate = moment(timeWindow.get('start')).endOf('day');
                    ramp.get('warehouse').then(function (w) {
                        console.log("ramp.get('warehouse'): " + w);
                        _this.get('scheduleController').setProperties({
                            warehouse: w,
                            selectedDate: scheduleStartDate,
                            scheduleStartDate: scheduleStartDate,
                            scheduleEndDate: scheduleEndDate,
                            ramps: [ramp]
                        });
                        _this.transitionToRoute('warehouses.schedule');
                    });
                });
            },

            goToWarehouse: function goToWarehouse(transport) {
                var warehouse = transport.get('stepsWithWarehouse').get('firstObject').get('warehouse');
                var scheduleStartDate = moment().startOf('day').toDate();
                var scheduleEndDate = moment().endOf('day').toDate();
                var ramps = warehouse.get('ramps');
                var info = this.get('i18n').t('warehouse.selectWindowInfo') + ':' + transport.get('internalIndex');
                this.get('scheduleController').setProperties({
                    warehouse: warehouse,
                    selectedDate: scheduleStartDate,
                    scheduleStartDate: scheduleStartDate,
                    scheduleEndDate: scheduleEndDate,
                    ramps: ramps,
                    transportToAdvice: transport,
                    info: info
                });

                this.transitionToRoute('warehouses.schedule');
            },

            // http://blog.ksol.fr/paginated-apis-with-ember-js/
            loadMore: function loadMore() {
                var self = this;

                var queryMap = this.getQueryMap();
                queryMap.page = this.get('page') + 1;

                this.set('loadingMore', true);

                this.get('store').query('transport', queryMap).then(function (data) {
                    self.get('model').pushObjects(data.get('content'));
                    self.incrementProperty('page');
                    self.setProperties({
                        loadingMore: false,
                        totalPages: data.get('meta.totalPages'),
                        count: data.get('meta.count')
                    });
                }, function () {
                    self.set('loadingMore', false);
                });
            },

            filter: function filter() {
                var self = this;

                this.set('loadingMore', true);

                var queryMap = this.getQueryMap();
                this.get('store').query('transport', queryMap).then(function (transports) {
                    self.setProperties({
                        model: transports,
                        loadingMore: false,
                        filterActive: queryMap.query || queryMap.start || queryMap.stop,
                        page: 1,
                        totalPages: transports.get('meta.totalPages'),
                        count: transports.get('meta.count')
                    });
                }, function () {
                    self.set('loadingMore', false);
                    self.set('filterActive', false);
                });
            },

            clearFilter: function clearFilter() {
                this.setProperties({
                    archiveQuery: '',
                    filterStop: null,
                    filterStart: null,
                    filterActive: false
                });
                this.send('filter');
            },

            fileUploadSuccess: function fileUploadSuccess(transportId) {
                console.log('fileUploadSuccess: ' + transportId);
                this.store.findRecord("transport", transportId).then(function (data) {
                    data.reload();
                });
            },

            deleteFile: function deleteFile(file) {
                console.log('deleteFile: ' + file.id);
                var transport = file.get('transport');
                file.destroyRecord().then(function () {
                    transport.then(function (t) {
                        return t.reload();
                    });
                });
            },

            generateXLS: function generateXLS() {
                var url = _apolloConfigEnvironment["default"].serverURL + '/transports/xls?token=' + this.get("sessionAccount").get("token");
                var queryMap = this.getQueryMap();
                url += "&" + $.param(queryMap);
                window.location = url;
            }
        },

        getQueryMap: function getQueryMap() {
            var self = this;
            return {
                archived: self.get('archive'),
                transportType: self.get('transportType.id'),
                query: self.get('archiveQuery'),
                start: self.get('filterStart') ? moment(self.get('filterStart')).format() : '',
                stop: self.get('filterStop') ? moment(self.get('filterStop')).format() : ''
            };
        },

        filteredModel: (function () {
            var self = this;
            var model = this.get('model');
            var archive = this.get("archive");

            return model.filter(function (item) {
                return item.id && (archive || item.searchOnAllAttrs(self.get('query'))) && item.get("archived") === archive;
            }).sortBy('isCompleted', 'sortProperty', 'id');
        }).property('model.@each.sortProperty', 'model.@each.isCompleted', 'query', 'archive'),

        transports: (function () {
            return this.get('filteredModel');
        }).property('filteredModel.@each')

    });
});