define("apollo/pods/warehouses/create/controller", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        sessionAccount: service('session-account'),
        i18n: _ember["default"].inject.service(),

        settingsIndexController: _ember["default"].inject.controller('settings/index'),

        actions: {
            save: function save() {
                var self = this;

                this.get('model').validate();
                if (this.get('model').get('errors.length')) {
                    return;
                }

                this.get('model').save().then(function () {
                    self.setProperties({
                        errors: []
                    });

                    self.get('sessionAccount').get('currentUser').reload();

                    self.get('sessionAccount').get('currentUser').get('warehouses').reload();

                    var msg = self.get('i18n').t('warehouse.updateSuccessMessage', { warehouseName: self.get('model').get('name') });
                    self.get('settingsIndexController').set('successMessage', msg);

                    self.transitionToRoute('settings.index');
                })["catch"](function (response) {
                    self.set('errors', response.errors);
                });
            },

            disable: function disable() {
                var self = this;
                var model = this.get('model');

                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/warehouses/' + model.get('id') + '/disable',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    model.set('enabled', false);

                    self.get('sessionAccount').get('currentUser').reload();

                    self.get('sessionAccount').get('currentUser').get('ramps').reload();

                    var msg = self.get('i18n').t('warehouse.disabledMessage', { warehouseName: model.get('name') });
                    self.get('settingsIndexController').set('successMessage', msg);
                    self.transitionToRoute('settings.index');
                }, function (response) {
                    self.set('errors', response.errors);
                });
            },
            enable: function enable() {
                var self = this;
                var model = this.get('model');

                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/warehouses/' + model.get('id') + '/enable',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    model.set('enabled', true);

                    self.get('sessionAccount').get('currentUser').reload();

                    self.transitionToRoute('settings.index');
                }, function (response) {
                    self.set('errors', response.errors);
                });
            },
            cancel: function cancel() {
                this.get('model').rollbackAttributes();
                this.transitionToRoute('settings.index');
            }
        }
    });
});