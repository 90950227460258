define("apollo/pods/transport/model", ["exports", "ember-data", "npm:fold-to-ascii", "ember", "apollo/mixins/model-validator"], function (exports, _emberData, _npmFoldToAscii, _ember, _apolloMixinsModelValidator) {
    var service = _ember["default"].inject.service;
    exports["default"] = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {
        i18n: service(),
        cookies: service(),
        sessionAccount: service('session-account'),

        creator: _emberData["default"].belongsTo('user', { async: true }),
        tradesman: _emberData["default"].belongsTo('user', { async: true }),
        principalCompany: _emberData["default"].belongsTo('company', { async: true }),
        carrierCompany: _emberData["default"].belongsTo('company', { async: true, deepEmbedded: ['country'] }),
        advice: _emberData["default"].belongsTo('advice', { async: true, deepEmbedded: ['creator', 'driverLanguage'] }),
        firstUnconfirmedCheckpoint: _emberData["default"].belongsTo('checkpoint', { async: true }),
        lastConfirmedCheckpointWithColor: _emberData["default"].belongsTo('checkpoint', { async: true }),

        transportType: _emberData["default"].belongsTo('transportType', { async: true }),
        steps: _emberData["default"].hasMany('step', { async: true, deepEmbedded: ['timeWindow', 'company', 'address', 'warehouse'] }),
        checkpoints: _emberData["default"].hasMany('checkpoint', { async: true }),
        notes: _emberData["default"].hasMany('note', { async: true }),
        files: _emberData["default"].hasMany('file', { async: true }),
        timeWindows: _emberData["default"].hasMany('timeWindow', { async: true }),
        transportLogs: _emberData["default"].hasMany('transportLog', { async: true }),
        customFields: _emberData["default"].hasMany('customFieldValue', { async: true, deepEmbedded: ['definition'] }),

        internalIndex: _emberData["default"].attr('number'),
        erpIdentifier: _emberData["default"].attr('string'),
        status: _emberData["default"].attr(),
        archived: _emberData["default"].attr('boolean'),
        dateOfArchiving: _emberData["default"].attr('isodate'),
        deleted: _emberData["default"].attr('boolean'),
        dateOfDeletion: _emberData["default"].attr('isodate'),
        earliestStepStart: _emberData["default"].attr('isodate'),
        dateCreated: _emberData["default"].attr('date'),
        lastUpdated: _emberData["default"].attr('date'),
        description: _emberData["default"].attr('string'),
        canBeUpdated: _emberData["default"].attr('boolean'),
        canBeDeleted: _emberData["default"].attr('boolean'),

        summaryQuantity: 0,
        summaryQuantityBoxes: 0,

        validations: {
            erpIdentifier: {
                presence: { message: 'blank' }
            },
            contractors: {
                custom: [{
                    validation: function validation(key, value, model) {
                        var supplierNames = model.get('supplierNames');
                        console.log("supplierNames: " + supplierNames);
                        if (!supplierNames.length) {
                            return true;
                        }
                        return supplierNames.length === supplierNames.uniq().length;
                    },
                    message: 'notUnique'
                }]
            },
            advice: {
                relations: ['belongsTo']
            },

            customFields: {
                relations: ['hasMany']
            }
        },

        CHECKPOINT_NAMES: {
            arrival: 'ARRIVAL',
            onUnload: 'ON_UNLOAD',
            afterUnload: 'AFTER_UNLOAD',
            departure: 'DEPARTURE'
        },

        TRANSPORT_TYPES: {
            transport: 'transport',
            service: 'service'
        },

        isTransport: (function () {
            return this.get('transportType.type') === this.get('TRANSPORT_TYPES').transport;
        }).property('transportType,'),

        STATE_INITIAL_NAME: (function () {
            return this.get('i18n').t('statuses.initial');
        }).property(''),

        STATE_DELETED_NAME: (function () {
            return this.get('i18n').t('statuses.deleted');
        }).property(''),

        STATE_FINISHED_NAME: (function () {
            return this.get('i18n').t('statuses.finished');
        }).property(''),

        name: (function () {
            return this.get('internalIndex') ? 'Transport nr ' + this.get('internalIndex') : 'Nowy transport';
        }).property('internalIndex'),

        isLate: (function () {
            return this.get('firstUnconfirmedCheckpoint.hasAlert');
        }).property('firstUnconfirmedCheckpoint.hasAlert'),

        color: (function () {
            if (this.get('deleted')) {
                return "darkgray"; // TODO: [pawel] umożliwić użytkownikowi ustalenie tego koloru w ustawieniach
            }
            if (this.get('archived')) {
                return "lightgray"; // TODO: [pawel] umożliwić użytkownikowi ustalenie tego koloru w ustawieniach
            }
            if (this.get('isLate')) {
                return "red";
            }
            return this.get('lastConfirmedCheckpointWithColor.color');
        }).property('lastConfirmedCheckpointWithColor.color'),

        backgroundColorStyle: _ember["default"].computed('color', function () {
            return _ember["default"].String.htmlSafe("background-color: " + this.get('color') + ";");
        }),

        textColorStyle: _ember["default"].computed('color', function () {
            return _ember["default"].String.htmlSafe("color: " + this.get('color') + ";");
        }),

        isCompleted: (function () {
            return !this.get('firstUnconfirmedCheckpoint.id');
        }).property('firstUnconfirmedCheckpoint'),

        stageName: (function () {
            if (this.get("deleted")) {
                return this.get('STATE_DELETED_NAME');
            } else if (this.get("archived")) {
                return this.get('STATE_FINISHED_NAME');
            }
            var point = this.get('lastConfirmedCheckpoint');
            return point ? point.get('stageName') : this.get('STATE_INITIAL_NAME');
        }).property('lastConfirmedCheckpoint.stageName'),

        lastConfirmedCheckpoint: (function () {
            return this.get('checkpoints').filterBy('isConfirmed', true).sortBy('idx').get('lastObject');
        }).property('checkpoints.@each.isConfirmed'),

        sortProperty: (function () {
            if (this.get('archived')) {
                return this.get('dateOfArchiving');
            } else {
                return this.get('earliestStepStart');
            }
        }).property('earliestStepStart', 'dateOfArchiving'),

        setSummaryQuantity: (function () {
            var _this = this;

            var q = 0;
            this.get('steps').then(function (steps) {
                steps.forEach(function (s) {
                    q += parseFloat(s.get('quantity')) || 0;
                });
                _this.set('summaryQuantity', q);
                if (q) {
                    _this.get('errors').remove('summaryQuantity');
                    _this.get('errors').remove('summaryQuantityBoxes');
                }
            });
        }).observes('steps.@each.quantity').on('init'),

        setSummaryQuantityBoxes: (function () {
            var _this2 = this;

            var q = 0;
            this.get('steps').then(function (steps) {
                steps.forEach(function (s) {
                    q += parseFloat(s.get('quantityBoxes')) || 0;
                });
                _this2.set('summaryQuantityBoxes', q);
            });
            if (this.get('summaryQuantity')) {
                this.get('errors').remove('summaryQuantityBoxes');
            }
        }).observes('steps.@each.quantityBoxes'),

        firstStep: (function () {
            return this.get('sortedSteps').get('firstObject');
        }).property('steps.@each.id', 'steps.@each.orderInTransport'),

        stepsWithWarehouse: (function () {
            return this.get("steps").filterBy("warehouse.id");
        }).property('steps'),

        stepsWithoutWarehouse: (function () {
            return _emberData["default"].PromiseArray.create({
                promise: this.get('steps').then(function (stepsArray) {
                    return stepsArray.filter(function (s) {
                        return !s.get('warehouse');
                    });
                })
            });
        }).property('steps'),

        contractors: (function () {
            return _emberData["default"].PromiseArray.create({
                promise: this.get('stepsWithoutWarehouse').then(function (stepsArray) {
                    return stepsArray.getEach('company').filter(function (company) {
                        return !!company.get('id');
                    });
                })
            });
        }).property('stepsWithoutWarehouse.@each.company'),

        supplierNames: (function () {
            return this.get('contractors').getEach('name').filter(function (s) {
                return s !== undefined;
            });
        }).property('contractors.@each.company'),

        loadSteps: (function () {
            var loadStepsPromise = this.get('steps').then(function (stepsArray) {
                return stepsArray.filterBy('stepTypeName', 'load');
            });

            console.log("ls: " + loadStepsPromise);
            return _emberData["default"].PromiseArray.create({
                promise: loadStepsPromise
            });
        }).property('steps.@each'),

        unloadSteps: (function () {
            var unloadStepsPromise = this.get('steps').then(function (stepsArray) {
                return stepsArray.filterBy('stepTypeName', 'unload');
            });

            return _emberData["default"].PromiseArray.create({
                promise: unloadStepsPromise
            });
        }).property('steps.@each'),

        sortedSteps: (function () {
            return this.get('steps').sortBy('orderInTransport');
        }).property('steps.@each', 'steps.@each.orderInTransport'),

        indexOfLastStep: (function () {
            if (!this.get('steps')) {
                return null;
            }
            return this.get('steps.length') - 1;
        }).property('steps.length'),

        warehouse: (function () {
            return this.get('ramp') ? this.get('ramp').get('warehouse') : null;
        }).property('steps.@each'),

        warehouses: (function () {
            var self = this;
            return this.get('sessionAccount.currentUser.warehouses').filter(function (w) {
                return w.get('company.id') === self.get('cookies').read('principalId');
            });
        }).property(),

        ramp: (function () {
            return this.get("timeWindow") ? this.get('timeWindow').get('ramp') : null;
        }).property('steps.@each'),

        timeWindow: (function () {
            return this.get('timeWindows').get('firstObject'); // TODO: [pawel] nie możemy w ten sposób wyszukiwać okna
        }).property('timeWindows.@each'),

        searchOnAllAttrs: function searchOnAllAttrs(query) {
            if (query === '') {
                return true;
            } else {
                query = new RegExp(_npmFoldToAscii["default"].fold(query), "i");
                if (this.get('erpIdentifier') && this.get('erpIdentifier').match(query)) {
                    return true;
                } else if (this.get('internalIndex') && ('' + this.get('internalIndex')).match(query)) {
                    return true;
                } else if (this.get('id') && ('' + this.get('id')).match(query)) {
                    return true;
                } else if (this.get('carrierCompany.name') && _npmFoldToAscii["default"].fold(this.get('carrierCompany.name')).match(query)) {
                    return true;
                } else if (this.get('advice.driverName') && _npmFoldToAscii["default"].fold(this.get('advice.driverName')).match(query)) {
                    return true;
                } else if (this.get('advice.driverIdentifier') && this.get('advice.driverIdentifier').match(query)) {
                    return true;
                } else if (this.get('advice.driverMobilePhoneNumber') && this.get('advice.driverMobilePhoneNumber').match(query)) {
                    return true;
                } else if (this.get('advice.carRegistrationNumber') && this.get('advice.carRegistrationNumber').match(query)) {
                    return true;
                } else if (this.get('advice.trailerRegistrationNumber') && this.get('advice.trailerRegistrationNumber').match(query)) {
                    return true;
                } else if (this.get('description') && this.get('description').match(query)) {
                    return true;
                } else if (this.get('ramp.name') && this.get('ramp').get('name').match(query)) {
                    return true;
                } else if (this.get('ramp.shortName') && this.get('ramp').get('shortName').match(query)) {
                    return true;
                } else if (this.get('warehouse.name') && _npmFoldToAscii["default"].fold(this.get('warehouse.name')).match(query)) {
                    return true;
                }

                if (this.get('contractors.length')) {
                    return this.get('contractors').filter(function (s) {
                        return s !== undefined && s.get('name') && s.get('name').match(query);
                    }).length;
                }

                if (this.get('timeWindow')) {
                    var d = this.get('timeWindow.start');
                    var dateFormatted = moment(d).format("DD.MM.YYYY HH:mm");
                    var dateFormattedLocale = d.toLocaleString();
                    var dateTimeFormatted = moment(d).format("HH:mm");
                    return dateFormatted.match(query) || dateFormattedLocale.match(query) || dateTimeFormatted.match(query);
                }
                return false;
            }
        },

        customFieldPairs: (function () {
            var definitions = this.get('transportType.customFieldDefinitions');
            var values = this.get('customFields');
            var pairs = [];

            var _loop = function (i) {
                var definition = definitions.objectAt(i);
                var value = values.find(function (v) {
                    return v.get('definition.id') === definition.get('id');
                });
                pairs[i] = { value: value, definition: definition };
            };

            for (var i = 0; i < definitions.get('length'); i++) {
                _loop(i);
            }

            return pairs.sortBy('definition.id');
        }).property('customFields.@each.definition', 'transportType.customFieldDefinitions.@each.id'),

        showFilesDiv: (function () {
            return this.get("files.length") || !this.get("browserOld");
        }).property('browserOld'),

        browserOld: (function () {
            return _ember["default"].$('html').is('.old');
        }).property(),

        canBeAccessedByCompany: function canBeAccessedByCompany(company) {
            if (this.get('creator.company.id') === company.get('id') || this.get('carrierCompany').get('id') === company.get('id')) {
                return true;
            }

            return this.belongsToContractors(company);
        },

        belongsToContractors: function belongsToContractors(company) {
            return !!this.get('contractors').findBy('id', company.get('id'));
        },
        canConfirmArrival: (function () {
            var arrivalCheckpoint = this.get('arrivalCheckpoint');
            return arrivalCheckpoint && !arrivalCheckpoint.get('isConfirmed');
        }).property('arrivalCheckpoint.isConfirmed'),

        canConfirmDeparture: (function () {
            var departureCheckpoint = this.get('departureCheckpoint');
            if (this.get('isTransport')) {
                var onUnloadCheckpoint = this.get('onUnloadCheckpoint');
                return onUnloadCheckpoint && departureCheckpoint && onUnloadCheckpoint.get('isConfirmed') && !departureCheckpoint.get('isConfirmed');
            } else {
                var arrivalCheckpoint = this.get('arrivalCheckpoint');
                return arrivalCheckpoint && departureCheckpoint && arrivalCheckpoint.get('isConfirmed') && !departureCheckpoint.get('isConfirmed');
            }
        }).property('arrivalCheckpoint.isConfirmed', 'departureCheckpoint.isConfirmed'),

        arrivalCheckpoint: (function () {
            return this.findCheckpoint(this.get('CHECKPOINT_NAMES').arrival);
        }).property('checkpoints.@each.id', 'checkpoints.@each.expectedDate'),

        departureCheckpoint: (function () {
            return this.findCheckpoint(this.get('CHECKPOINT_NAMES').departure);
        }).property('checkpoints.@each.id', 'checkpoints.@each.expectedDate'),

        onUnloadCheckpoint: (function () {
            return this.findCheckpoint(this.get('CHECKPOINT_NAMES').onUnload);
        }).property('checkpoints.@each.id', 'checkpoints.@each.expectedDate'),

        findCheckpoint: function findCheckpoint(name) {
            return this.get('checkpoints').filterBy('checkpointName', name).get('firstObject');
        },

        title: (function () {
            return this.get('i18n').t('transport.plan') + ': ' + this.get('transportType').get('label');
        }).property('archived'),

        hasLoads: (function () {
            return this.get('transportType.typeGroup') === "DELIVERY" || this.get('transportType.typeGroup') === "TRANSPORT";
        }).property('transportType.type'),

        hasUnloads: (function () {
            return this.get('transportType.type') === "COLLECTION" || this.get('transportType.typeGroup') === "TRANSPORT";
        }).property('transportType.type'),

        canNotBeEdited: (function () {
            return !(!this.get('id') || this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_TRANSPORT') || !this.get('canBeUpdated'));
        }).property('sessionAccount', 'canBeUpdated')
    });
});