define('apollo/pods/components/custom-popover/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        destroyPopovers: function destroyPopovers() {
            $('.popover').each(function () {
                $(this.previousSibling).popover('destroy');
            });
        },

        showPopover: function showPopover(element, class1, class2) {
            this.destroyPopovers();

            var $el = $(element);

            // popover('destroy') wykonuje się asynchronicznie, co jest trochę zaskakujące,
            // i czasem nie zdążył się wykonać, gdy próbowaliśmy utworzyć nowy popover;
            // na razie wydaje mi się, że rozwiązanie z setTimeout jest faktycznie najlepsze
            // http://stackoverflow.com/a/27345242/2224598
            setTimeout(function () {
                $el.find(class1).popover({
                    html: true,
                    content: function content() {
                        return $el.find(class2).html();
                    }
                });
                $el.find(class1).popover("show");
            }, 150);
        }
    });
});