define("apollo/pods/components/custom-auto-complete/component", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    var focusOutEvent;

    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Component.extend({
        sessionAccount: service('session-account'),

        highlightIndex: -1,
        escapedChars: [40, 38, 13],
        visibility: _ember["default"].String.htmlSafe("display: none;"),
        inputClass: '',
        inputClazz: _ember["default"].computed(function () {
            return "typeahead form-control" + this.get('inputClass');
        }),
        classNames: ["custom-auto-complete"],

        layoutName: "components/custom-auto-complete",

        getOptionsFromServer: (function () {
            var self = this;
            var inputVal = this.get("inputVal") || "";
            (0, _icAjax["default"])({
                type: "GET",
                url: _apolloConfigEnvironment["default"].serverURL + this.urlAddress,
                data: {
                    query: inputVal,
                    field: this.field,
                    type: this.type
                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader("Authorization", "Bearer " + self.get('sessionAccount').get("token"));
                }
            }).then(function (resp) {
                self.set('options', resp);
            });
        }).observes("inputVal"),

        onValueSelected: (function () {
            // this.set("selectedFromList", true);
            this.set("visibility", _ember["default"].String.htmlSafe("display: none;"));
        }).observes("selectedValue"),

        dropDownStyle: (function () {
            return "Visibility: " + this.get("visibility");
        }).property('visibility'),

        keyUp: function keyUp(event) {
            if (event.keyCode === 27) {
                this.set("visibility", _ember["default"].String.htmlSafe("display: none;"));
            } else if (this.escapedChars.indexOf(event.keyCode) === -1) {
                this.set("visibility", _ember["default"].String.htmlSafe("display: block;"));
                this.set("inputVal", _ember["default"].$(event.target).val());
            }
        },

        focusIn: function focusIn() {
            this.set("selectedFromList", false);
            if (this.get("visibility") === "display:none;") {
                this.set("visibility", _ember["default"].String.htmlSafe("display: block;"));
            }
        },

        focusOut: function focusOut() {
            clearTimeout(focusOutEvent);
            var self = this;
            var func = function func() {
                if (self.isDestroyed) {
                    return;
                }
                self.set("visibility", _ember["default"].String.htmlSafe("display: none;"));
                if (!self.get("selectedFromList")) {
                    var value = this.get("selectedValue");
                    var optionsToMatch = this.get("optionsToMatch");
                    if (optionsToMatch.indexOf(value) === -1) {
                        self.set("inputVal", "");
                        self.sendAction("newValueEntered", value);
                    }
                }
            };
            focusOutEvent = _ember["default"].run.later(this, func, 200);
        },

        keyDown: function keyDown(event) {
            if (this.get("visibility") !== "display:none;") {
                if (event.keyCode === 40) {
                    this.highlight("down");
                } else if (event.keyCode === 38) {
                    this.highlight("up");
                } else if (event.keyCode === 13 || event.keyCode === 9) {
                    if (!_ember["default"].isBlank(this.selectableSuggestion)) {
                        this.send("selectItem", this.selectableSuggestion);
                        this.set("visibility", _ember["default"].String.htmlSafe("display: none;"));
                    } else {
                        var value = this.get("selectedValue");
                        var optionsToMatch = this.get("optionsToMatch");
                        if (optionsToMatch.indexOf(value) >= 0) {
                            console.log("selectedFromList..");
                            this.set("selectedFromList", true);
                            this.set("visibility", _ember["default"].String.htmlSafe("display: none;"));
                        }
                    }
                }
            } else {
                this.set("visibility", _ember["default"].String.htmlSafe("display: block;"));
            }
        },

        highlight: function highlight(direction) {
            var newHighlightIndex = -1;
            if (direction === "down") {
                newHighlightIndex = this.highlightIndex + 1;
            } else if (this.highlightIndex > 0) {
                newHighlightIndex = this.highlightIndex - 1;
            }
            if (newHighlightIndex < this.get("suggestions").length) {
                if (this.highlightIndex > -1) {
                    var currentResult = this.get("suggestions").objectAt(this.highlightIndex);
                    currentResult.highlight = false;
                }
                this.set("highlightIndex", newHighlightIndex);
                if (this.highlightIndex > -1) {
                    var nextResult = this.get("suggestions").objectAt(this.highlightIndex);
                    nextResult.highlight = true;
                    this.set("selectableSuggestion", nextResult);
                }
            }
        }
    });
});
// na podstawie https://www.npmjs.com/package/ember-cli-auto-complete