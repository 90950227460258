define("apollo/pods/warehouses/template/controller", ["exports", "ember", "apollo/mixins/schedule-mixin", "apollo/config/environment"], function (exports, _ember, _apolloMixinsScheduleMixin, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend(_apolloMixinsScheduleMixin["default"], {

        sessionAccount: service('session-account'),
        i18n: _ember["default"].inject.service(),

        scheduleController: _ember["default"].inject.controller('warehouses/schedule'),

        isTemplate: true,
        isSaving: false,

        warehouse: (function () {
            return this.get('model.warehouse');
        }).property('model.warehouse'),

        ramps: [],

        daysOfWeek: [{ idx: 1, checked: true }, { idx: 2, checked: true }, { idx: 3, checked: true }, { idx: 4, checked: true }, { idx: 5, checked: true }, { idx: 6, checked: false }, { idx: 7, checked: false }],

        selectedDayIndexes: (function () {
            return this.get('daysOfWeek').filter(function (d) {
                return d.checked;
            }).map(function (checkedDay) {
                return checkedDay.idx;
            });
        }).property('daysOfWeek.@each.checked'),

        dateRangeOption: 'M',

        dateRangeOptions: ['M', 'Y'],

        dateRange: null,

        setDateRange: (function () {
            var format = this.get('dateFormat');
            var d = moment().format(format);
            console.log("dateRange: " + d);
            this.set('dateRange', d);
        }).observes('dateRangeOption').on('init'),

        dateFormat: (function () {
            return this.get('dateRangeOption') === 'M' ? 'MMMM YYYY' : 'YYYY';
        }).property('dateRangeOption'),

        dateRanges: (function () {
            var ranges = [];
            var rangeStartM = moment().startOf('month');
            var rangeEndM = moment(rangeStartM).add(2, 'years');
            var format = this.get('dateFormat');
            for (var m = rangeStartM; m.isBefore(rangeEndM); m = m.add(1, 'month')) {
                ranges.push(m.format(format));
            }
            return ranges.uniq();
        }).property('dateRangeOption'),

        setRamps: (function () {
            var self = this;
            console.log('setRamps');
            _ember["default"].run.once(this, function () {
                var user = self.get('sessionAccount').get('currentUser');
                if (_ember["default"].isEmpty(user)) {
                    return;
                }
                user.get('ramps').then(function (allRamps) {
                    var ramps = [];
                    var currentWarehouseId = self.get('model.warehouse').get('id');
                    if (currentWarehouseId == null) {
                        return;
                    }

                    allRamps.forEach(function (ramp) {
                        if (currentWarehouseId === ramp.get('warehouse').get('id')) {
                            ramps.push(ramp);
                        }
                    });

                    self.set('ramps', ramps);
                });
            });
        }).observes('model.warehouse.id'),

        shownDays: (function () {
            console.log("shownDays..");
            var daysOfWeek = [];
            var startDate = this.get('scheduleStartDate');
            for (var i = 0; moment(startDate).add(i, 'days') < this.get('scheduleEndDate'); i++) {
                daysOfWeek[i] = {
                    date: moment(startDate).add(i, 'daysOfWeek'),
                    name: moment(startDate).add(i, 'daysOfWeek').format('YYYY-MM-DD'),
                    longName: moment(startDate).add(i, 'daysOfWeek').format('DD.MM.YYYY dddd')
                };
            }
            return daysOfWeek;
        }).property('scheduleStartDate'),

        scheduleEndDate: (function () {
            return moment(this.get('scheduleStartDate')).endOf('day').toDate();
        }).property('scheduleStartDate'),

        scheduleSetWindows: (function () {
            _ember["default"].run.once(this, 'setWindows');
        }).observes('scheduleEndDate', 'ramps.@each'),

        sortedRamps: (function () {
            return this.get('ramps').sortBy('idx');
        }).property('ramps.@each.idx'),

        actions: {
            toggleDay: function toggleDay(idx) {
                var day = this.get('daysOfWeek').filter(function (d) {
                    return d.idx === idx;
                }).get('firstObject');

                _ember["default"].set(day, 'checked', !day.checked);
            },

            selectWarehouse: function selectWarehouse(warehouse) {
                var scheduleStartString = moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
                this.transitionToRoute('warehouses.template', warehouse.get('id'), scheduleStartString);
            },

            createWindow: function createWindow(start, stop, rampId) {
                if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
                    this.storeDedicatedWindow(start, stop, rampId);
                } else {
                    console.log('Brak ROLE_BLOCK_RAMPS.');
                }
            },

            removeBlockedWindow: function removeBlockedWindow(window) {
                if (!this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
                    console.log('Brak ROLE_BLOCK_RAMPS.');
                    return;
                }
                window.deleteRecord();
                this.get('windows').removeObject(window);
            },

            saveWindows: function saveWindows() {
                var self = this;

                self.set('isSaving', true);

                $('.save-template-button').button('loading');

                var warehouseId = this.get('model.warehouse').get('id');
                var data = JSON.stringify({
                    warehouse: {
                        id: warehouseId
                    },
                    windows: this.get('windows'),
                    daysOfWeek: this.get('selectedDayIndexes'),
                    dateRangeOption: this.get('dateRangeOption'),
                    dateRange: moment(this.get('dateRange'), this.get('dateFormat')).format('YYYY-MM')
                });
                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/warehouses/' + warehouseId + '/template',
                    type: 'POST',
                    data: data,
                    contentType: 'application/json; charset=utf-8',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function (data) {
                    self.setProperties({
                        errors: [],
                        isSaving: false
                    });
                    console.log("Szablon zapisany; usuwamy wszystkie okna z pamięci..");
                    self.store.unloadAll('timeWindow');

                    var successMessage = self.get('i18n').t('warehouse.templates.savedMessage');

                    if (data.datesWithErrorMessage) {
                        successMessage += " " + self.get('i18n').t('warehouse.templates.datesWithErrorMessage', { windows: data.datesWithErrorMessage });
                    }

                    self.get('scheduleController').setProperties({
                        warehouse: self.store.peekRecord('warehouse', warehouseId),
                        successMessage: successMessage
                    });
                    self.get('scheduleController').notifyPropertyChange('scheduleEndDate');

                    console.log(".. przechodzimy do widoku harmonogramu..");
                    self.transitionToRoute('warehouses.schedule');
                }, function (error) {
                    console.log("error: " + error);
                    self.setProperties({
                        errors: [error.responseText],
                        isSaving: false
                    });
                });
            },

            cancel: function cancel() {
                var self = this;
                this.set('errors', null);
                this.store.unloadAll('timeWindow');

                var warehouseId = this.get('model.warehouse').get('id');
                this.get('scheduleController').setProperties({
                    warehouse: self.store.peekRecord('warehouse', warehouseId)
                });
                this.get('scheduleController').notifyPropertyChange('scheduleEndDate');

                this.transitionToRoute('warehouses.schedule');
            },

            openNoteWindow: function openNoteWindow(window) {
                window.isTemplateView = true;
                this.send("showModal", 'time-window.modals.window-note', window);
            },

            toggleWholeRampWindow: function toggleWholeRampWindow(time, rampId) {
                console.log('toggleWholeRampWindow');

                var start = this.get('warehouse').getStartOnDay(time);
                var stop = this.get('warehouse').getEndOnDay(time);

                this.storeDedicatedWindow(start, stop, rampId);
            }
        },

        setWindows: function setWindows() {
            if (!this.get('rampsIds.length')) {
                return;
            }
            console.log("setWindows");
            var self = this;
            var scheduleStartDate = this.get('scheduleStartDate');
            var startString = moment(scheduleStartDate).format('DD.MM.YYYY HH:mm');
            var data = {
                start: startString,
                isTemplate: true,
                rampsIds: this.get('rampsIds').join(',')
            };
            return this.store.query("timeWindow", data).then(function (results) {
                self.set('windows', results.toArray());
            });
        },

        storeDedicatedWindow: function storeDedicatedWindow(start, stop, rampId) {
            console.log("storeDedicatedWindow / start: " + start + " / stop: " + stop + " / rampId: " + rampId);
            var self = this;
            var randomId = Math.floor(Math.random() * 10000 + 1);
            var windowData = {
                id: randomId,
                ramp: this.store.peekRecord("ramp", rampId),
                company: this.get("company"),
                start: start,
                stop: stop,
                isTemplate: true,
                initialStart: start
            };

            if (this.overlapsAnyWindow(windowData)) {
                console.log("windows overlap");
                return;
            }

            var window = this.store.createRecord("timeWindow", windowData);
            self.get('windows').pushObject(window);
        }
    });
});