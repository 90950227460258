define('apollo/pods/workflows/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        settingsIndexController: _ember['default'].inject.controller('settings/index'),
        i18n: _ember['default'].inject.service(),

        expectedDateModes: ['reference', 'exact'],

        dateSigns: ['before', 'after'],

        dateUnits: ['h', 'm', 's'],

        taskGroupIndexes: [0, 1, 2],

        stepTypes: ['load', 'unload'],

        tasks: (function () {
            return this.get('model').get('tasks');
        }).property('model'),

        proxiedTaskGroups: [],

        errors: [],

        scheduleSetProxiedTaskGroups: (function () {
            _ember['default'].run.once(this, 'setProxiedTaskGroups');
        }).observes('tasks.@each.idxInGroup').on('init'),

        setProxiedTaskGroups: function setProxiedTaskGroups() {
            var _this = this;

            this.get('tasks').then(function (loadedTasks) {
                var proxiedTaskGroups = _this.get('taskGroupIndexes').map(function (groupIdx) {
                    var filteredTasks = loadedTasks.filter(function (task) {
                        return task.get('taskGroupIdentifier') === groupIdx;
                    });

                    var references = groupIdx === 1 ? ['stepStart', 'stepStop'] : ['transportCreated', 'earliestStepStart', 'latestStepStop'];

                    return _ember['default'].ObjectProxy.create({
                        content: groupIdx,
                        tasks: filteredTasks.sortBy('idxInGroup'),
                        references: references
                    });
                });

                _this.set('proxiedTaskGroups', proxiedTaskGroups);
            });
        },

        actions: {
            toggleTracked: function toggleTracked(task) {
                var tracked = task.get('tracked');
                task.set('tracked', !tracked);
            },

            addTask: function addTask(identifier, idx) {
                var workflow = this.get('model');
                this.get('store').createRecord('task', {
                    workflow: workflow,
                    taskGroupIdentifier: identifier,
                    idxInGroup: idx,
                    expectedDateInterval: '30m'
                });
            },

            dragStart: function dragStart(object) {
                console.log('Drag Start', object);
            },

            sortEndAction: function sortEndAction() {
                this.get('proxiedTaskGroups').forEach(function (tg) {
                    tg.get('tasks').forEach(function (t, idx) {
                        t.set('idxInGroup', idx);
                    });
                });
            },

            update: function update() {
                var self = this;

                if (!self.get('model').validate()) {
                    return;
                }

                self.get('model').save().then(function (w) {
                    console.log("Workflow " + w.id + " saved.");
                    self.setProperties({
                        success: true,
                        errors: []
                    });

                    var msg = self.get('i18n').t('workflows.saveSuccessMessage');
                    self.get('settingsIndexController').set('successMessage', msg);

                    self.transitionToRoute('settings.index');
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.transitionToRoute('settings.index');
            }
        }
    });
});