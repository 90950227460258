define('apollo/helpers/odd-even', ['exports', 'ember'], function (exports, _ember) {
    exports.oddEven = oddEven;

    function oddEven(number) {
        if (number % 2) {
            return 'odd';
        } else {
            return 'even';
        }
    }

    exports['default'] = _ember['default'].Helper.helper(oddEven);
});