define("apollo/app", ["exports", "ember", "apollo/resolver", "ember-load-initializers", "apollo/config/environment"], function (exports, _ember, _apolloResolver, _emberLoadInitializers, _apolloConfigEnvironment) {

    var App = undefined;

    _ember["default"].MODEL_FACTORY_INJECTIONS = true;

    App = _ember["default"].Application.extend({
        modulePrefix: _apolloConfigEnvironment["default"].modulePrefix,
        podModulePrefix: _apolloConfigEnvironment["default"].podModulePrefix,
        Resolver: _apolloResolver["default"]
    });

    _ember["default"].RSVP.configure('onerror', function (e) {
        console.error(e.message);
        console.error(e.stack);
    });

    (0, _emberLoadInitializers["default"])(App, _apolloConfigEnvironment["default"].modulePrefix);

    exports["default"] = App;
});