define('apollo/pods/invitations/create/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {
            var origin = window.location.origin;
            var invitationModel = this.store.createRecord('invitation', {
                enabled: true,
                origin: origin
            });

            var partnershipTypes = this.store.findAll('partnershipType');

            return _ember['default'].RSVP.hash({
                invitation: invitationModel,
                partnershipTypes: partnershipTypes
            });
        },

        actions: {
            willTransition: function willTransition() {
                this.get('controller').setProperties({
                    invitationEmail: null,
                    invitationRoleGroup: null,
                    invitationError: null,
                    invitationSuccess: null,
                    success: false,
                    errors: []
                });
                return true;
            }
        }
    });
});