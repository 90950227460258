define('apollo/pods/user/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var _Ember = Ember;
    var service = _Ember.inject.service;
    exports['default'] = _emberData['default'].Model.extend({

        sessionAccount: service('session-account'),

        accessibleRamps: _emberData['default'].hasMany('ramp', { inverse: 'usersWithAccess' }),
        ramps: _emberData['default'].hasMany('ramp', { inverse: 'users' }),

        workflows: _emberData['default'].hasMany('workflow', { async: true }),
        roles: _emberData['default'].hasMany('role', { async: true }),
        tableColumns: _emberData['default'].hasMany('tableColumn', { async: true }),
        notifications: _emberData['default'].hasMany('notification', { async: true }),
        transportTypes: _emberData['default'].hasMany('transportType', { async: true }),

        company: _emberData['default'].belongsTo('company', { async: true }),
        roleGroup: _emberData['default'].belongsTo('roleGroup', { async: true }),
        whoDeleted: _emberData['default'].belongsTo('user', { async: true }),
        invitation: _emberData['default'].belongsTo('invitation', { async: true }),

        username: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        enabled: _emberData['default'].attr('boolean'),
        password: _emberData['default'].attr('string'),
        phoneNumber: _emberData['default'].attr('string'),
        mobilePhoneNumber: _emberData['default'].attr('string'),
        occupation: _emberData['default'].attr('string'),
        workHours: _emberData['default'].attr('string'),
        accountExpired: _emberData['default'].attr('boolean'),
        accountLocked: _emberData['default'].attr('boolean'),
        passwordExpired: _emberData['default'].attr('boolean'),
        dateCreated: _emberData['default'].attr('date'),
        lastUpdated: _emberData['default'].attr('date'),
        dateDeleted: _emberData['default'].attr('date'),
        createdPersonLog: _emberData['default'].attr(),
        resetPasswordDate: _emberData['default'].attr('date'),
        resetPasswordIsDisabled: _emberData['default'].attr('boolean'),

        warehouses: (function () {
            var uniqueWarehouses = [];
            this.get('accessibleRampsForPrincipal').forEach(function (r) {
                var warehouse = r.get('warehouse');

                var alreadyAdded = uniqueWarehouses.filter(function (w) {
                    return w.get("id") === warehouse.get("id");
                }).length;

                if (!alreadyAdded) {
                    uniqueWarehouses.push(warehouse);
                }
            });
            return uniqueWarehouses;
        }).property('accessibleRampsForPrincipal.@each.warehouse'),

        accessibleRampsForPrincipal: (function () {
            var self = this;
            if (self.get('sessionAccount.currentUser.isPrincipal')) {
                return this.get('ramps').filter(function (r) {
                    return r.get('warehouse.company.id') === self.get('sessionAccount.currentUser.company.id');
                });
            } else {
                return this.get('ramps');
            }
        }).property(),

        accessibleWarehouses: (function () {
            var uniqueWarehouses = [];
            this.get('accessibleRamps').forEach(function (r) {
                var warehouse = r.get('warehouse');

                var alreadyAdded = uniqueWarehouses.filter(function (w) {
                    return w.get("id") === warehouse.get("id");
                }).length;

                if (!alreadyAdded) {
                    uniqueWarehouses.push(warehouse);
                }
            });
            return uniqueWarehouses;
        }).property('accessibleRamps.@each.warehouse'),

        rampsIds: (function () {
            return this.get('ramps').map(function (r) {
                return r.get('id');
            });
        }).property('ramps.@each.id'),

        isPrincipal: (function () {
            return this.get('company').get('isPrincipal');
        }).property('company.isPrincipal'),

        isFullyRegistered: (function () {
            var isFullyRegistered = !this.get('isPrincipal') || !!this.get('warehouses.length');
            console.log('isFullyRegistered? ' + isFullyRegistered);
            return isFullyRegistered;
        }).property('isPrincipal', 'warehouses.length')
    });
});
//noinspection JSLint