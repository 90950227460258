define('apollo/pods/companies/index/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        queryParams: ['query'],
        query: '',

        queryInputChanged: (function () {
            _ember['default'].run.debounce(this, this.applyFilter, 500);
        }).observes('queryInput'),

        applyFilter: function applyFilter() {
            var queryInput = this.get('queryInput');
            this.set('query', queryInput);

            if (queryInput) {
                this.set('filterActive', true);
            }
        },

        actions: {
            clearFilter: function clearFilter() {
                this.setProperties({
                    query: '',
                    queryInput: '',
                    filterActive: false
                });
            }
        }
    });
});