define("apollo/routes/application", ["exports", "ember", "ember-simple-auth/mixins/application-route-mixin", "apollo/config/environment"], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin["default"], {

        sessionAccount: service('session-account'),
        freshWidget: service('fresh-widget'),
        i18n: _ember["default"].inject.service(),

        beforeModel: function beforeModel() {
            if (this.get('beforeModelFired')) {
                console.log("beforeModel already fired; returning..");
                return true;
            }
            this.set('beforeModelFired', true);

            var language = undefined;
            try {
                language = (window.navigator.languages || [window.navigator.language || window.navigator.userLanguage])[0].split("-")[0];
            } catch (e) {
                language = (navigator.language || navigator.browserLanguage).split('-')[0];
            }
            var availableLocales = ['pl', 'en'];
            if ($.inArray(language, availableLocales) < 0) {
                language = 'en';
            }
            this.set('locale', language);
            this.set('i18n.locale', language);

            if (this.get('session.isAuthenticated') && !this.get('sessionAccount.currentUser')) {
                return this._loadCurrentUser();
            }
        },

        afterModel: function afterModel() {
            this.loadSupportWidget();
        },

        sessionAuthenticated: function sessionAuthenticated() {
            console.log("Session has been authenticated.");
            this._super.apply(this, arguments);

            var self = this;
            this._loadCurrentUser().then(function (loggedUser) {
                if (!loggedUser.get('resetPasswordIsDisabled') && new Date(loggedUser.get('resetPasswordDate')) < new Date()) {
                    self.get('sessionAccount').set('passwordExpired', true);
                    self.transitionTo('reset-password');
                } else {
                    loggedUser.get('company').then(function () {
                        self.send('afterLogin');
                    });
                }
            });
        },

        loadSupportWidget: function loadSupportWidget() {
            this.get('freshWidget').loadWidget();
        },

        actions: {
            afterLogin: function afterLogin() {
                var loggedUser = this.get('sessionAccount').get('currentUser');
                if (!loggedUser.get('isFullyRegistered')) {
                    console.log("User is not fully registered - transition to 'after-register' route..");
                    this.transitionTo('register.after-register');
                } else {
                    this.transitionTo('transports');
                }
            },
            showModal: function showModal(name, model) {
                this.render(name, {
                    into: 'application',
                    outlet: 'modal',
                    model: model
                });
            },
            showPopupDialog: function showPopupDialog(name, model) {
                this.render(name, {
                    into: 'application',
                    outlet: 'popup-dialog',
                    model: model
                });
            },
            removeModal: function removeModal() {
                this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },
            removePopupDialog: function removePopupDialog() {
                var $dialog = $('.popup-dialog');
                $dialog.fadeTo('fast', 0.5, function () {
                    $dialog.dialog('close');
                });

                this.disconnectOutlet({
                    outlet: 'popup-dialog',
                    parentView: 'application'
                });
            },
            willTransition: function willTransition() {
                this.get('controller').setProperties({
                    hdMode: false
                });
                this.send('removePopupDialog');
                return true;
            },
            error: function error(_error) {
                var status = _error.errors ? _error.errors[0].status : null;
                if (status === 0) {
                    console.log('Sorry, but we\'re having trouble connecting to the server. This problem is usually the result of a broken Internet connection. You can try refreshing this page.');
                } else if (status === "403" || status === "401" || status === "404") {
                    this.transitionTo('forbidden');
                }
            }
        },

        redirect: function redirect(model, transition) {
            var unauthenticatedRoutes = _apolloConfigEnvironment["default"]['ember-simple-auth'].unauthenticatedRoutes;
            if (!unauthenticatedRoutes.includes(transition.targetName) && !this.get('session.isAuthenticated')) {
                console.log("User is unauthenticated - redirecting to auth route (and save transition to " + transition.targetName + ") ..");
                this.set('session.attemptedTransition', transition);
                this.transitionTo('auth.login');
            } else {
                console.log("session.isAuthenticated");
            }
        },

        _loadCurrentUser: function _loadCurrentUser() {
            if (!this.get('session.isAuthenticated')) {
                return;
            }
            return this.get('sessionAccount').loadCurrentUser();
        },

        setupController: function setupController(controller) {
            controller.set('browserUnsupported', _ember["default"].$('html').is('.unsupported'));
            controller.set('browserOld', _ember["default"].$('html').is('.old'));
        }
    });
});