define("apollo/pods/ramps/create/controller", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        sessionAccount: service('session-account'),
        i18n: _ember["default"].inject.service(),

        warehousesCreateController: _ember["default"].inject.controller('warehouses/create'),

        actions: {
            save: function save() {
                var self = this;

                this.get('model').validate();
                if (this.get('model').get('errors.length')) {
                    return;
                }

                this.get('model').save().then(function (m) {
                    m.get('warehouse').then(function (w) {
                        w.reload();

                        self.get('sessionAccount').get('currentUser').reload();

                        self.get('sessionAccount').get('currentUser').get('ramps').reload();

                        self.setProperties({
                            errors: [],
                            success: true
                        });
                        self.transitionToRoute('ramps.edit', m.id);
                    });
                })["catch"](function (response) {
                    self.set('errors', response.errors);
                });
            },

            disable: function disable() {
                var _this = this;

                var self = this;
                var model = this.get('model');

                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/ramps/' + model.get('id') + '/disable',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    var warehouse = model.get('warehouse');

                    model.set('enabled', false);

                    warehouse.then(function (w) {
                        w.reload();

                        self.get('sessionAccount').get('currentUser').reload();

                        self.get('sessionAccount').get('currentUser').get('ramps').reload();

                        var msg = self.get('i18n').t('ramp.disabledMessage', { rampName: model.get('name') });
                        self.get('warehousesCreateController').set('successMessage', msg);

                        model.unloadRecord();

                        _this.transitionToRoute('warehouses.edit', warehouse);
                    });
                }, function (response) {
                    self.set('errors', response.errors);
                });
            },
            enable: function enable() {
                var _this2 = this;

                var self = this;
                var model = this.get('model');

                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/ramps/' + model.get('id') + '/enable',
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    var warehouse = model.get('warehouse');

                    model.set('enabled', true);

                    warehouse.then(function (w) {
                        w.reload();

                        self.get('sessionAccount').get('currentUser').reload();

                        self.get('sessionAccount').get('currentUser').get('ramps').reload();

                        var msg = self.get('i18n').t('ramp.enabledMessage', { rampName: model.get('name') });
                        self.get('warehousesCreateController').set('successMessage', msg);

                        _this2.transitionToRoute('warehouses.edit', warehouse);
                    });
                }, function (response) {
                    self.set('errors', response.errors);
                });
            },
            cancel: function cancel() {
                var warehouse = this.get('model').get('warehouse');
                this.get('model').rollbackAttributes();
                this.transitionToRoute('warehouses.edit', warehouse);
            }
        }
    });
});