define('apollo/pods/components/transport-modal/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            ok: function ok() {
                this.$('.modal').modal('hide');
                this.sendAction('ok');
            }
        },
        show: (function () {
            this.$('.modal').modal({ backdrop: 'static' }).on('hidden.bs.modal', (function () {
                this.sendAction('close');
            }).bind(this));
            this.$('.modal').modal({ backdrop: 'static' }).on('shown.bs.modal', (function () {
                $('.focus-input').focus();
            }).bind(this));
        }).on('didInsertElement')
    });
});