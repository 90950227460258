define('apollo/locales/pl/translations', ['exports'], function (exports) {
    exports['default'] = {
        common: {
            search: 'Szukaj',
            noResults: 'Brak wyników dla danego wyszukiwania',
            cancel: 'Anuluj',
            confirmMessage: 'Jesteś pewien?',
            save: 'Zapisz',
            update: 'Aktualizuj',
            disable: 'Wyłącz',
            enable: "Włącz",
            saving: 'Zapisywanie',
            'delete': 'Usuń',
            addFile: 'Dodaj plik',
            phone: 'Telefon',
            oldBrowser: 'Używasz przestarzałej przeglądarki. Nasza aplikacja będzie lepiej działać na nowoczesnej przeglądarce, takiej jak Google Chrome, Firefox, Opera, Vivaldi, Safari, lub IE Edge.',
            today: 'Dziś',
            day: 'Dzień',
            week: 'Tydzień',
            edit: 'Edytuj',
            hint: 'Podpowiedź',
            company: 'Firma',
            role: 'Rola',
            back: 'Powrót',
            address: 'Adres',
            type: 'Typ',
            value: 'Wartość',
            name: 'Nazwa',
            shortName: 'Nazwa skrócona',
            desc: 'Opis',
            idx: 'Indeks',
            dateCreated: 'Data utworzenia',
            lastUpdated: 'Data ostatniej zmiany',
            description: 'Opis',
            send: 'Wyślij',
            clear: 'Wyczyść',
            noAdviceAddedYet: 'Nie dodano jeszcze żadnej awizacji',
            uploadFile: 'Dodaj plik',
            browseAndUploadDocuments: 'Przeglądaj i dodawaj dokumenty',
            downloadManualHeader: 'Pobierz instrukcję obsługi',
            transportDeletionDisabled: 'Nie możesz już usunąć tego transportu. Skontaktuj się z administratorem.',
            deletingProgress: 'usuwanie',
            deleteTitle: 'Usuń transport (usunięty transport znajdzie się w archiwum).',
            newMessagesTitle: 'Masz nieprzeczytane wiadomości.',
            selectDate: 'Wybierz datę',
            exportAsXLS: 'Eksportuj do pliku XLS.',
            instructionalVideo: 'Film instruktażowy',
            forDateRange: {
                M: 'miesiąca',
                Y: 'roku'
            },
            daysOfWeek: {
                label: 'w dniach',
                1: 'Pon',
                2: 'Wt',
                3: 'Śr',
                4: 'Czw',
                5: 'Pt',
                6: 'So',
                7: 'Nie'
            },
            notSpecified: 'nie podano',
            from: 'od',
            to: 'do',
            active: 'Aktywny',
            settings: 'Ustawienia',
            timeSign: {
                'before': 'przed',
                'after': 'po'
            },
            timeUnit: {
                s: 'sekund',
                m: 'minut',
                h: 'godzin',
                d: 'dni'
            },
            add: 'Dodaj',
            register: 'Dołącz',
            accept: 'Zaakceptuj',
            accepted: 'Zaakceptowane',
            reject: 'Odrzuć',
            rejected: 'Odrzucone',
            waiting: 'Oczekuje',
            by: 'przez',
            byYou: 'przez Ciebie',
            details: 'szczegóły',
            check: 'sprawdź',
            fromCompany: 'z firmy',
            recipient: 'Adresat',
            yes: 'Tak',
            no: 'Nie'
        },

        help: {
            contactUs: 'lub skontaktuj się z nami',
            contactWithWarehouse: 'Kontakt z magazynem',
            statute: 'Regulamin',
            newMessagesTitle: 'Nieprzeczytane wiadomości: %@1.',
            selectRamp: 'Wybierz grupę załadunkową.'
        },

        auth: {
            login: 'Login',
            notRegistered: 'Nie masz konta?',
            alreadyRegistered: 'Masz już konto?',
            loginPlaceholder: 'Wpisz login',
            password: 'Hasło',
            confirmPassword: 'Potwierdź hasło',
            passwordPlaceholder: 'Wpisz hasło',
            emailPlaceholder: 'Wpisz adres e-mail',
            description: 'System awizacji dostaw',
            fail: 'Logowanie nie powiodło się',
            footer: 'Przygotowany przez',
            noLoginAndPassword: 'Proszę podać login i hasło.',
            invalidLoginOrPassword: 'Login lub hasło są niepoprawne.',
            serverError: 'Serwer nie odpowiada. Spróbuj ponownie za kilka minut lub skontaktuj się z info@logintegra.com.',
            logout: 'Wyloguj',
            remindLink: 'Zapomniałeś hasła?',
            remind: 'Przypomnij',
            remindEmail: 'Podaj adres e-mail powiązany z Twoim kontem, na który wyślemy tymczasowe hasło',
            remindFail: 'Przypominanie hasła nie powiodło się',
            passwordRemindedMessage: 'Nowe hasło dla użytkownika {{username}} zostało wysłane na adres {{email}}.',
            username: 'Nazwa użytkownika',
            remindingInProgress: 'Wysyłamy e-mail'
        },
        resetPasswordWindow: {
            header: 'Zmiana hasła logowania do systemu',
            info: 'Reset hasła jest wymagany przez politykę bezpieczeństwa systemu.',
            info2: 'Nowe hasło musi być różne od wcześniej wprowadzonego oraz powinno zawierać 8 znaków (cyfry oraz małe i wielkie litery).',
            enteredPasswordsIsNotIdentical: 'Wprowadzone hasła muszą być identyczne.'
        },

        columns: {
            number: 'Numer',
            term: {
                full: 'Termolabilne',
                short: 'Term.'
            },
            rampNumber: 'Nr&nbsp;rampy',
            group: 'Grupa',
            contact: 'Kontakt',
            supplier: 'Klient',
            cargo: 'Palety',
            unload: 'Rozładunek',
            driver: 'Kierowca',
            phone: 'Telefon',
            car: 'Pojazd',
            trailer: 'Naczepa',
            status: 'Status',
            arrival: 'Wjazd',
            departure: 'Wyjazd',
            carrier: 'Przewoźnik',
            company: 'Firma',
            hour: 'Godzina',
            description: 'Opis',
            serviceman: 'Serwisant',
            deliveryPlace: 'Miejsce dostawy',
            collectionPlace: 'Miejsce odbioru',
            country: 'Kraj',
            city: 'Miasto',
            advice: 'Awizacja',
            timeWindowDate: 'Załadunek',
            creationDate: 'Utworzenie',
            arrivalDate: 'Data dostawy',
            identifier: 'Numer',
            tradesman: 'Handlowiec'
        },

        services: {
            label: 'Serwisy',
            add: 'Zaplanuj serwis'
        },

        delivery: {
            label: 'Dostawa'
        },

        deliveries: {
            label: 'Dostawy',
            add: 'Dodaj dostawę'
        },

        table: {
            loadMore: 'Załaduj więcej danych',
            loading: 'Ładuję'
        },

        statuses: {
            initial: 'Zaawizowany',
            deleted: 'Usunięty',
            finished: 'Zakończony',
            waitingForConfirmation: 'Oczekuje na potwierdzenie'
        },

        checkpoints: {
            plan: 'plan',
            confirmedMessage: 'potw. {{date}} przez {{person}}',
            empty: 'Brak statusów',
            confirm: 'Potwierdź',
            confirmStatus: 'Potwierdź status',
            revokeConfirm: 'Na pewno chcesz cofnąć potwierdzenie tego statusu?',
            revokeManyConfirm: 'Uwaga: jako że nie jest to ostatni potwierdzony status, usuniemy także wszystkie późniejsze potwierdzenia. Na pewno chcesz to zrobić?',
            processFinished: 'Proces jest zakończony!'
        },

        transport: {
            type: {
                TRANSPORT: 'Transport',
                COURIER: 'Kurier',
                COLLECTION: 'Odbiór',
                DELIVERY: 'Dostawa',
                TRANSPORT_ORDER: 'Zlecenie transportowe',
                CONTAINER: 'Kontener',
                OWN_COLLECTION: 'Odbiór własny'
            },
            documents: {
                label: 'Dokumenty',
                header: 'Dokumenty dla dostawy {{identifier}}',
                empty: 'Do tej dostawy nie zostały jeszcze dodane dokumenty.'
            },
            createdBy: 'Utworzony przez',
            plan: 'Zaplanuj',
            description: 'Uwagi',
            dateOfAdvice: 'Data dostawy',
            quantity: 'Ilość jednostek ładunkowych',
            group: 'Grupa dostawy',
            erpIdentifier: 'Numer zamówienia Apollo',
            adviceFor: 'Dostawca',
            trailerRegistrationNumber: 'Numer rejestracyjny naczepy',
            carRegistrationNumber: 'Numer rejestracyjny ciągnika',
            documentNumber: 'Numer dokumentu',
            driverLanguage: 'Język kierowcy',
            driverIdentifier: 'Numer dowodu osobistego',
            driverMobilePhoneNumber: 'Numer telefonu kierowcy',
            driverName: 'Imię i nazwisko kierowcy',
            canNotEditBecauseOfOtherContractors: 'Nie możesz edytować danych awizacji od wielu kontrahentów.',
            canNotEditAfterProcessStarts: 'Możesz edytować tylko dane awizacji, które utworzyłeś oraz przed wjazdem kierowcy na teren zakładu.',
            createTitle: 'Zaplanuj dostawę',
            details: 'Szczegóły dostawy',
            selectSupplier: 'Wybierz dostawcę',
            createdMessage: 'Utworzony {{date}} przez użytkownika {{person}} ({{company}}).',
            title: 'Dostawa {{ident}}',
            noFilesForOldBrowsers: 'Funkcjonalność dodawania plików w tym widoku nie jest dostępna na Twojej przeglądarce. Plik można dodać z poziomu tabeli.',
            selectUnloadStartDate: 'Min. data rozł.',
            selectUnloadStartDateTitle: 'Wybierz minimalną datę rozładunku.',
            selectUnloadStopDate: 'Maks. data rozł.',
            selectUnloadStopDateTitle: 'Wybierz maksymalną datę rozładunku.',
            selectTransportType: 'Wybierz rodzaj dostawy',
            warehouse: 'Magazyn',
            selectWarehouse: 'Wybierz magazyn',
            selectCountry: 'Kraj',
            deleteTransport: 'Czy na pewno chcesz usunąć transport?',
            logs: {
                label: 'Historia zmian',
                start: 'Początek okna czasowego',
                stop: 'Koniec okna czasowego',
                ramp: 'Rampa',
                name: 'Plik',
                initialStartInfo: 'Okno czasowe zostało dociągnięte z godziny {{initialDate}} na godzinę {{startDate}}',
                dateOfDeletion: 'Transport został usunięty',
                dateOfArchiving: 'Transport został przeniesiony do archiwum'
            },
            carrier: {
                label: 'Przewoźnik'
            },
            bigQuantityWarning: 'Czy jesteś pewien, że awizujesz więcej niż jedną naczepę towaru?',
            packs: {
                label: 'Opakowania',
                pallets: 'Palety',
                boxes: 'Kartony'
            },
            contractors: {
                add: {
                    title: 'Dodaj kolejnego kontrahenta'
                },
                'delete': {
                    title: 'Usuń kontrahenta z tego transportu'
                }
            },
            errors: {
                warehouse: {
                    blank: 'Proszę wybrać magazyn'
                },
                erpIdentifier: {
                    blank: 'Proszę podać numer zamówienia'
                },
                dateOfAdvice: {
                    blank: 'Proszę podać datę dostawy',
                    isInPast: 'Nie możesz zaplanować dostawy w przeszłości'
                },
                summaryQuantity: {
                    nonPositiveInteger: 'Ilość palet musi być liczbą całkowitą większą niż  0'
                },
                summaryQuantityBoxes: {
                    summaryQuantityBlank: 'Oszacuj na ilu paletach zmieszczą się zaznaczone kartony i podaj ilość - minimum 1 paleta',
                    nonPositiveInteger: 'Ilość kartonów musi być liczbą całkowitą większą niż 0'
                },
                contractors: {
                    notUnique: 'Proszę podać unikalną listę kontrahentów'
                }
            },
            addNewCompany: {
                label: 'Dodaj firmę, której nie ma w systemie.',
                emailInfo: 'Na podany adres e-mail zostanie wysłane zaproszenie do współpracy.',
                cooperatingCompanies: 'Współpracujesz już z firmą o podobnej nazwie',
                existingCompany: 'Posiadamy już w naszej bazie firmę o NIP-ie',
                existingCompanyAndPartnership: 'Firma o numerze NIP {{taxIdNumber}} jest już zarejestrowana w systemie i należy do grupy Twoich współpracowników',
                assignmentCompany: 'Chcesz przypisać tę firmę jako wykonawcę transportu?',
                sendInvitation: 'Wyślij zaproszenie do współpracy',
                emailRequired: 'Jeśli chcesz wysłać zaproszenie do współpracy musisz podać adres email',
                wrongEmail: 'Podany adres email jest błędny'
            },
            advice: 'Awizuj',
            load: 'Załadunek',
            unload: 'Rozładunek',
            weight: 'Waga',
            volume: 'Objętość',
            arrivalDate: 'Data przybycia',
            wzDocument: 'Numery dok. WZ',
            tradesman: {
                label: 'Handlowiec'
            }
        },

        service: {
            company: 'Firma',
            selectCompany: 'Wybierz firmę',
            serviceman: 'Nazwisko serwisanta',
            registrationNumber: 'Numer rejestracyjny',
            description: 'Opis prac',
            arrival: 'Wjazd',
            departure: 'Wyjazd',
            title: 'Serwis #%@1',
            createTitle: 'Zaplanuj serwis',
            contractorCompanyPlaceholder: 'Nazwa firmy'
        },

        tabs: {
            deliveries: 'Dostawy',
            deliveriesAndServices: 'Transporty',
            archive: 'Archiwum',
            schedule: 'Harmonogram',
            users: 'Użytkownicy',
            companies: 'Firmy',
            settings: 'Ustawienia',
            invitations: 'Zaproszenia'
        },

        warehouses: {
            label: 'Magazyny'
        },

        warehouse: {
            label: 'Magazyn',
            backToDeliveries: 'Lista dostaw',
            hint: 'Aby wybrać termin dostawy kliknij w wolne okno czasowe.',
            firstEarlierFreeStart: 'Najbliższa możliwa wcześniejsza data awizacji to',
            firstSubsequentFreeStart: 'Najbliższa możliwa późniejsza data awizacji to',
            selectCompany: 'Po kliknięciu w wolne pole utwórz okno dedykowane dla firmy',
            sumOfPallets: 'Suma palet',
            windowNoteLabel: 'Treść notatki',
            windowNoteTitle: 'Wprowadź notatkę',
            templates: {
                label: 'Szablony',
                header: 'Szablony okien czasowych dla magazynu',
                copyFrom: 'Skopiuj szablon z dnia',
                saveFor: 'zapisz szablon dla',
                savedMessage: 'Poprawnie zapisaliśmy szablon.',
                datesWithErrorMessage: 'Uwaga: z powodu zmiany czasu nie potrafiliśmy zapisać niektórych okien w dniach: {{windows}}.'
            },
            hdOff: 'Wyłącz HD',
            maxPallets: 'Dzienny limit palet',
            workStartHour: 'Godzina otwarcia',
            workEndHour: 'Godzina zamknięcia',
            numberOfRamps: 'Liczba ramp',
            errors: {
                name: {
                    blank: 'Proszę wybrać nazwę dla tego magazynu.'
                },
                maxPallets: {
                    nonPositiveInteger: 'Limit palet musi być dodatnią liczbą całkowitą.'
                },
                workStartTime: {
                    invalidFormat: 'Proszę podać pełną godzinę w formacie hh:mm, np. 06:00.'
                },
                workEndTime: {
                    invalidFormat: 'Proszę podać pełną godzinę w formacie hh:mm, np. 22:00. Na razie można wybrać jedynie pełną godzinę.',
                    endBeforeStart: 'Koniec pracy magazynu musi się odbywać po rozpoczęciu pracy.'
                }
            },
            updateSuccessMessage: 'Poprawnie zapisaliśmy zmiany ustawień magazynu {{warehouseName}}.',
            disableConfirm: 'Na pewno chcesz wyłączyć ten magazyn?',
            enableConfirm: 'Na pewno chcesz włączyć ten magazyn?',
            disabledMessage: 'Poprawnie usunęliśmy magazyn "{{warehouseName}}".',
            addRamp: 'Dodaj rampę',
            createRampsAfterSave: 'Rampy można będzie ustawić po zapisaniu magazynu.',
            create: 'Utwórz magazyn',
            windowIsLoad: 'Z',
            windowIsUnload: 'R',
            selectWindowInfo: 'Wybierz wolne miejsce w harmonogramie, by dodać awizację do transportu',
            reserveFreePlace: 'Zarezerwuj wolne miejsce w harmonogramie dla transportu {{internalIndex}} na'
        },

        ramps: {
            label: 'Rampy'
        },

        ramp: {
            label: 'Rampa',
            updateSuccessMessage: 'Poprawnie zapisaliśmy zmiany ustawień rampy.',
            disableConfirm: 'Na pewno chcesz wyłączyć tę rampę?',
            enableConfirm: 'Na pewno chcesz włączyć tę rampę?',
            disabledMessage: 'Poprawnie usunęliśmy rampę "{{rampName}}".',
            enabledMessage: 'Poprawnie włączyliśmy rampę "{{rampName}}".',
            idx: 'Kolejność w magazynie',
            errors: {
                name: {
                    blank: 'Proszę wybrać nazwę rampy'
                },
                shortName: {
                    blank: 'Proszę wybrać nazwę skróconą rampy',
                    sizeTooBig: 'Maksymalna długość nazwy skróconej to 10 znaków.'
                },
                idx: {
                    nonPositiveInteger: 'Kolejność rampy w magazynie musi być dodatnią liczbą całkowitą.',
                    idxTooBig: 'Kolejność rampy w magazynie nie może być większa niż liczba ramp tego magazynu.'
                }
            },
            backToWarehouse: 'Powrót do magazynu'
        },

        users: {
            label: 'Użytkownicy'
        },

        user: {
            name: 'Imię i nazwisko',
            username: 'Login',
            email: 'Email',
            mobilePhoneNumber: 'Telefon',
            availableRamps: 'Dostępne rampy',
            noAvailableRamps: 'Brak dostępnych ramp',
            myAccount: 'Moje konto',
            createUser: 'Dodaj użytkownika',
            editUser: 'Edycja użytkownika',
            roleDescription: 'Opis roli',
            list: 'Lista użytkowników',
            selectRole: 'Wybierz rolę',
            availableWarehouses: 'Available warehouses',
            deleteConfirm: 'Na pewno chcesz usunąć użytkownika?',
            deletedMessage: 'Usunięty {{date}} przez użytkownika {{person}} z firmy {{company}}.',
            activeNotifications: 'Aktywne powiadomienia',
            noActiveNotifications: 'Brak aktywnych powiadomień',
            invitedBy: 'Zaproszony: {{date}} przez {{person}}.',
            accountCreatedBy: 'Konto utworzone przez'
        },

        company: {
            label: 'Firma',
            list: 'Lista firm',
            createCompany: 'Dodaj firmę',
            employees: 'Pracownicy',
            shortName: 'Nazwa skrócona',
            selectCountry: 'Wybierz kraj',
            selectType: 'Wybierz typ',
            editCompany: 'Edytuj firmę',
            name: 'Nazwa',
            address: 'Adres',
            taxIdNumber: 'NIP',
            country: 'Kraj',
            noEmployees: 'Firma nie ma pracowników',
            deletedMessage: 'Poprawnie usunęliśmy firmę "{{companyName}}".',
            partnershipStatus: {
                label: 'Status',
                NO_INVITATION: 'Brak zaproszenia',
                WAITING: 'Oczekuje',
                ACCEPTED: 'Zaakceptowane',
                REJECTED: 'Odrzucone'
            },
            type: {
                label: 'Typ',
                SUPPLIER: 'Dostawca',
                CARRIER: 'Przewoźnik',
                SERVICE: 'Serwis',
                producer: 'Producent'
            },
            errors: {
                name: {
                    blank: 'Proszę podać nazwę firmy'
                },
                shortName: {
                    blank: 'Proszę podać skróconą nazwę firmy'
                },
                companyType: {
                    blank: 'Proszę wybrać typ firmy'
                },
                country: {
                    blank: 'Proszę wybrać kraj'
                }
            }
        },

        roleGroup: {
            label: 'Rola',
            placeholder: 'Wybierz rolę',
            CHOOSE_COMPANY: {
                desc: 'Proszę najpierw wybrać firmę.'
            },
            ADMIN: {
                label: 'Admin',
                desc: 'Użytkownik mający wszelkie możliwe uprawnienia. Ma dostęp do wszystkich zakładek. Może tworzyć i edytować użytkowników i firmy.'
            },
            PRODUCER: {
                label: 'Logistyk',
                desc: 'Użytkownik mogący tworzyć i edytować dostawy oraz okna czasowe.'
            },
            GUARD: {
                label: 'Ochrona',
                desc: 'Użytkownik ochrony. Nie może tworzyć ani edytować dostaw.'
            },
            SUPPLIER: {
                label: 'Dostawca',
                desc: 'Dostawca. Może tworzyć dostawy tylko dla swojej firmy. Nie widzi okien czasowych innych dostawców.'
            },
            CARRIER: {
                label: 'Przewoźnik',
                desc: 'Przewoźnik. Może tworzyć dostawy od wielu dostawców. Widzi tylko okna czasowe, dla których jest przewoźnikiem.'
            },
            WAREHOUSEMAN: {
                label: 'Magazyn',
                desc: 'Użytkownik mający dostęp do tabeli dostaw i magazynu. Nie może tworzyć ani edytować dostaw.'
            },
            SERVICEMAN: {
                label: 'Serwis',
                desc: 'Użytkownik mogący tworzyć i edytować serwisy. Ma dostęp do zakładek Dostawy i serwisy, ale nie widzi listy dostaw.'
            },
            OBSERVER: {
                label: 'Obserwator',
                desc: 'Użytkownik mogący jedynie przeglądać dostawy.'
            }
        },

        settings: {
            label: 'Ustawienia',
            emails: {
                label: 'Powiadomienia e-mail',
                lateDocuments: 'opóźnienie w dodawaniu dokumentów'
            },
            backToSettings: 'Powrót do listy ustawień',
            updateSuccessMessage: 'Poprawnie zapisaliśmy zmiany ustawień',
            desc: {
                ARCHIVING_JOB_INTERVAL: 'Ile czasu po potwierdzeniu ostatniego statusu powinniśmy przenieść transport do archiwum.',
                DAYS_TO_RESET_PASSWORD: 'Co ile dni powinien się pojawiać komunikat o konieczności resetu hasła.',
                DELETE_TRANSPORT_INTERVAL: 'Do jakiego momentu w stosunku do planowanej daty rozładunku powinno być możliwe usuwanie transportu.',
                DOCUMENTS_MONITORING_INTERVAL: 'Wielkość opóźnienia w dodaniu dokumentów, po którym wysłany zostanie mail do klienta.',
                FOREIGN_DELIVERY_MULTIPLIER: 'Wartość przez którą mnożona będzie długość rozładunku dla dostaw zagranicznych.',
                INVITATION_EXPIRATION_INTERVAL: 'Okres ważności zaproszenia.',
                RESET_PASSWORD_IS_DISABLED: 'Wyłącz konieczność resetowania hasła.',
                XLS_TOP_ROW_ENABLED: 'Włącz nagłówek dla raportów XLS.',
                WINDOW_CHANGE_MAIL_DELAY_INTERVAL: 'Wielkość opóźnienia, po którym ma być wysłany e-mail po przesunięciu okna czasowego.',
                WINDOW_CONSTANT_TIME: 'Stała wartość w minutach dodawana do długości rozładunku w oknach.',
                TIME_WINDOW_OFFSET_INTERVAL: 'Ilość minut o ile można przesuwać okna czasowe. Możliwe do wprowadzenia wartości to 15, 30 lub 60.'
            },
            transportTypes: {
                label: 'Ustawienia typów transportu',
                header: 'Ustawienia typu transportu',
                permissions: 'Uprawnienia',
                workflow: 'Etapy i statusy',
                permissionsSaveSuccess: 'Poprawnie zapisaliśmy ustawienia uprawnień.'
            }
        },

        page: {
            pageTitle: 'Awizacje Apollo'
        },

        notification: {
            ADVICE_CREATED: {
                label: 'Utworzenie awizacji',
                desc: 'Powiadomienie wysyłane do twórcy po zapisaniu awizacji.'
            },
            ADVICE_UPDATED: {
                label: 'Zmiana awizacji',
                desc: 'Powiadomienie wysyłane do zainteresowanych (np. jeżeli twórcą awizacji jest dostawca, e-mail zostanie wysłany do logistyka oraz przewoźników) po zmianie awizacji.'
            },
            LATE_ADVICE: {
                label: 'Opóźnienie awizacji',
                desc: 'Powiadomienie wysyłane w przypadku opóźnienie przyjazdu kierowcy.'
            },
            LATE_DOCUMENTS: {
                label: 'Opóźnienie w dodaniu dokumentów',
                desc: 'Powiadomienie wysyłane, jeżeli dostawca nie doda dokumentów przewozowych w odpowiednim terminie.'
            },
            roleGroups: {
                label: 'Role użytkowników',
                desc: 'Użytkownicy z daną rolą będą mogli otrzymywać dane powiadomienie oraz włączać/wyłączać chęć otrzymywania go w ustawieniach swojego konta.'
            }
        },

        notifications: {
            label: 'Powiadomienia',
            selectRole: 'Proszę najpierw wybrać rolę.',
            noNotificationsForRole: 'Dla wybranej roli nie ma dostępnych powiadomień'
        },

        workflows: {
            label: 'Ustawienia etapów i statusów',
            addTask: 'Dodaj etap',
            updateDisclaimer: 'Uwaga: zmiany dotyczące czasu zaczną obowiązywać tylko dla NOWYCH transportów.',
            saveSuccessMessage: 'Poprawnie zapisaliśmy ustawienia etapów i statusów.'
        },

        tasks: {
            empty: 'Nie ma tu żadnych zadań.'
        },

        task: {
            label: 'Etap',
            shouldBeConfirmedBy: 'powinien być potw.',
            checkpointName: 'Nazwa etapu',
            stageName: 'Nazwa statusu',
            defaultStagePrefix: 'Potwierdzony',
            expectedDateReference: 'Referencja',
            unit: 'Jednostka',
            sign: 'Przed/Po',
            canBeConfirmedByGuard: 'Możliwość potwierdzania przez ochronę',
            afterConfirmation: {
                willChangeStateTo: 'Po potw. tego etapu transport przejdzie w status',
                willChangeColorTo: 'a jego wiersz w tabeli oraz okno czasowe przyjmą kolor'
            },
            group: {
                0: 'Prace przygotowawcze',
                1: 'Załadunki i rozładunki',
                2: 'Zakończenie procesu'
            },
            expectedDateMode: {
                reference: 'najpóźniej',
                exact: 'o godzinie',
                onDay: 'w dniu'
            },
            reference: {
                before: {
                    transportCreated: 'utworzeniem transportu',
                    earliestStepStart: 'rozp. pierwszego zał./rozł.',
                    latestStepStop: 'zakończ. ostatniego zał./rozł.',
                    stepStart: 'rozpoczęciem',
                    stepStop: 'zakończeniem'
                },
                after: {
                    transportCreated: 'utworzeniu transportu',
                    earliestStepStart: 'rozp. pierwszego zał./rozł.',
                    latestStepStop: 'zakończ. ostatniego zał./rozł.',
                    stepStart: 'rozpoczęciu',
                    stepStop: 'zakończeniu'
                }
            },
            stepType: {
                placeholder: 'Typ akcji',
                load: 'załadunku',
                unload: 'rozładunku'
            },
            tracked: {
                enabled: {
                    label: 'Nadzorowanie punktualności',
                    title: 'W przypadku niezatwierdzenia etapu o czasie transport przejdzie w stan \'spóźnienia\' - wiersz w tabeli oraz okno czasowe zmienią kolor na czerwony oraz zostaną wysłane maile z informacją o spóźnieniu. Kliknij w ikonkę, by' + ' zmienić ustawienia.'
                },
                disabled: {
                    label: '',
                    title: 'Oczekiwany czas potw. etapu będzie tylko informacją poglądową - w przypadku niezatwierdzenia etapu o czasie nie zmienimy koloru wiersza ani okna i nie będziemy wysyłać powiadomień o spóźnieniu. Kliknij w ikonkę, by zmienić' + ' ustawienia.'
                }
            },
            errors: {
                checkpointName: {
                    blank: 'Proszę podać nazwę etapu.'
                },
                stepType: {
                    blank: 'Proszę podać typ akcji.'
                },
                expectedDateReference: {
                    blank: 'Proszę podać referencję.'
                }
            }
        },

        algorithmSettings: {
            label: 'Ustawienia algorytmu obliczania wielkości okna czasowego',
            numberOfPallets: 'Ilość palet',
            loadTimeInMinutes: 'Czas załadunku w minutach',
            deleteSetting: 'Czy na pewno chcesz usunąć to ustawienie?',
            saveSuccessMessage: 'Poprawnie zapisaliśmy ustawienia algorytmu.'
        },

        invitations: {
            label: 'Zaproszenia',
            received: 'Otrzymane',
            sent: 'Wysłane',
            emptyReceived: 'Nie otrzymano jeszcze żadnego zaproszenia.',
            emptySent: 'Nie wysłano jeszcze żadnego zaproszenia.'
        },

        invitation: {
            label: 'Zaproszenie',
            creator: 'Autor',
            invite: 'Zaproś',
            notFound: 'Zaproszenie nie istnieje, lub straciło ważność.',
            alreadyAccepted: 'To zaproszenie zostało już zaakceptowane.',
            createdBy: '{{personName}} zaprasza Cię, byś dołączył do firmy {{companyName}} jako {{roleName}}.',
            toPartnership: '{{personName}} zaprasza Cię, byś rozpoczął współpracę z firmą {{companyName}} jako {{roleName}}.',
            registrationComplete: 'Dziękujemy. Twoje konto zostało zarejestrowane. Możesz się zalogować wykorzystując login {{username}}.',
            sendTo: 'Wyślij zaproszenie',
            sent: 'Zaproszenie zostało wysłane na adres {{email}}.',
            acceptedSummary: 'Zaakceptowane',
            rejectedSummary: 'Odrzucone',
            alreadyHandled: 'Zaproszenie zostało już obsłużone',
            acceptedByAnotherPerson: 'Zaproszenie zostało już zaakceptowane przez inną osobę z Państwa firmy.',
            registeredAccount: 'Zarejestrowane konto',
            'for': {
                JOIN_COMPANY: 'Do swojej firmy',
                SUPPLIER: 'Dostawcę',
                CARRIER: 'Przewoźnika',
                SERVICE: 'Serwisanta'
            },
            type: {
                JOIN_COMPANY: 'Dołącz do firmy',
                SUPPLIER: 'Dołącz jako dostawca',
                CARRIER: 'Dołącz jako przewoźnik',
                SERVICE: 'Dołącz jako serwisant'
            }
        },

        afterRegistration: {
            label: 'Witamy!',
            title: 'Jesteś na dobrej drodze do pożegnania kolejek!',
            info: 'Załóż własny magazyn i zaproś kontrahentów, by planować transporty i awizacje.',
            invitationButton: 'Lista zaproszeń',
            addWarehouseButton: 'Dodaj magazyn'
        },

        customFieldDefinition: {
            settingsFields: 'Ustawienia pól',
            type: {
                number: 'Liczba',
                text: 'Tekst',
                date: 'Data'
            },
            addNewField: 'Dodaj nowe pole',
            saveSuccessMessage: 'Poprawnie zapisaliśmy ustawienia pól.',
            deleteSuccessMessage: 'Poprawnie usunęliśmy ustawienie pola.',
            'delete': 'Czy na pewno chcesz usunąć to pole?',
            cancel: 'Czy na pewno chcesz anulować wprowadzone zmiany?',
            typeField: 'Typ pola',
            name: 'Nazwa',
            englishName: 'Nazwa angielska',
            obligatory: 'Wymagane',
            visibleInTable: 'Widoczne w tabeli'
        },

        wrongUrl: "Brak dostępu lub strona o podanym adresie nie istnieje",

        address: {
            label: 'Adres',
            street: 'Ulica',
            city: 'Miasto',
            postal: 'Kod pocztowy',
            country: 'Kraj',
            addNewAddress: 'Dodaj nowy adres'
        }
    };
});