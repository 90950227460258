define("apollo/pods/components/show-manuals/component", ["exports", "ember", "apollo/config/environment", "apollo/pods/components/show-manuals/template", "apollo/pods/components/custom-popover/component"], function (exports, _ember, _apolloConfigEnvironment, _apolloPodsComponentsShowManualsTemplate, _apolloPodsComponentsCustomPopoverComponent) {
    var service = _ember["default"].inject.service;
    exports["default"] = _apolloPodsComponentsCustomPopoverComponent["default"].extend({

        layout: _apolloPodsComponentsShowManualsTemplate["default"],

        sessionAccount: service('session-account'),

        setManualHref: (function () {
            var href = _apolloConfigEnvironment["default"].serverURL + "/files/manual?token=" + this.get("sessionAccount").get("token");
            this.set("manualHref", href);
        }).on("init"),

        actions: {
            showManuals: function showManuals() {
                this.showPopover(this.element, '.show-manuals-button', '.manual-popover');
            }
        }
    });
});