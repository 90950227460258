define("apollo/mixins/invitations-mixin", ["exports", "ember", "apollo/config/environment"], function (exports, _ember, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Mixin.create({

        sessionAccount: service('session-account'),

        actions: {
            accept: function accept(id) {
                var self = this;
                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + ("/invitations/" + id + "/accept"),
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    console.log("Invitation " + id + " accepted.");
                    self.get('store').findRecord('invitation', id).then(function () {
                        self.get('sessionAccount').get('currentUser').reload();
                        self.transitionToRoute('invitations.show', id);
                    });
                });
            },

            reject: function reject(id) {
                var self = this;
                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + ("/invitations/" + id + "/reject"),
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    console.log("Invitation " + id + " rejected.");
                    self.get('store').findRecord('invitation', id).then(function () {
                        self.get('sessionAccount').get('currentUser').reload();
                        self.transitionToRoute('invitations.show', id);
                    });
                });
            }
        }
    });
});