define('apollo/pods/transports/archive/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        sessionAccount: service('session-account'),
        access: ['ROLE_ARCHIVE_TAB'],
        cookies: service(),

        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        model: function model(params) {
            return this.store.query('transport', {
                archived: true,
                transportTypeId: params.type,
                principalId: this.get('cookies').read('principalId')
            });
        },

        renderTemplate: function renderTemplate() {
            this.render('transports.index');
        },

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            this.controllerFor('transports.index').setProperties({ content: model });
            var transportType = model.get('firstObject') ? model.get('firstObject').get('transportType') : 'transport';
            this.controllerFor('transports.index').setProperties({
                totalPages: model.get('meta.totalPages'),
                archive: true,
                transportType: transportType
            });
        },

        actions: {
            willTransition: function willTransition() {
                this.controllerFor('transports.index').setProperties({
                    page: 1,
                    totalPages: 0
                });
                return true;
            }
        }
    });
});