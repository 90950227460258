define('apollo/pods/address/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        country: _emberData['default'].belongsTo('country'),

        city: _emberData['default'].attr('string'),
        postal: _emberData['default'].attr('string'),
        street: _emberData['default'].attr('string')

    });
});