define('apollo/instance-initializers/pagefront-beacon', ['exports', 'ember-pagefront/instance-initializers/pagefront-beacon'], function (exports, _emberPagefrontInstanceInitializersPagefrontBeacon) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPagefrontInstanceInitializersPagefrontBeacon['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberPagefrontInstanceInitializersPagefrontBeacon.initialize;
    }
  });
});