define("apollo/pods/partnership/model", ["exports", "ember-data"], function (exports, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({

        STATUS: {
            NO_INVITATION: "NO_INVITATION",
            WAITING: "WAITING",
            ACCEPTED: "ACCEPTED",
            REJECTED: "REJECTED"
        },

        principalCompany: _emberData["default"].belongsTo('company', { async: true, inverse: 'acceptedPartnershipsByContractor' }),
        contractorCompany: _emberData["default"].belongsTo('company', { async: true }),
        invitation: _emberData["default"].belongsTo('invitation', { async: true }),
        partnershipType: _emberData["default"].belongsTo('partnershipType', { async: true }),

        status: _emberData["default"].attr('string')
    });
});