define('apollo/pods/components/button-with-confirmation/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'span',
        type: 'button',
        click: function click() {
            var text = this.get('text') || this.get('i18n').t('common.confirmMessage');
            if (confirm(text)) {
                this.get('onConfirm')();
            }
        }
    });
});
//noinspection JSLint