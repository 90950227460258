define("apollo/pods/warehouses/schedule/controller", ["exports", "ember", "apollo/config/environment", "apollo/mixins/schedule-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/attachment-mixin"], function (exports, _ember, _apolloConfigEnvironment, _apolloMixinsScheduleMixin, _apolloMixinsPersistenceMixin, _apolloMixinsAttachmentMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend(_apolloMixinsScheduleMixin["default"], _apolloMixinsPersistenceMixin["default"], _apolloMixinsAttachmentMixin["default"], {

        session: service('session'),
        sessionAccount: service('session-account'),
        clock: _ember["default"].inject.service('ember-clock'),
        cookies: service(),
        store: _ember["default"].inject.service(),

        company: null,

        createTransport: _ember["default"].inject.controller('transports/modals/create-transport'),
        applicationController: _ember["default"].inject.controller('application'),

        errors: [],

        transportToSave: '',

        transportToAdvice: '',

        windowForAdvice: '',

        setScheduleDates: (function () {
            this.setProperties({
                scheduleStartDate: moment().startOf('day'),
                scheduleEndDate: moment().endOf('day')
            });
        }).on('init'),

        queryParams: ['selectedRamp'],
        selectedRamp: null,

        attachments: [],

        selectedDate: moment().startOf('day').toDate(),
        info: null,

        hdMode: false,

        hmClock: _ember["default"].computed('clock.date', function () {
            return moment(this.get('clock.date')).format('HH:mm');
        }),

        viewedColumns: (function () {
            var start = moment(this.get('scheduleStartDate'));
            var end = moment(this.get('scheduleEndDate'));
            var diff = end.diff(start, 'days') + 1;
            return this.get('warehouse.enabledRamps.length') * diff;
        }).property('warehouse.ramps.length', 'scheduleEndDate'),

        warehouse: (function () {
            return this.get('warehouses').get('firstObject');
        }).property('warehouses.@each'),

        warehouses: (function () {
            var self = this;
            if (this.get('model.warehouses')) {
                return this.get('model.warehouses');
            } else {
                return this.get('sessionAccount.currentUser.warehouses').filter(function (w) {
                    return w.get('company.id') === self.get('cookies').read('principalId');
                });
            }
        }).property('model.warehouses'),

        setRamps: (function () {
            var self = this;
            _ember["default"].run.once(this, function () {
                this.get('warehouse').get('enabledRampsForCurrentUser').then(function (ramps) {
                    console.log("ramps: " + ramps);
                    var sortedRamps = ramps.sortBy('idx');
                    var rampsIds = sortedRamps.map(function (r) {
                        return parseInt(r.get('id'));
                    });
                    self.setProperties({
                        sortedRamps: sortedRamps,
                        rampsIds: rampsIds
                    });
                });
            });
        }).observes('warehouse.enabledRampsForCurrentUser').on('init'),

        windows: [],

        agendaView: "day",
        daysDelta: (function () {
            return this.get("agendaView") === 'week' ? 6 : 0;
        }).property("agendaView"),

        isOnDayView: (function () {
            return this.get('agendaView') === "day";
        }).property('agendaView'),

        selectedDateChanged: (function () {
            console.log("selectedDateChanged..");
            var selectedDate = this.get("selectedDate");
            var delta = this.get("daysDelta");
            this.setProperties({
                scheduleStartDate: selectedDate,
                scheduleEndDate: moment(selectedDate).add(delta, "days").endOf("day")
            });

            //todo: dopisac ograniczenie dla dostawcy, zeby nie mogl przesunac sie dalej niz o 2 tyg do przodu, najlepiej
            // dodac je jako parametr w widoku
        }).observes('selectedDate'),

        scheduleSetWindows: (function () {
            console.log("scheduleSetWindows..");
            _ember["default"].run.once(this, 'setWindows');
        }).observes('scheduleEndDate', 'sortedRamps'), //nie mozna nasluchiwac jednoczesnie na
        // scheduleStartDate i scheduleEndDate bo powoduje to
        // podwojne wywolywanie obserwera co powoduje lipe

        setWindows: function setWindows() {
            if (!this.get('warehouse.enabledRamps.length')) {
                return;
            }
            console.log('setWindows');
            var start = moment(this.get("scheduleStartDate")).format("DD.MM.YYYY HH:mm");
            var stop = moment(this.get("scheduleEndDate")).format("DD.MM.YYYY HH:mm");
            var self = this;
            var data = {
                rampsIds: this.get('rampsIds').join(','),
                start: start,
                stop: stop
            };
            return this.store.query("timeWindow", data).then(function (results) {
                self.set('windows', results.toArray());

                if (self.get("hdMode")) {
                    self.scroll();
                }
            });
        },

        sumOfPallets: 0,

        sumOfPalletsObserver: (function () {
            var self = this;
            self.set('sumOfPallets', 0);
            this.get('windows').forEach(function (window) {
                if (!window.get('isDisabled')) {
                    window.get('step').then(function (step) {
                        self.set('sumOfPallets', parseInt(self.get('sumOfPallets')) + parseInt(step.get('quantity')));
                    });
                }
            });
        }).observes('windows.@each', 'warehouse', 'selectedDate'),

        tooMuchPallets: (function () {
            if (this.get('sumOfPallets') > this.get('warehouse.maxPallets')) {
                return 'too-much-pallets';
            }
        }).property('sumOfPallets'),

        storeDisabledWindow: function storeDisabledWindow(start, stop, rampId) {
            var self = this;
            var selectedCompany = this.get("company");
            var windowData = {
                ramp: this.store.peekRecord("ramp", rampId),
                company: selectedCompany,
                start: start,
                stop: stop,
                state: selectedCompany ? 'state-dedicated' : 'state-disabled',
                initialStart: start,
                firstSavedStart: start
            };
            var window = this.store.createRecord("timeWindow", windowData);
            window.save().then(function (savedWindow) {
                self.send('removePopupDialog');

                try {
                    var windows = self.get('windows');
                    windows.pushObject(savedWindow);
                } catch (e) {
                    console.error('nie udal sie push okna / ' + e);
                }
                self.setProperties({
                    errors: [],
                    scheduleClicked: false
                });
            })["catch"](function (response) {
                self.setProperties({
                    errors: response.errors,
                    scheduleClicked: false
                });
            });
        },

        unblockRamp: function unblockRamp(windows) {
            console.log('unblockRamp');
            var self = this;
            var onlyDisabled = true;
            windows.forEach(function (window) {
                if (window.get('state') != "state-disabled") {
                    onlyDisabled = false;
                }
            });
            //wywala blad ale dziala
            //http://stackoverflow.com/questions/15167703/how-to-delete-all-data-from-ember-data-store
            if (onlyDisabled) {
                windows.forEach(function (window) {
                    self.send('removeBlockedWindow', window.get('id'));
                });
            }
        },

        scrollInterval: (function () {
            return 60000;
        }).property().readOnly(),

        activateScrolling: function activateScrolling() {
            console.log("Aktywujemy scrollowanie..");
            this.set('scrollTimer', this.schedule(this.scroll));
        },

        deactivateScrolling: function deactivateScrolling() {
            console.log("Wyłączamy scrollowanie..");
            _ember["default"].run.cancel(this.get('scrollTimer'));
        },

        schedule: function schedule(f) {
            return _ember["default"].run.later(this, function () {
                f.apply(this);
                this.set('scrollTimer', this.schedule(f));
            }, this.get('scrollInterval'));
        },

        scroll: function scroll() {
            var now = moment();
            var h = now.format('H');

            this.set('currentHour', parseInt(h));

            var m = now.format('m');
            var roundedM = Math.floor(parseInt(m) / 15) * 15 % 60;
            console.log("scroll // h: " + h + " / m: " + roundedM);
            var div = $("div[data-schedule-hour='" + h + "'] div[data-schedule-minute='" + roundedM + "']").first();
            $("html, body").animate({ scrollTop: div.offset().top - 200 }, "slow");
        },

        actions: {
            toggleHDMode: function toggleHDMode() {
                var hdMode = !this.get("hdMode");

                if (hdMode) {
                    this.activateScrolling();
                } else {
                    this.deactivateScrolling();
                }

                this.get('applicationController').set('hdMode', hdMode);
                this.setProperties({
                    agendaView: 'day',
                    scheduleEndDate: moment(this.scheduleStartDate).endOf('day'),
                    hdMode: hdMode
                });
            },

            selectWarehouse: function selectWarehouse(warehouseId) {
                console.log("selectWarehouse..");
                var self = this;
                this.store.peekRecord("warehouse", warehouseId).then(function (warehouse) {
                    self.set('warehouse', warehouse);
                });
            },

            onPoll: function onPoll() {
                var _this = this;

                try {
                    (function () {
                        var self = _this;
                        var warehouseId = _this.get('warehouse').get('id');
                        var lastPollFormatted = moment(_this.get('lastPoll')).format();
                        var currentRampsIds = _this.get('rampsIds');

                        var token = _this.get('session.data.authenticated.token');

                        _ember["default"].$.ajax({
                            url: _apolloConfigEnvironment["default"].serverURL + '/warehouses/' + warehouseId + '/updates',
                            data: {
                                lastPoll: lastPollFormatted,
                                start: moment(self.get('scheduleStartDate')).format(),
                                stop: moment(self.get('scheduleEndDate')).format()
                            },
                            type: 'GET',
                            beforeSend: function beforeSend(xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                            }
                        }).then(function (json) {
                            json.timeWindows.forEach(function (w) {
                                if (!currentRampsIds.includes(w.ramp)) {
                                    return;
                                }
                                self.get('store').find('timeWindow', w.id).then(function (window) {
                                    console.log("Refreshing window " + window.id + " for transport " + window.get('transport.id') + "..");

                                    var deleted = window.get('deleted') !== w.deleted || window.get('deleted');
                                    if (deleted) {
                                        self.get('windows').removeObject(window);
                                        console.log("Removed window #" + window.id + ".");
                                    } else if (!window.get('isSaving')) {
                                        console.log("Unloading checkpoints from transport " + window.get('transport.id') + "..");
                                        self.unloadDeletedRecords('transport', window.get('transport.id'), 'checkpoints').then(function () {
                                            window.reload().then(function (result) {
                                                result.get('step').then(function (step) {
                                                    if (step) {
                                                        step.get('transport').then(function (transport) {
                                                            transport.reload().then(function () {
                                                                var lastConfirmedCheckpoint = transport.get('lastConfirmedCheckpoint');
                                                                if (lastConfirmedCheckpoint) {
                                                                    console.log("Last confirmed checkpoint for transport #" + transport.id + " is #" + lastConfirmedCheckpoint.id + ".");
                                                                }
                                                                self.replaceTimeWindow(result);
                                                            });
                                                        });
                                                    } else {
                                                        self.replaceTimeWindow(result);
                                                    }
                                                });
                                            });
                                        });
                                    }
                                });
                            });

                            var pollTime = json.pollTime;
                            self.set('lastPoll', pollTime);
                        });
                    })();
                } catch (e) {
                    console.error(e);
                }
            },

            createWindow: function createWindow(start, stop, rampId, initialStart) {
                if (this.get('scheduleClicked')) {
                    return;
                }
                this.set('scheduleClicked', true);

                if (this.get('transportToAdvice')) {
                    this.send('openCreateAdviceModal', start, stop, rampId, initialStart);
                } else if (this.get('transportToSave')) {
                    this.send('createAdvice', start, stop, rampId, initialStart);
                } else if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
                    this.send('createBlockedWindow', start, rampId);
                } else {
                    console.log('Brak ROLE_BLOCK_RAMPS.');
                }
            },

            openCreateAdviceModal: function openCreateAdviceModal(start, stop, rampId, initialStart) {
                var transport = this.get('transportToAdvice');
                this.send('setAdviceForTransport', transport);

                var advice = {
                    transport: transport,
                    initialStart: initialStart,
                    start: start,
                    stop: stop,
                    rampId: rampId
                };
                this.send("showModal", 'advice.modals.create-advice', advice);
            },

            setAdviceForTransport: function setAdviceForTransport(transport) {
                var advice = this.get('store').createRecord('advice', {});
                transport.set('advice', advice);
            },

            toggleWholeRampWindow: function toggleWholeRampWindow(time, rampId) {
                console.log('toggleWholeRampWindow');
                var self = this;

                if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
                    var start = this.get('warehouse').getStartOnDay(time);
                    var stop = this.get('warehouse').getEndOnDay(time);

                    this.store.findRecord("ramp", rampId).then(function (ramp) {
                        self.store.filter('timeWindow', function (window) {
                            return window.get('ramp.id') === ramp.id && window.get('start') >= start && window.get('stop') <= stop && !window.get('deleted');
                        }).then(function (windows) {
                            if (windows.get('firstObject') == null) {
                                self.storeDisabledWindow(start, stop, rampId);
                            } else {
                                self.unblockRamp(windows);
                            }
                        });
                    });
                } else {
                    console.log('without producer role: ramp click');
                }
            },

            createAdvice: function createAdvice(start, stop, rampId, initialStart) {
                var self = this;
                var transport = this.get('transportToSave');
                if (!transport) {
                    transport = this.get('transportToAdvice');
                }
                var principalCompany = this.store.peekRecord('company', self.get('cookies').read('principalId'));
                this.store.findRecord('ramp', rampId).then(function (ramp) {
                    var timeWindow = self.store.createRecord('timeWindow', {
                        start: start,
                        stop: stop,
                        state: 'on-load',
                        ramp: ramp,
                        initialStart: initialStart
                    });
                    transport.get('stepsWithWarehouse').get('firstObject').set('timeWindow', timeWindow);

                    if (!transport.get('carrierCompany.name')) {
                        transport.set('carrierCompany', null);
                    }

                    transport.set('principalCompany', principalCompany);

                    transport.save().then(function () {
                        self.send('removePopupDialog');

                        timeWindow.unloadRecord();

                        transport.get('advice').then(function (a) {
                            if (a) {
                                a.unloadRecord();
                            }
                        });

                        var transportID = transport.id;
                        var token = self.get('sessionAccount').get('token');

                        console.log("saving file for " + transportID);
                        if (self.attachments) {
                            self.uploadAttachment(transportID, token);
                        }

                        var steps = transport.get('steps'),
                            stepsArray = steps.toArray();

                        stepsArray.forEach(function (s) {
                            if (s === undefined || !s.id) {
                                steps.removeObject(s);
                                if (s !== undefined) {
                                    s.unloadRecord();
                                }
                            }
                        });

                        var customFields = transport.get('customFields'),
                            customFieldsArray = customFields.toArray();

                        customFieldsArray.map(function (cf) {
                            if (cf === undefined || !cf.id) {
                                customFields.removeObject(cf);
                                if (cf !== undefined) {
                                    cf.unloadRecord();
                                }
                            }
                        });

                        self.send('onPoll');

                        var createTransportController = self.get('createTransport');
                        createTransportController.setProperties({
                            model: null,
                            errors: [],
                            attachments: [],
                            showRampContactData: false
                        });

                        _ember["default"].$('.modal').modal('hide');
                        $("body").removeClass("modal-open");

                        self.setProperties({
                            attachments: [],
                            transportToSave: null,
                            scheduleClicked: false
                        });
                    })["catch"](function (response) {
                        var firstEarlierFreeStart = response.firstEarlierFreeStart ? moment(response.firstEarlierFreeStart).toDate() : null;
                        var firstSubsequentFreeStart = response.firstSubsequentFreeStart ? moment(response.firstSubsequentFreeStart).toDate() : null;
                        self.get('createTransport').setProperties({
                            errors: response.errors,
                            showRampContactData: response.showRampContactData,
                            firstEarlierFreeStart: firstEarlierFreeStart,
                            firstSubsequentFreeStart: firstSubsequentFreeStart
                        });

                        self.set('scheduleClicked', false);

                        _ember["default"].$('.modal').show();
                        $("body").addClass("modal-open");
                    });
                });
            },

            showTransportDetails: function showTransportDetails(transport) {
                console.log('showModal');
                this.send("showModal", 'transports.modals.create-transport', transport);
            },

            createBlockedWindow: function createBlockedWindow(start, rampId) {
                this.set('scheduleClicked', true);

                var stop = moment(start).add(this.get("DEFAULT_WINDOW_LENGTH_IN_MINUTES"), "minutes").toDate();
                var laterWindows = this.get('windows').filter(function (w) {
                    return parseInt(w.get('ramp.id')) === rampId && w.get('start') > start;
                });
                if (laterWindows.length) {
                    var laterWindow = laterWindows.sortBy('start').get('firstObject');
                    var laterWindowStart = laterWindow.get('start');
                    stop = laterWindowStart < stop ? laterWindowStart : stop;
                }

                this.storeDisabledWindow(start, stop, rampId);
                this.send('onPoll');
            },

            removeBlockedWindow: function removeBlockedWindow(window) {
                if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
                    var self = this;
                    window.destroyRecord().then(function (w) {
                        self.send('removePopupDialog');
                        self.get('windows').removeObject(w);
                        self.set('errors', null);
                    })["catch"](function (response) {
                        self.set('errors', response.errors);
                    });
                } else {
                    console.log('Brak ROLE_BLOCK_RAMPS.');
                }
            },

            shiftScheduleLeft: function shiftScheduleLeft() {
                var days = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

                var scheduleStartDate = this.get('scheduleStartDate');
                var newStartMoment = moment(scheduleStartDate).subtract(days, 'days');
                this.set('selectedDate', newStartMoment.toDate());
            },

            shiftScheduleRight: function shiftScheduleRight() {
                var days = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

                var scheduleStartDate = this.get('scheduleStartDate');
                var newStartMoment = moment(scheduleStartDate).add(days, 'days');
                this.set('selectedDate', newStartMoment.toDate());
            },

            today: function today() {
                this.set('selectedDate', moment().startOf('day').toDate());
            },

            oneDay: function oneDay() {
                this.setProperties({
                    agendaView: 'day',
                    scheduleEndDate: moment(this.scheduleStartDate).endOf('day')
                });
            },

            oneWeek: function oneWeek() {
                this.setProperties({
                    agendaView: 'week',
                    scheduleEndDate: moment(this.scheduleStartDate).add(6, 'days').endOf('day')
                });
            },

            backToTransportsTable: function backToTransportsTable() {
                this.transitionToRoute('transports.index');
            },

            showTransportToSave: function showTransportToSave() {
                //this.send('showModal', 'transports.modals.create-transport');
                _ember["default"].$('.modal').show();
                $("body").addClass("modal-open");
            },

            openNoteWindow: function openNoteWindow(window) {
                this.send("showModal", 'time-window.modals.window-note', window);
            },

            openPopupDialog: function openPopupDialog(text) {
                this.send("showPopupDialog", 'components.popup-dialog', text);
            }
        }
    });
});
//noinspection JSLint