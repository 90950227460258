define('apollo/pods/invitations/index/controller', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend({

        sessionAccount: service('session-account'),

        receivedInvitations: (function () {
            var currentUserId = this.get('sessionAccount').get('currentUser.id');
            return this.get('model').filter(function (item) {
                return item.get("creator.id") !== currentUserId;
            });
        }).property('model.@each'),

        sentInvitations: (function () {
            var currentUserId = this.get('sessionAccount').get('currentUser.id');
            return this.get('model').filter(function (item) {
                return item.get("creator.id") === currentUserId;
            });
        }).property('model.@each')
    });
});