define('apollo/initializers/moment', ['exports'], function (exports) {
    exports.initialize = initialize;

    function initialize() {
        var language = (navigator.language || navigator.browserLanguage).split('-')[0];
        moment.locale(language ? language : 'en');
        try {
            moment.tz.setDefault('Europe/Warsaw');
        } catch (e) {
            console.error(e);
        }
    }

    exports['default'] = {
        name: 'moment',
        initialize: initialize
    };
});