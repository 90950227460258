define('apollo/pods/components/create-transport-button/component', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        store: _ember['default'].inject.service(),
        sessionAccount: service('session-account'),

        actions: {
            openTransportModal: function openTransportModal(type) {
                var _this = this;

                var store = this.get('store');

                var advice = store.createRecord('advice', {});
                var transport = store.createRecord('transport', {
                    transportType: type,
                    advice: advice
                });

                var warehouses = this.get('sessionAccount').get('currentUser').get('warehouses');

                this.get('sessionAccount.currentUser.company').then(function (comp) {
                    if (comp.get('partnershipType.name') === 'CARRIER') {
                        transport.set('carrierCompany', comp);
                    } else {
                        transport.set('carrierCompany', store.createRecord('company', {}));
                    }
                });

                if (type.get('typeGroup') === "COLLECTION") {
                    (function () {
                        // odbiór - pierwszy etap w magazynie, dowolna ilość rozładunków
                        var loadStep = store.createRecord('step', {
                            ownedByPrincipal: true,
                            stepTypeName: 'load',
                            orderInTransport: 0
                        });
                        if (warehouses.get('length') === 1) {
                            loadStep.set('warehouse', warehouses.get('firstObject'));
                        }
                        transport.get('steps').pushObject(loadStep);

                        var unloadStep = store.createRecord('step', {
                            ownedByPrincipal: false,
                            stepTypeName: 'unload',
                            orderInTransport: 1
                        });
                        _this.get('sessionAccount.currentUser.company').then(function (comp) {
                            if (comp.get('partnershipType.name') === 'SUPPLIER') {
                                unloadStep.set('company', comp);
                            } else {
                                unloadStep.set('company', store.createRecord('company', {}));
                            }
                        });

                        transport.get('steps').pushObject(unloadStep);
                    })();
                } else if (type.get('typeGroup') === "DELIVERY" || type.get('typeGroup') === "COURIER") {
                    (function () {
                        // dostawa - ostatni etap w magazynie, dowolna ilość załadunków lub kurier - przyjazd do klienta, tylko jeden "załadunek"
                        var loadStep = store.createRecord('step', {
                            ownedByPrincipal: false,
                            stepTypeName: 'load',
                            orderInTransport: 0
                        });
                        _this.get('sessionAccount.currentUser.company').then(function (comp) {
                            if (comp.get('partnershipType.name') === 'SUPPLIER') {
                                loadStep.set('company', comp);
                            } else {
                                loadStep.set('company', store.createRecord('company', {}));
                            }
                        });
                        transport.get('steps').pushObject(loadStep);

                        var unloadStep = store.createRecord('step', {
                            ownedByPrincipal: true,
                            stepTypeName: 'unload',
                            orderInTransport: 1
                        });
                        if (warehouses.get('length') === 1) {
                            unloadStep.set('warehouse', warehouses.get('firstObject'));
                        }
                        transport.get('steps').pushObject(unloadStep);
                    })();
                } else if (type.get('typeGroup') === "TRANSPORT") {
                    // zlecenie transportowe - dowolna ilość etapów, w dowolnej konfiguracji
                    var loadStep = store.createRecord('step', {
                        ownedByPrincipal: false,
                        stepTypeName: 'load',
                        orderInTransport: 0
                    });
                    transport.get('steps').pushObject(loadStep);

                    // zakładamy, że najczęściej 'transport' będzie kończył się rozładunkiem w magazynie klienta;
                    // użytkownik będzie mógł to zmienić podczas tworzenia transportu
                    var unloadStep = store.createRecord('step', {
                        ownedByPrincipal: true,
                        stepTypeName: 'unload',
                        orderInTransport: 1
                    });
                    transport.get('steps').pushObject(unloadStep);
                }

                transport.get('transportType').get('customFieldDefinitions').forEach(function (definition) {
                    var customField = store.createRecord('customFieldValue', {
                        definition: definition
                    });
                    transport.get('customFields').pushObject(customField);
                });

                this.set("action", "showModal");
                this.sendAction('action', 'transports.modals.create-transport', transport);
            }
        }
    });
});