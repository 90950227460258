define('apollo/helpers/format-number', ['exports', 'ember-cli-format-number/helpers/format-number'], function (exports, _emberCliFormatNumberHelpersFormatNumber) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliFormatNumberHelpersFormatNumber['default'];
    }
  });
  Object.defineProperty(exports, 'formatNumber', {
    enumerable: true,
    get: function get() {
      return _emberCliFormatNumberHelpersFormatNumber.formatNumber;
    }
  });
});