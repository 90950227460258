define("apollo/pods/transports/modals/create-transport/controller", ["exports", "ember", "ic-ajax", "apollo/config/environment", "apollo/mixins/attachment-mixin"], function (exports, _ember, _icAjax, _apolloConfigEnvironment, _apolloMixinsAttachmentMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend(_apolloMixinsAttachmentMixin["default"], {

        i18n: _ember["default"].inject.service(),
        sessionAccount: service('session-account'),
        cookies: service(),

        scheduleController: _ember["default"].inject.controller('warehouses/schedule'),
        transportController: _ember["default"].inject.controller('transports/index'),

        errors: [],

        browserOld: (function () {
            return _ember["default"].$('html').is('.old');
        }).property(),

        dateOfAdvice: moment().startOf('day').toDate(),

        attachments: [],
        existingFilesToRemove: [],

        ramps: (function () {
            return this.get('sessionAccount.currentUser.ramps');
        }).property(),

        selectedRamp: null,

        transport: (function () {
            return this.get('model');
        }).property('model'),

        canNotEdit: true,

        setCanNotEdit: (function () {
            if (!this.get('model.id') || this.get('sessionAccount').hasRole('ROLE_EDIT_ANY_ADVICE')) {
                this.set('canNotEdit', false);
                return;
            }

            if (this.get('model.archive') || this.get('canNotEditBecauseOfOtherContractors')) {
                this.set('canNotEdit', true);
                return;
            }

            if (!this.get('sessionAccount').hasRole('ROLE_EDIT_ADVICE')) {
                this.set('canNotEdit', false);
                return;
            }

            var self = this;
            var loggedCompany = this.get('sessionAccount.currentUser.company');

            this.get('model.creator').then(function (creator) {
                creator.get('company').then(function (company) {
                    if (creator.get('isPrincipal')) {
                        self.get('model').then(function (transport) {
                            var canAccessTransport = transport.canBeAccessedByCompany(loggedCompany);
                            console.log("Twórca transportu " + transport.id + " jest producentem - zalogowany użytkownik jest z firmy " + loggedCompany.get('name') + "; canAccessTransport: " + canAccessTransport + ".");
                            self.set('canNotEdit', !canAccessTransport);
                        });
                    } else {
                        var transportCreatorCompany = company.get('name');
                        var canAccessTransport = company.get('id') === loggedCompany.get('id');
                        console.log("Twórca transportu to firma " + transportCreatorCompany + " - zalogowany użytkownik jest z firmy " + loggedCompany.get('name') + "; canAccessTransport: " + canAccessTransport + ".");
                        self.set('canNotEdit', !canAccessTransport);
                    }
                });
            });
        }).observes('model'),

        canBeDeleted: (function () {
            if (!this.get('model.id') || this.get('model').get('archived')) {
                return false;
            }
            if (this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE_ANY_TIME')) {
                return true;
            }
            return this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE') && this.get("model.canBeDeleted");
        }).property('model.id', 'model.canBeDeleted', 'model.archived'),

        canNotEditAfterProcessStarts: (function () {
            if (this.get('sessionAccount').hasRole('ROLE_EDIT_ADVICE_ANY_TIME')) {
                return false;
            }
            return this.get('canNotEdit') || this.get('canNotEditBecauseOfOtherContractors') || this.get('model.id') && !this.get('model').get('canBeUpdated');
        }).property('model.id', 'canNotEdit', 'canNotEditBecauseOfOtherContractors', 'model.canBeUpdated'),

        canNotEditBecauseOfOtherContractors: (function () {
            var isCurrentUserContractor = this.get('sessionAccount.currentUser').get('company').get('partnershipType').get('name') === 'SUPPLIER';
            if (!isCurrentUserContractor) {
                return false;
            }
            return this.get('model.contractors.length') > 1;
        }).property('model.contractors.length'),

        canNotChangeWarehouse: (function () {
            return !!(this.get('model') && this.get('model').get('id'));
        }).property('model'),

        principalCompany: (function () {
            return this.store.peekRecord('company', this.get('cookies').read('principalId'));
        }).property(),

        trades: (function () {

            return this.get('principalCompany.trades');
        }).property('principalCompany.trades.@each.id'),

        actions: {

            selectRamp: function selectRamp(id) {
                console.debug("Ramp " + id + " selected.");
                if (!id) {
                    return;
                }
                var self = this;
                this.store.findRecord("ramp", id).then(function (ramp) {
                    self.set("selectedRamp", ramp);
                });
            },

            save: function save() {
                var self = this;
                var transportModel = this.get('model');
                if (transportModel.then !== undefined) {
                    transportModel.then(function (transport) {
                        self.send('updateTransport', transport);
                    });
                } else {
                    self.send('updateTransport', transportModel);
                }
            },

            updateTransport: function updateTransport(transport) {
                var self = this;

                transport.set('principalCompany', self.get('principalCompany'));

                transport.validate();
                self.customValidate();
                if (transport.get('errors.length')) {
                    return;
                }

                console.log("updating transport id " + transport.get('id'));
                transport.save().then(function () {
                    self.existingFilesToRemove.forEach(function (fileId) {
                        console.log("self.existingFilesToRemove..");
                        self.deleteFile(fileId);
                    });
                    self.existingFilesToRemove.clear();

                    var token = self.get('sessionAccount').get('token');
                    if (self.attachments) {
                        self.uploadAttachment(transport.id, token);
                        self.attachments.clear();
                    }
                    // [pawel] ember-data nie nadpisuje stepów po otrzymaniu odpowiedzi z serwera tylko dodaje nowe, więc stare usuwamy je ręcznie
                    transport.get('steps').forEach(function (s) {
                        if (s !== undefined && !s.get('id')) {
                            s.unloadRecord();
                        }
                    });

                    transport.get('advice').then(function (a) {
                        if (a) {
                            a.unloadRecord();
                        }
                    });

                    self.setProperties({
                        model: null,
                        errors: []
                    });
                    _ember["default"].$('.modal').modal('hide');

                    self.get('scheduleController').set('info', null);
                })["catch"](function (response) {
                    self.set('errors', response.errors);
                });
            },

            selectWindow: function selectWindow() {
                var self = this,
                    transport = self.get('transport');

                transport.validate();
                self.customValidate();
                if (transport.get('errors.length')) {
                    return;
                }

                var data = this.get('transport').serialize();
                var stepsWithWarehouse = this.get('transport').get('stepsWithWarehouse');
                var warehouse = undefined;
                if (stepsWithWarehouse.length) {
                    warehouse = stepsWithWarehouse.get('firstObject').get('warehouse');
                    data.warehouse = {
                        id: warehouse.get('id')
                    };
                }
                data.date = moment(this.get('dateOfAdvice')).format('DD.MM.YYYY');

                _ember["default"].$.support.cors = true;
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/transports/validateRamp',
                    type: 'POST',
                    crossDomain: true,
                    data: JSON.stringify(data),
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('sessionAccount').get('token'));
                    }
                }).then(function (response) {
                    if (!response.validates) {
                        self.setProperties({
                            errors: response.errors,
                            showRampContactData: true
                        });
                        return;
                    }
                    _ember["default"].$('.modal').hide();
                    _ember["default"].$(".fade").hide();
                    _ember["default"].$("body").removeClass("modal-open");

                    var info = self.get('i18n').t('warehouse.hint');
                    var date = self.get('dateOfAdvice');
                    var scheduleStartDate = moment(date).startOf('day');
                    var scheduleEndDate = moment(date).endOf('day');
                    var controller = self.get('scheduleController');
                    controller.setProperties({
                        warehouse: warehouse,
                        scheduleStartDate: scheduleStartDate,
                        scheduleEndDate: scheduleEndDate,
                        selectedDate: scheduleStartDate.toDate(),
                        attachments: self.get('attachments'),
                        errors: null,
                        ramps: warehouse.get('ramps'),
                        transportToSave: transport,
                        info: info
                    });

                    self.transitionToRoute('warehouses.schedule');
                });
            },

            cancelCreateWindow: function cancelCreateWindow() {
                var _this = this;

                console.log("cancelCreateWindow..");
                this.set('selectedWarehouse', null);
                var model = this.get('transport');
                if (model) {
                    if (model.rollbackAttributes) {
                        this.rollbackTransportChanges(model);
                    } else {
                        model.then(function (transportModel) {
                            _this.rollbackTransportChanges(transportModel);
                        });
                    }
                }
                this.setProperties({
                    errors: [],
                    model: null
                });
                this.get('scheduleController').set('info', null);

                if (this.get('scheduleController').get('transportToSave')) {
                    this.get('scheduleController').setProperties({
                        transportToSave: null
                    });
                    this.transitionToRoute('transports.index');
                }
            },

            addFiles: function addFiles(files) {
                var attachments = this.get('attachments');

                files.forEach(function (file) {
                    if (!attachments.findBy('name', file.name)) {
                        attachments.pushObject(file);
                    }
                });
            },

            removeFile: function removeFile(file) {
                var attachments = this.get('attachments');
                this.set('attachments', attachments.rejectBy('name', file.name));
            },

            removeExistingFile: function removeExistingFile(file) {
                console.log("removeExistingFile..");
                this.get('existingFilesToRemove').push(file.get('id'));
                $("#file-" + file.get('id')).css("text-decoration", "line-through");
            },

            confirm: function confirm(checkpoint) {
                this.get('transportController').send('confirm', checkpoint);
            },

            deleteTransport: function deleteTransport(transport) {
                var self = this;
                $(".delete-progress").show();
                var token = this.get('sessionAccount').get('token');
                (0, _icAjax["default"])({
                    type: "PUT",
                    url: _apolloConfigEnvironment["default"].serverURL + '/transports/' + transport.get("id") + "/delete",
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    self.setProperties({
                        model: null,
                        errors: []
                    });
                    _ember["default"].$('.modal').modal('hide');
                });
            },

            toggleTransportLogs: function toggleTransportLogs(transport) {
                $(".transport-logs-div").toggle();
                this.send('getTransportLogs', transport);
            },

            getTransportLogs: function getTransportLogs(transport) {
                var self = this;
                var token = this.get('sessionAccount').get('token');
                _ember["default"].$.ajax({
                    url: _apolloConfigEnvironment["default"].serverURL + '/transports/' + transport.get("id") + '/getTransportLogs',
                    type: 'GET',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function (json) {
                    json.transportLogs.forEach(function (transportLog) {
                        self.get('store').findRecord('transportLog', transportLog.id).then(function (t) {
                            transport.get('transportLogs').pushObject(t);
                        });
                    });
                });
            },

            setTradesmanData: function setTradesmanData(item) {
                console.log("setTradesmanData..");
                var self = this;
                this.get('store').findRecord('user', item.id).then(function (user) {
                    self.get("transport").set('tradesman', user);
                });
            }
        },
        customValidate: function customValidate() {
            var validates = true;

            if (!this.get('dateOfAdvice')) {
                this.get('transport').get('errors').add('dateOfAdvice', 'blank');
                validates = false;
            }

            return validates;
        },

        deleteFile: function deleteFile(fileId) {
            console.log("deleteFile: " + fileId);
            this.get('store').findRecord('file', fileId).then(function (f) {
                f.destroyRecord();
            });
        },

        rollbackTransportChanges: function rollbackTransportChanges(transportModel) {
            transportModel.rollbackAttributes();

            transportModel.get('advice').then(function (a) {
                if (a) {
                    a.rollbackAttributes();
                }
            });

            if (transportModel.get('carrierCompany.name')) {
                transportModel.get('carrierCompany').then(function (c) {
                    if (c.id) {
                        c.rollbackAttributes();
                    } else {
                        c.unloadRecord();
                        if (transportModel.get('oldCarrierCompany')) {
                            transportModel.setProperties({
                                company: transportModel.get('oldCarrierCompany'),
                                oldCarrierCompany: null
                            });
                        }
                    }
                });
            }

            transportModel.get('steps').then(function (steps) {
                steps.forEach(function (s) {

                    if (s === undefined) {
                        return true;
                    }

                    s.rollbackAttributes();

                    if (!s.get('company.name')) {
                        return true;
                    }
                    s.get('company').then(function (comp) {
                        if (comp.get('id')) {
                            comp.rollbackAttributes();
                        } else {
                            comp.unloadRecord();
                            if (s.get('oldCompany')) {
                                s.setProperties({
                                    company: s.get('oldCompany'),
                                    oldCompany: null
                                });
                            }
                        }
                    });
                });
            });

            this.get('attachments').clear();
        }
    });
});
//noinspection JSLint