define("apollo/pods/ramp/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {

    var attr = _emberData["default"].attr;
    var hasMany = _emberData["default"].hasMany;
    var belongsTo = _emberData["default"].belongsTo;

    var Ramp = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {

        windows: hasMany('timeWindow', { async: true }),
        warehouse: belongsTo('warehouse', { async: true }),
        usersWithAccess: hasMany('user', { async: true }),
        users: hasMany('user', { async: true }),
        contactPerson: belongsTo('user', { async: true }),

        name: attr('string'),
        shortName: attr('string'),
        idx: attr('number'),
        contactPhoneNumber: attr('string'),

        enabled: attr('boolean'),

        validations: {
            name: {
                presence: { message: 'blank' }
            },
            shortName: {
                presence: { message: 'blank' },
                custom: {
                    validation: function validation(key, value) {
                        return !value || value.length <= 10;
                    },
                    message: 'sizeTooBig'
                }
            },
            idx: {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    onlyInteger: true,
                    message: 'nonPositiveInteger'
                },
                custom: {
                    validation: function validation(key, value, model) {
                        return parseInt(value) < model.get('warehouse').get('ramps.length');
                    },
                    message: 'idxTooBig'
                }
            }
        },

        orderInWarehouse: (function () {
            return this.get('idx') + 1;
        }).property('idx'),

        setIdx: (function () {
            if (this.get('isDeleted')) {
                return;
            }
            var orderInWarehouse = this.get('orderInWarehouse');
            this.set('idx', parseInt(orderInWarehouse) - 1);
        }).observes('orderInWarehouse')
    });

    exports["default"] = Ramp;
});