define('apollo/pods/auth/remind/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        renderTemplate: function renderTemplate(controller, model) {
            this.render('auth/remind', {
                into: 'application',
                outlet: 'login',
                controller: controller,
                model: model
            });
        }
    });
});