define('apollo/initializers/older-browsers', ['exports', 'ember'], function (exports, _ember) {
    exports.initialize = initialize;

    function initialize() /* container, application */{
        _ember['default'].$.ajaxSetup({ cache: false });

        if (_ember['default'].$('html').is('.polyfill')) {

            // trim function for IE8
            String.prototype.trim = function () {
                return this.replace(/^\s+|\s+$/g, '');
            };

            // simple Object.keys polyfill that could work
            if (!Object.keys) {
                Object.keys = function (obj) {
                    var keys = [];

                    for (var i in obj) {
                        if (obj.hasOwnProperty(i)) {
                            keys.push(i);
                        }
                    }

                    return keys;
                };
            }

            if (typeof console === "undefined" || typeof console.log === "undefined") {
                console = {};
                console.log = function (msg) {};
                console.error = function (msg) {};
                console.debug = function (msg) {};
                console.warn = function (msg) {};
                console.info = function (msg) {};
            }
        }
    }

    exports['default'] = {
        name: 'older-browsers',
        after: ['store'],
        initialize: initialize
    };
});
// http://www.gevious.com/2015/01/07/making-an-ember-enterprise-app-work-on-ie8/?utm_source=Ember+Weekly&utm_campaign=6a8c312b3a-Ember_Weekly_Issue_93&utm_medium=email&utm_term=0_e96229d21d-6a8c312b3a-95806901