define('apollo/pods/users/create/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        i18n: _ember['default'].inject.service(),
        session: _ember['default'].inject.service('session'),
        sessionAccount: _ember['default'].inject.service('session-account'),

        canNotEditRamps: (function () {
            return !this.get('sessionAccount').hasRole('ROLE_EDIT_USER');
        }).property('sessionAccount.currentUser'),

        isEditing: (function () {
            return this.get('model').get('id') !== null;
        }).property('model.id'),

        errors: [],

        // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
        proxiedWarehouses: (function () {
            var self = this;
            return this.get('sessionAccount').get('currentUser').get('accessibleWarehouses').map(function (w) {

                var proxiedRamps = self.get('proxiedRamps').filter(function (r) {
                    return r.get('warehouse.id') === w.get('id');
                });

                return _ember['default'].ObjectProxy.create({
                    content: w,
                    proxiedRamps: proxiedRamps,
                    checked: true
                });
            });
        }).property('accessibleWarehouses.@each.id', 'proxiedRamps.@each'),

        setProxiedWarehouses: (function () {
            var self = this;
            _ember['default'].run.once(this, function () {
                self.checkWarehouses();
            });
        }).observes('proxiedWarehouses.length', 'model'),

        accessibleRamps: (function () {
            return this.get('sessionAccount').get('currentUser').get('accessibleRamps');
        }).property('sessionAccount.currentUser'),

        proxiedRamps: _ember['default'].computed.map('accessibleRamps', function (model) {
            return _ember['default'].ObjectProxy.create({
                content: model,
                checked: true
            });
        }),

        setProxiedRamps: (function () {
            var self = this;
            _ember['default'].run.once(this, function () {
                self.checkRamps();
            });
        }).observes('proxiedRamps.length', 'model'),

        proxiedCheckedRamps: _ember['default'].computed.filterBy('proxiedRamps', 'checked', true),

        checkedRamps: _ember['default'].computed.mapBy('proxiedCheckedRamps', 'content'),

        notifications: (function () {
            if (!this.get('model').get('roleGroup.id')) {
                return;
            }
            return this.get('model').get('roleGroup').get('notifications');
        }).property('model.roleGroup.notifications.@each'),

        proxiedNotifications: _ember['default'].computed.map('notifications', function (model) {
            return _ember['default'].ObjectProxy.create({
                content: model,
                checked: true
            });
        }),

        setProxiedNotifications: (function () {
            var self = this;
            _ember['default'].run.once(this, function () {
                self.checkNotifications();
            });
        }).observes('proxiedNotifications.length', 'model'),

        proxiedCheckedNotifications: _ember['default'].computed.filterBy('proxiedNotifications', 'checked', true),

        checkedNotifications: _ember['default'].computed.mapBy('proxiedCheckedNotifications', 'content'),

        actions: {
            setCompany: function setCompany(item) {
                var self = this;
                this.store.findRecord('company', item.id).then(function (company) {
                    self.get('model').setProperties({
                        company: company,
                        roleGroup: company.get('defaultRoleGroup')
                    });
                });
            },

            setRoleGroup: function setRoleGroup(group) {
                this.get('model').setProperties({
                    oldRoleGroup: this.get('model').get('roleGroup'),
                    roleGroup: group
                });
            },

            checkUncheckRamps: function checkUncheckRamps(proxiedWarehouse) {
                var previouslyChecked = proxiedWarehouse.get('checked');
                proxiedWarehouse.get('proxiedRamps').filter(function (r) {
                    r.set('checked', !previouslyChecked);
                });
            },

            save: function save() {
                var self = this;
                var user = this.get('model');

                var checkedRamps = this.get('checkedRamps');
                user.get('ramps').clear();
                checkedRamps.forEach(function (r) {
                    user.get('ramps').pushObject(r);
                });

                if (user.get('password')) {
                    user.set('password', user.get('password').trim());
                }

                user.get('notifications').clear();
                this.get('checkedNotifications').forEach(function (s) {
                    user.get('notifications').pushObject(s);
                });

                user.save().then(function (user) {
                    self.set('errors', []);

                    if (self.get('isEditing')) {
                        self.transitionToRoute('users.show', user.id);
                    } else {
                        self.transitionToRoute('users.index');
                    }
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.get('errors').clear();
                if (!this.get('isEditing')) {
                    this.get('model').unloadRecord();
                    this.transitionToRoute('users.index');
                } else {
                    var user = this.get('model');
                    user.rollbackAttributes();
                    if (user.get('oldRoleGroup')) {
                        user.setProperties({
                            roleGroup: user.get('oldRoleGroup'),
                            oldRoleGroup: null
                        });
                    }
                    this.checkWarehouses();
                    this.checkRamps();
                    this.checkNotifications();
                    this.transitionToRoute('users.show', user);
                }
            },

            'delete': function _delete() {
                var self = this;
                var userModel = this.get('model');
                userModel.destroyRecord().then(function (u) {
                    console.log('Poprawnie usunęliśmy użytkownika ' + u.get('name') + '.');
                    self.transitionToRoute('users.index');
                })['catch'](function (response) {
                    self.set('errors', response.errors);
                });
            }
        },

        checkWarehouses: function checkWarehouses() {
            var proxiedWarehouses = this.get('proxiedWarehouses');
            var isEditing = this.get('isEditing');
            var warehouseIds = this.get('model').get('warehouses').map(function (w) {
                return w.get('id');
            });
            proxiedWarehouses.forEach(function (w) {
                var checked = !isEditing || warehouseIds.includes(w.get('id'));
                w.set('checked', checked);
            });
        },

        checkRamps: function checkRamps() {
            var proxiedRamps = this.get('proxiedRamps');
            var isEditing = this.get('isEditing');
            var userRampsIds = this.get('model').get('rampsIds');
            proxiedRamps.forEach(function (r) {
                var checked = !isEditing || userRampsIds.includes(r.get('id'));
                r.set('checked', checked);
            });
        },

        checkNotifications: function checkNotifications() {
            var proxiedNotifications = this.get('proxiedNotifications');
            var isEditing = this.get('isEditing');
            var userNotifications = this.get('model').get('notifications');
            var userNotificationsIds = userNotifications.map(function (s) {
                return s.get('id');
            });
            proxiedNotifications.forEach(function (s) {
                var checked = !isEditing || userNotificationsIds.includes(s.get('id'));
                s.set('checked', checked);
            });
        }
    });
});
//noinspection JSLint