define("apollo/pods/ramps/create/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        model: function model() {
            return this.store.createRecord("ramp", { enabled: true });
        },

        actions: {
            willTransition: function willTransition() {
                this.get('controller').setProperties({
                    success: null,
                    errors: null
                });
                return true;
            }
        }
    });
});