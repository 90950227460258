define("apollo/pods/users/index/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
        queryParams: {
            query: {
                refreshModel: true
            }
        },

        model: function model(params) {
            if (params) {
                return this.store.query('user', params);
            } else {
                return this.store.findAll('user');
            }
        },

        actions: {
            willTransition: function willTransition(transition) {
                this.get('controller').setProperties({
                    query: '',
                    info: null,
                    errors: null,
                    successMessage: null
                });
                if (transition.targetName !== 'users.index') {
                    this.get('controller').setProperties({
                        queryInput: '',
                        filterActive: false
                    });
                }
                return true;
            }
        }
    });
});