define("apollo/pods/services/modals/create-service/controller", ["exports", "ember", "ic-ajax", "apollo/config/environment"], function (exports, _ember, _icAjax, _apolloConfigEnvironment) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        i18n: _ember["default"].inject.service(),
        sessionAccount: service('session-account'),

        errors: [],

        title: (function () {
            if (this.get('model.id')) {
                return this.get('i18n').t('service.title', this.get('model.internalIndex'));
            }
            return this.get('i18n').t('service.createTitle');
        }).property('model.archived'),

        startH: '',

        service: (function () {
            if (this.get('model')) {
                return this.get('model');
            }
            return this.get('newService');
        }).property('model'),

        newService: (function () {
            var advice = this.store.createRecord('advice', {});
            var service = this.store.createRecord('transport', {
                transportType: 'service', //todo: to nie zadziala bo zmienil sie typ
                description: '',
                advice: advice
            });

            var carrierCompany = this.store.createRecord("company", {
                enabled: true
            });
            service.set("carrierCompany", carrierCompany);

            var timeWindow = this.store.createRecord('timeWindow', {
                start: moment().add(1, 'hours').startOf('hour').toDate(),
                stop: moment().add(2, 'hours').startOf('hour').toDate()
            });

            var step = this.store.createRecord('step', {
                timeWindow: timeWindow
            });

            service.get('steps').pushObject(step);
            return service;
        }).property(),

        canNotEdit: (function () {
            if (!this.get('sessionAccount').hasRole('ROLE_EDIT_ADVICE')) {
                return true;
            }
            return this.get('model') && this.get('model').get('archived');
        }).property('model.id', 'model.archived', 'sessionAccount.currentUser'),

        canBeDeleted: (function () {
            if (!this.get('model.id') || this.get('model').get('archived')) {
                return false;
            }
            if (this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE_ANY_TIME')) {
                return true;
            }
            return this.get('sessionAccount').hasRole('ROLE_DELETE_ADVICE') && this.get("model.canBeDeleted");
        }).property('model.canBeDeleted', 'model.archived'),

        actions: {

            setAdviceData: function setAdviceData(item) {
                this.get("service").get("advice").setProperties({
                    driverName: item.driverName,
                    driverIdentifier: item.driverIdentifier,
                    driverMobilePhoneNumber: item.driverMobilePhoneNumber,
                    carRegistrationNumber: item.carRegistrationNumber,
                    trailerRegistrationNumber: item.trailerRegistrationNumber
                });
            },

            setCompanyName: function setCompanyName(name) {
                this.get("service").get("carrierCompany").setProperties({
                    name: name
                });
            },

            save: function save() {
                var self = this;

                self.get('service').save().then(function (service) {
                    // [pawel] z jakiegoś powodu po zapisaniu serwisu mamy do transportu przypisane dwa stepy zamiast jednego; jeśli będzie czas, trzeba to rozwiązać inaczej
                    service.get('steps').forEach(function (s) {
                        if (!s.get('id')) {
                            s.unloadRecord();
                        }
                    });
                    self.setProperties({
                        errors: [],
                        model: self.newService
                    });
                    _ember["default"].$('.modal').modal('hide');
                    self.transitionToRoute('transports.index');
                })["catch"](function (response) {
                    self.set('errors', response.errors);
                });
            },

            cancel: function cancel() {
                this.setProperties({
                    errors: [],
                    model: self.newService
                });
                _ember["default"].$('.modal').modal('hide');
            },

            deleteTransport: function deleteTransport(transport) {
                var self = this;
                $(".delete-progress").show();
                (0, _icAjax["default"])({
                    type: "PUT",
                    url: _apolloConfigEnvironment["default"].serverURL + '/transports/' + transport.get("id") + "/delete"
                }).then(function () {
                    self.setProperties({
                        errors: [],
                        model: self.newService
                    });
                    _ember["default"].$('.modal').modal('hide');
                });
            }
        }
    });
});