define("apollo/pods/transport-types/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {

        sessionAccount: service('session-account'),
        access: ['ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS'],

        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        model: function model(params) {
            return _ember["default"].RSVP.hash({
                transportType: this.store.findRecord('transport-type', params.id, { reload: true }),
                transportTypes: this.store.findAll('transport-type', { reload: true })
            });
        }
    });
});