define('apollo/pods/algorithm-settings/create/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.store.createRecord('algorithm-setting', {});
        },

        renderTemplate: function renderTemplate() {
            this.render('algorithmSettings.edit');
        },
        setupController: function setupController(controller, model) {
            this.controllerFor('algorithmSettings.edit').setProperties({ content: model });
        },

        actions: {
            willTransition: function willTransition() {
                this.controllerFor('algorithmSettings.edit').setProperties({
                    success: false,
                    errors: []
                });
                return true;
            }
        }
    });
});