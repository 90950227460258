define('apollo/pods/custom-field-definition/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
    exports['default'] = _emberData['default'].Model.extend({

        i18n: _ember['default'].inject.service(),

        FIELD_TYPES: {
            TYPE_TEXT: 'text',
            TYPE_DATE: 'date',
            TYPE_NUMBER: 'number'
        },

        transportType: _emberData['default'].belongsTo('transportType', { async: true }),

        type: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        englishName: _emberData['default'].attr('string'),
        obligatory: _emberData['default'].attr('boolean'),
        visibleInTable: _emberData['default'].attr('boolean'),

        isTextType: (function () {
            return this.get('type') === this.get('FIELD_TYPES').TYPE_TEXT;
        }).property(),

        isNumberType: (function () {
            return this.get('type') === this.get('FIELD_TYPES').TYPE_NUMBER;
        }).property(),

        isDateType: (function () {
            return this.get('type') === this.get('FIELD_TYPES').TYPE_DATE;
        }).property(),

        availableFieldTypes: (function () {
            var text = this.get('FIELD_TYPES').TYPE_TEXT;
            var number = this.get('FIELD_TYPES').TYPE_NUMBER;
            var date = this.get('FIELD_TYPES').TYPE_DATE;
            return [text, number, date];
        }).property(),

        nameInAppropriateLanguage: (function () {
            if (this.get('i18n.locale') !== 'pl' && this.get('englishName')) {
                return this.get('englishName');
            } else {
                return this.get('name');
            }
        }).property('name', 'englishName')

    });
});