define("apollo/pods/components/show-notes/component", ["exports", "apollo/pods/components/show-notes/template", "apollo/pods/components/custom-popover/component"], function (exports, _apolloPodsComponentsShowNotesTemplate, _apolloPodsComponentsCustomPopoverComponent) {
    exports["default"] = _apolloPodsComponentsCustomPopoverComponent["default"].extend({
        layout: _apolloPodsComponentsShowNotesTemplate["default"],

        actions: {
            showNotes: function showNotes(transport) {
                this.showPopover(this.element, '.notes-button', '.notes-popover');

                $(this.element).find('#noteInput').focus();
                this.set("action", "markNotesAsReadOff");
                this.sendAction('action', transport);
            },

            addNote: function addNote() {
                var $el = $(this.element);
                var noteText = $el.find('#noteInput').val();
                var transport = this.get('transport');
                var id = transport.get('id');
                if (noteText) {
                    this.set("action", "addNote");
                    this.sendAction('action', noteText, id);
                    this.destroyPopovers();
                    console.log("note added");
                }
            },

            sendNoteByEmail: function sendNoteByEmail(note) {
                this.set("sendingNote", true);
                this.set("action", "sendNoteByEmail");
                this.sendAction('action', note);
            }
        },

        willDestroy: function willDestroy() {
            console.log("wD!");
        }
    });
});