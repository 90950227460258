define("apollo/pods/company/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {
    exports["default"] = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {

        users: _emberData["default"].hasMany('user', { async: true }),
        groups: _emberData["default"].hasMany('roleGroup', { async: true }),
        partnerships: _emberData["default"].hasMany('partnership', { async: true, inverse: 'principalCompany' }),
        acceptedPartnershipsByContractor: _emberData["default"].hasMany('partnership', { async: true, inverse: 'contractorCompany' }),
        suppliers: _emberData["default"].hasMany('company', { async: true }),
        availableRoles: _emberData["default"].hasMany('role', { async: true }),
        transportTypes: _emberData["default"].hasMany('transportType', { async: true }),
        addresses: _emberData["default"].hasMany('address', { async: true }),

        country: _emberData["default"].belongsTo('country', { async: true }),
        defaultRoleGroup: _emberData["default"].belongsTo('roleGroup', { async: true }),
        partnershipType: _emberData["default"].belongsTo('partnershipType', { async: true }),

        isPrincipal: _emberData["default"].attr('boolean'),
        name: _emberData["default"].attr('string'),
        shortName: _emberData["default"].attr('string'),
        dateCreated: _emberData["default"].attr(),
        taxIdNumber: _emberData["default"].attr('string'),
        lastUpdated: _emberData["default"].attr(),
        email: _emberData["default"].attr('string'),
        enabled: _emberData["default"].attr('boolean'),

        validations: {
            name: {
                presence: { message: 'blank' }
            },
            shortName: {
                presence: { message: 'blank' }
            },
            country: {
                presence: { message: 'blank' }
            }
        },

        symbol: (function () {
            try {
                return this.get('shortName').substr(0, 2);
            } catch (e) {
                try {
                    return this.get('name').substr(0, 2);
                } catch (e) {
                    console.log('brak nazwy firmy dostawcy');
                    return '';
                }
            }
        }).property('name'),

        sortedUsers: (function () {
            return this.get('users').sortBy('name');
        }).property('users.@each.name'),

        shortNameOrName: (function () {
            return this.get('shortName') ? this.get('shortName') : this.get('name');
        }).property('shortName', 'name'),

        trades: (function () {
            var _this = this;

            return this.store.findAll('role-group').then(function () {
                return _this.get('users').filter(function (user) {
                    return user.get('roleGroup.name') === 'TRADESMAN';
                });
            });
        }).property('users.@each.id', 'users.@each.roleGroup.name')
    });
});