define('apollo/pods/step/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        STEP_TYPES: {
            LOAD: 'load',
            UNLOAD: 'unload'
        },

        transport: _emberData['default'].belongsTo('transport', { async: true }),
        timeWindow: _emberData['default'].belongsTo('timeWindow', { async: true }),
        company: _emberData['default'].belongsTo('company', { async: true }),
        address: _emberData['default'].belongsTo('address', { async: true }),
        warehouse: _emberData['default'].belongsTo('warehouse', { async: true }),

        stepTypeName: _emberData['default'].attr('string'),
        orderInTransport: _emberData['default'].attr(),
        documentIdentifier: _emberData['default'].attr(),

        quantity: _emberData['default'].attr('number'),
        arrivalDate: _emberData['default'].attr('isodate'),

        document: _emberData['default'].attr('string'),
        weight: _emberData['default'].attr('number'),
        volume: _emberData['default'].attr('number'),

        sortProperty: (function () {
            return this.get('start') ? this.get('start').getTime() : '';
        }).property('start'),

        start: (function () {
            return this.get('timeWindow').get('start');
        }).property('timeWindow.start'),

        isLoad: (function () {
            console.log('st: ' + this.get('stepTypeName'));
            return this.get('stepTypeName') === this.get('STEP_TYPES').LOAD;
        }).property('stepTypeName'),

        isUnload: (function () {
            return this.get('stepTypeName') === this.get('STEP_TYPES').UNLOAD;
        }).property('stepTypeName'),

        canChangeOwnedByPrincipal: (function () {
            return this.get('transport').get('transportType.typeGroup') === "TRANSPORT";
        }).property('transport.transportType'),

        companyName: (function () {
            return this.get('company.name');
        }).property('company'),

        country: (function () {
            return this.get('company.country');
        }).property('company')
    });
});