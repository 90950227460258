define('apollo/pods/invitation/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        partnershipType: _emberData['default'].belongsTo('partnershipType', { async: true }),
        creator: _emberData['default'].belongsTo('user', { async: true }),
        registeredAccount: _emberData['default'].belongsTo('user', { async: true, inverse: 'invitation' }),
        personWhoAccepted: _emberData['default'].belongsTo('user', { async: true }),
        personWhoRejected: _emberData['default'].belongsTo('user', { async: true }),
        roleGroup: _emberData['default'].belongsTo('roleGroup', { async: true }),

        origin: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        enabled: _emberData['default'].attr('boolean'),

        expirationDate: _emberData['default'].attr('date'),
        dateAccepted: _emberData['default'].attr('date'),
        dateRejected: _emberData['default'].attr('date'),
        dateCreated: _emberData['default'].attr('date'),
        lastUpdated: _emberData['default'].attr('date'),

        isAccepted: (function () {
            return !!this.get('dateAccepted');
        }).property('dateAccepted'),

        isRejected: (function () {
            return !!this.get('dateRejected');
        }).property('dateRejected'),

        isHandled: (function () {
            return !!this.get('dateAccepted') || !!this.get('dateRejected');
        }).property('dateAccepted', 'dateRejected'),

        isToJoinCompany: (function () {
            return !this.get('partnershipType.id');
        }).property('partnershipType.id'),

        type: (function () {
            return this.get('isToJoinCompany') ? 'JOIN_COMPANY' : this.get('partnershipType').get('name');
        }).property('partnershipType.name')
    });
});
//noinspection NpmUsedModulesInstalled