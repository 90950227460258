define("apollo/pods/transport-types/permissions/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {

        sessionAccount: service('session-account'),
        access: ['ROLE_SHOW_PERMISSIONS_TRANSPORT_TYPE'],

        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        model: function model(params) {
            return _ember["default"].RSVP.hash({
                transportType: this.store.findRecord('transport-type', params.id, { reload: true }),
                roleGroups: this.store.query('role-group', { transportTypeId: params.id })
            });
        }
    });
});