define('apollo/pods/warehouses/add-ramp/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            var warehouseId = params.warehouse_id;

            var ramp = this.store.createRecord('ramp', {
                enabled: true
            });

            return _ember['default'].RSVP.hash({
                warehouse: this.store.findRecord('warehouse', warehouseId),
                ramp: ramp
            });
        },

        renderTemplate: function renderTemplate() {
            this.render('ramps.create');
        },

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);

            var ramp = model.ramp;
            ramp.setProperties({
                warehouse: model.warehouse,
                idx: model.warehouse.get('enabledRamps.length')
            });
            this.controllerFor('ramps.create').setProperties({ content: ramp });
        }
    });
});