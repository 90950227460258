define('apollo/helpers/has-unread-notes', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Helper.extend({

        sessionAccount: service('session-account'),

        compute: function compute(params) {
            this.set('value', params[0]);

            return this.get('content');
        },

        valueDidChange: _ember['default'].observer('value', function () {
            var value = this.get('value');

            if (_ember['default'].isEmpty(value)) {
                _ember['default'].defineProperty(this, 'content', null);
                return;
            }

            var self = this;
            _ember['default'].defineProperty(this, 'content', _ember['default'].computed('value', function () {
                var value = self.get('value');
                var sessionAccount = self.get('sessionAccount');

                var notesReadByUser = value.filter(function (n) {
                    return n.hasBeenReadBy(sessionAccount);
                });

                return value.get('length') !== notesReadByUser.length;
            }));
        }),

        contentDidChange: _ember['default'].observer('content', function () {
            this.recompute();
        })
    });
});
//noinspection JSLint