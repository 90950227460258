define('apollo/pods/components/transport/step-section/component', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        store: service(),
        cookies: service(),

        addNewAddress: false,

        countries: (function () {
            return this.get('store').findAll('country').then(function (items) {
                return items.sortBy('ibanSymbol');
            });
        }).property(),

        companyType: "SUPPLIER",

        companies: (function () {
            var self = this;
            var principalCompanyId = self.get('cookies').read('principalId');

            return self.get('store').query('company', {
                principalCompanyId: principalCompanyId,
                companyType: self.get('companyType')
            }).then(function (items) {
                return items;
            });
        }).property(),

        actions: {
            selectWarehouse: function selectWarehouse(step, warehouseId) {
                var self = this;
                this.get('store').findRecord("warehouse", warehouseId).then(function (warehouse) {
                    step.set('warehouse', warehouse);
                    self.get('transport').get('errors').remove('warehouse');
                });
            },

            deleteStep: function deleteStep(idx) {
                var transport = this.get('step.transport');
                var stepArray = transport.get('steps').filterBy('orderInTransport', idx);

                if (!stepArray) {
                    return;
                }

                var step = stepArray.get('firstObject');
                transport.get('steps').removeObject(step);
                step.unloadRecord();

                // Usuwamy ewentualne błędy dot. nieunikalnych dostawców
                var supplierNames = transport.get('supplierNames');
                if (supplierNames.length === supplierNames.uniq().length) {
                    transport.get('errors').remove('contractors');
                }

                // Przeliczamy kolejność stepów w transporcie
                transport.get('steps').sortBy('orderInTransport').forEach(function (s, idx) {
                    console.log('idx: ' + idx);
                    s.set('orderInTransport', idx);
                });
            },

            setContractorCompanyData: function setContractorCompanyData(step, item) {
                this.get('store').findRecord('company', item.id).then(function (company) {
                    step.set('company', company);

                    var supplierNames = step.get('transport').get('supplierNames');
                    if (supplierNames.length !== supplierNames.uniq().length) {
                        step.get('transport').get('errors').add('contractors', 'notUnique');
                    } else {
                        step.get('transport').get('errors').remove('contractors');
                    }
                });
            },

            selectContractorCompany: function selectContractorCompany(id) {
                if (!id) {
                    return;
                }
                var self = this;
                this.get('store').findRecord("company", id).then(function (company) {
                    self.get("transport").set("contractorCompany", company);
                });
            },

            offScroll: function offScroll() {
                $(':input').on('mousewheel', function (event) {
                    event.preventDefault();
                });
            },

            toggleOwnedByPrincipal: function toggleOwnedByPrincipal(step, b) {
                step.set('ownedByPrincipal', b);
            },

            toogleNewAddress: function toogleNewAddress(step) {
                if (this.get('addNewAddress')) {
                    this.send('deleteAddressFromStep', step);
                    this.set('addNewAddress', false);
                } else {
                    this.send('setAddressForStep', step);
                    this.set('addNewAddress', true);
                }
            },

            deleteAddressFromStep: function deleteAddressFromStep(step) {
                step.get('address').then(function (address) {
                    address.unloadRecord();
                });
            },

            setAddressForStep: function setAddressForStep(step) {
                step.set('address', this.get('store').createRecord('address', {}));
            },

            setCompanies: function setCompanies() {
                this.notifyPropertyChange('companies');
            },

            setAddressData: function setAddressData(address) {
                var self = this;
                self.get("step.address").setProperties({
                    street: address.street,
                    postal: address.postal,
                    city: address.city
                });

                self.get('store').findRecord('country', address.country).then(function (c) {
                    self.get('step.address').set('country', c);
                });
            }
        }
    });
});