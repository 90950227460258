define("apollo/pods/warehouse/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (exports, _emberData, _apolloMixinsModelValidator) {

    var attr = _emberData["default"].attr;
    var hasMany = _emberData["default"].hasMany;
    var service = Ember.inject.service;

    var Warehouse = _emberData["default"].Model.extend(_apolloMixinsModelValidator["default"], {

        sessionAccount: service('session-account'),

        users: hasMany('user', { async: true }),
        usersWithAccess: hasMany('user', { async: true }),

        ramps: hasMany('ramp', { async: true }),

        company: _emberData["default"].belongsTo('company', { async: true }),
        address: _emberData["default"].belongsTo('address', { async: true }),

        name: attr('string'),
        maxPallets: attr('number'),
        workStartHour: attr('number'),
        workEndHour: attr('number'),

        enabled: attr('boolean'),

        validations: {
            name: {
                presence: { message: 'blank' }
            },
            maxPallets: {
                custom: {
                    validation: function validation(key, value) {
                        return value.toString().match(/^[0-9]+$/);
                    },
                    message: 'nonPositiveInteger'
                }
            },
            workStartTime: {
                custom: {
                    validation: function validation(key, value) {
                        return value.match(/^([01]\d|2[0-4]):00$/);
                    },
                    message: 'invalidFormat'
                }
            },
            workEndTime: {
                custom: [{
                    validation: function validation(key, value) {
                        return value.match(/^([01]\d|2[0-4]):00$/);
                    },
                    message: 'invalidFormat'
                }, {
                    validation: function validation(key, value, model) {
                        if (!value.match(/^([01]\d|2[0-4]):00$/)) {
                            return true;
                        }
                        var h = value.split(':')[0];
                        return parseInt(model.get('workStartHour')) <= parseInt(h);
                    },
                    message: 'endBeforeStart'
                }]
            }
        },

        workStartTime: (function () {
            return moment(this.getStartOnDay(new Date())).format('HH:mm');
        }).property('workStartHour'),

        setWorkStartHour: (function () {
            if (this.get('isDeleted')) {
                return;
            }
            var h = this.get('workStartTime').split(':')[0];
            this.set('workStartHour', parseInt(h));
        }).observes('workStartTime'),

        workEndTime: (function () {
            return moment(this.getEndOnDay(new Date())).format('HH:mm');
        }).property('workEndHour'),

        setWorkEndHour: (function () {
            if (this.get('isDeleted')) {
                return;
            }
            var h = this.get('workEndTime').split(':')[0];
            this.set('workEndHour', parseInt(h));
        }).observes('workEndTime'),

        hours: (function () {
            var hoursArray = [];
            for (var i = this.get('workStartHour'); i < this.get('workEndHour'); ++i) {
                hoursArray.push(i);
            }
            return hoursArray;
        }).property('workStartHour', 'workEndHour'),

        minutes: (function () {
            return [0, 15, 30, 45];
        }).property(),

        getStartOnDay: function getStartOnDay(date) {
            return moment(date).startOf('day').add(this.get('workStartHour'), 'hours').toDate();
        },

        getEndOnDay: function getEndOnDay(date) {
            return moment(date).startOf('day').add(this.get('workEndHour'), 'hours').toDate();
        },

        enabledRamps: (function () {
            return _emberData["default"].PromiseArray.create({
                promise: this.get('ramps').then(function (loadedRamps) {
                    return loadedRamps.filterBy('enabled', true);
                })
            });
        }).property('ramps.@each.enabled'),

        sortedRamps: (function () {
            return this.get('ramps').sortBy('idx');
        }).property('enabledRamps.@each.idx'),

        enabledRampsForCurrentUser: (function () {
            var self = this;

            return _emberData["default"].PromiseArray.create({
                promise: self.get('sessionAccount.currentUser.ramps').then(function (loadedRamps) {
                    return loadedRamps.filterBy('warehouse.id', self.get('id'));
                })
            });
        }).property('sessionAccount.currentUser.ramps')

    });

    exports["default"] = Warehouse;
});