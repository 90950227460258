define('apollo/initializers/hide-popovers', ['exports'], function (exports) {
    exports.initialize = initialize;

    function initialize() {

        var destroyPopovers = function destroyPopovers() {
            $("[data-toggle='popover']").popover('destroy');
        };

        $('html').on('mouseup', function (e) {
            if (!$(e.target).closest('.popover').length && $(e.target).data('toggle') !== 'popover') {
                destroyPopovers();
            }
        });

        $(document).keyup(function (e) {
            if (e.keyCode === 27) {
                // Esc key event
                if (!$(e.target).closest('.popover').length) {
                    destroyPopovers();
                }
            }
        });
    }

    exports['default'] = {
        name: 'hide-popovers',
        initialize: initialize
    };
});