define('apollo/pods/warehouses/template/route', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({

        sessionAccount: service('session-account'),
        access: ['ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES'],

        beforeModel: function beforeModel(transition) {
            this.store.unloadAll('timeWindow');
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        model: function model(params) {
            var warehouseId = params.warehouse_id;
            var date = moment(params.date_string);
            return _ember['default'].RSVP.hash({
                warehouse: this.store.findRecord('warehouse', warehouseId),
                warehouses: this.store.findAll('warehouse'),
                date: date
            });
        },

        setupController: function setupController(controller, model) {
            controller.setProperties({
                windows: null,
                content: model,
                scheduleStartDate: model.date.toDate()
            });
        },

        actions: {
            willTransition: function willTransition() {
                this.get('controller').set('windows', null);
                this.store.unloadAll('timeWindow');
                return true;
            }
        }
    });
});