define('apollo/initializers/model-locale', ['exports', 'ember-model-validator/initializers/model-locale'], function (exports, _emberModelValidatorInitializersModelLocale) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberModelValidatorInitializersModelLocale['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberModelValidatorInitializersModelLocale.initialize;
    }
  });
});