define("apollo/pods/components/show-docs/component", ["exports", "ember", "apollo/pods/components/show-docs/template", "apollo/pods/components/custom-popover/component"], function (exports, _ember, _apolloPodsComponentsShowDocsTemplate, _apolloPodsComponentsCustomPopoverComponent) {
    var service = _ember["default"].inject.service;
    exports["default"] = _apolloPodsComponentsCustomPopoverComponent["default"].extend({

        sessionAccount: service('session-account'),

        layout: _apolloPodsComponentsShowDocsTemplate["default"],
        errors: [],
        uploading: false,
        completed: 0,
        action: 'fileUploadSuccess',
        attachments: [],

        actions: {
            showDocs: function showDocs() {
                this.showPopover(this.element, '.show-documents-button', '.documents-popover');
            },

            fileUploadSuccess: function fileUploadSuccess(transportId) {
                this.set('action', 'fileUploadSuccess');
                this.sendAction("action", transportId);
                this.destroyPopovers();
            },

            deleteFile: function deleteFile(file) {
                this.set('action', 'deleteFile');
                this.sendAction('action', file);
                this.destroyPopovers();
            }
        }
    });
});