define("apollo/pods/reset-password/controller", ["exports", "ember", "apollo/config/environment", "ember-simple-auth/authenticators/base"], function (exports, _ember, _apolloConfigEnvironment, _emberSimpleAuthAuthenticatorsBase) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        session: service('session'),
        sessionAccount: service('session-account'),
        i18n: _ember["default"].inject.service(),
        errors: [],

        actions: {
            resetPassword: function resetPassword() {
                var self = this;
                var user = self.get('sessionAccount').get('currentUser');

                var passwords = self.getProperties('password', 'confirmedPassword');
                if (passwords.password) {
                    passwords.password = passwords.password.trim();
                }
                if (passwords.password !== passwords.confirmedPassword) {
                    var message = self.get('i18n').t('resetPasswordWindow.enteredPasswordsIsNotIdentical');
                    this.set('errorMessage', message);
                    return;
                }
                user.set('password', passwords.password);
                user.save().then(function () {
                    self.get('sessionAccount').set('passwordValid', true);
                    self.transitionToRoute('transports');
                })["catch"](function (response) {
                    self.set('errors', response.errors);
                });
            },
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate(this.get('sessionAccount'));
            }
        }
    });
});