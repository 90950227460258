define("apollo/pods/time-window/modals/window-note/controller", ["exports", "ember"], function (exports, _ember) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend({

        i18n: _ember["default"].inject.service(),
        sessionAccount: service('session-account'),

        title: (function () {
            return "#" + this.get('model.id') + " " + this.get('i18n').t('warehouse.windowNoteTitle');
        }).property(),

        errors: [],

        canNotEdit: (function () {
            return !this.get('sessionAccount').hasRole('ROLE_ADD_WINDOW_NOTES');
        }).property(),

        actions: {
            save: function save() {
                var window = this.get('model');
                console.log("save window note / isTemplateView: " + window.get('isTemplateView'));
                if (window.get('isTemplateView')) {
                    _ember["default"].$('.modal').modal('hide');
                } else {
                    window.save().then(function () {
                        _ember["default"].$('.modal').modal('hide');
                    });
                }
            },

            cancel: function cancel() {
                _ember["default"].$('.modal').modal('hide');
                this.get('model').setProperties({
                    note: this.get('model').get('oldNote'),
                    oldNote: null
                });
            }
        }
    });
});