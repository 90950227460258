define('apollo/pods/role-group/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        notifications: _emberData['default'].hasMany('notification', { async: true }),
        tasks: _emberData['default'].hasMany('task', { async: true }),
        transportTypes: _emberData['default'].hasMany('transportTypes', { async: true }),

        name: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        canCreateTransportOfType: _emberData['default'].attr('boolean')
    });
});