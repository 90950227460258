define('apollo/pods/time-window/model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;

    var Window = _emberData['default'].Model.extend({
        ramp: belongsTo('ramp', { async: true }),
        step: belongsTo('step', { async: true }),
        transport: belongsTo('transport', { async: true }),
        company: belongsTo('company', { async: true }),

        start: attr('isodate'),
        stop: attr('isodate'),
        deleted: attr('boolean'),
        isTemplate: attr('boolean'),
        note: _emberData['default'].attr('string'),

        lastUpdated: _emberData['default'].attr('date'),
        initialStart: _emberData['default'].attr('isodate'),
        firstSavedStart: _emberData['default'].attr('isodate'),

        STATE_NAMES: {
            dedicated: 'state-dedicated',
            disabled: 'state-disabled',
            template: 'state-template',
            dedicatedTemplate: 'state-dedicated-template',
            transport: 'state-with-transport'
        },

        state: (function () {
            if (this.get('company').get("id")) {
                return this.get('isTemplate') ? this.get('STATE_NAMES').dedicatedTemplate : this.get('STATE_NAMES').dedicated;
            }
            if (!this.get('step').get('id')) {
                return this.get('isTemplate') ? this.get('STATE_NAMES').template : this.get('STATE_NAMES').disabled;
            } else {
                return this.get('STATE_NAMES').transport;
            }
        }).property('step.transport.statusClass', 'step.id', "company.id"),

        color: (function () {
            return this.get('transport') ? this.get('transport.color') : '';
        }).property('transport.color'),

        isDisabled: (function () {
            return this.get('isBlocked') || this.get('isDedicated');
        }).property('state'),

        isBlocked: (function () {
            return this.get('state') === this.get('STATE_NAMES').disabled || this.get('state') === this.get('STATE_NAMES').template;
        }).property('state'),

        isDedicated: (function () {
            return this.get('state') === this.get('STATE_NAMES').dedicated || this.get('state') === this.get('STATE_NAMES').dedicatedTemplate;
        }).property('state'),

        durationInMinutes: (function () {
            return (this.get('stop') - this.get('start')) / (1000 * 60);
        }).property('start', 'stop'),

        isInOneDay: (function () {
            return moment(this.get('start')).format('DD.MM.YYYY') === moment(this.get('stop')).subtract(1, 's').format('DD.MM.YYYY');
        }).property('start', 'stop'),

        formattedStart: (function () {
            var date = this.get('start');
            return moment(date).format('DD.MM.YYYY');
        }).property('start'),

        formattedStop: (function () {
            var date = this.get('stop');
            return moment(date).format('DD.MM.YYYY');
        }).property('stop'),

        startH: (function () {
            return moment(this.get("start")).format("HH");
        }).property('start'),

        startM: (function () {
            return moment(this.get("start")).format("mm");
        }).property('start'),

        stopH: (function () {
            return moment(this.get("stop")).format("HH");
        }).property('stop'),

        stopM: (function () {
            return moment(this.get("stop")).format("mm");
        }).property('stop'),

        setFullStart: (function () {
            var start = this.get('fullStart');
            this.set('start', start);
        }).observes('startH', 'startM', 'formattedStart'),

        fullStart: (function () {
            var d = moment(this.get("formattedStart"), 'DD.MM.YYYY').toDate();
            var h = this.get('startH');
            if (h) {
                d.setHours(h);
            }
            var m = this.get('startM');
            if (h) {
                d.setMinutes(m);
            }
            return moment(d).toDate();
        }).property('startH', 'startM', 'start'),

        setFullStop: (function () {
            var stop = this.get('fullStop');
            this.set('stop', stop);
        }).observes('stopH', 'stopM', 'formattedStop'),

        fullStop: (function () {
            var d = moment(this.get("formattedStop"), 'DD.MM.YYYY').toDate();
            var h = this.get('stopH');
            if (h) {
                d.setHours(h);
            }
            var m = this.get('stopM');
            if (h) {
                d.setMinutes(m);
            }
            return moment(d).toDate();
        }).property('stopH', 'stopM', 'stop'),

        showInitialStart: (function () {
            var firstSavedStart = Date.parse(this.get('firstSavedStart'));
            var initialStart = Date.parse(this.get('initialStart'));
            if (initialStart && firstSavedStart && firstSavedStart != initialStart) {
                return initialStart;
            }
        }).property('firstSavedStart', 'initialStart'),

        isLoad: (function () {
            return this.get('step').get('isLoad');
        }).property('step.isLoad'),

        isUnload: (function () {
            return this.get('step').get('isUnload');
        }).property('step.isUnload')
    });

    exports['default'] = Window;
});