define('apollo/pods/warehouses/schedule/route', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        sessionAccount: service('session-account'),
        cookies: service(),
        access: ['ROLE_WAREHOUSE_TAB'],
        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
                transition.abort();
                this.transitionTo('forbidden');
            }
        },

        model: function model() {
            var self = this;
            var currentUser = self.get('sessionAccount').get('currentUser');
            return _ember['default'].RSVP.hash({
                warehouses: currentUser.get('warehouses').filter(function (w) {
                    return w.get('company.id') === self.get('cookies').read('principalId');
                })
            });
        },

        activate: function activate() {
            this.set('timer', this.schedule(this.get('onPoll')));
        },
        deactivate: function deactivate() {
            _ember['default'].run.cancel(this.get('timer'));
        },

        schedule: function schedule(f) {
            return _ember['default'].run.later(this, function () {
                f.apply(this);
                this.set('timer', this.schedule(f));
            }, this.get('interval'));
        },

        onPoll: function onPoll() {
            this.controller.send('onPoll');
        },

        lastPoll: (function () {
            var interval = this.get('interval');
            return moment().subtract(interval, 'milliseconds');
        }).property(),

        interval: (function () {
            return 5000; // Time between polls (in ms)
        }).property().readOnly(),

        actions: {
            willTransition: function willTransition() {
                this.get('controller').send('removeModal');
                this.get('controller').setProperties({
                    info: null,
                    errors: null,
                    successMessage: null,
                    inProgress: false,
                    hdMode: false,
                    transportToAdvice: '',
                    scheduleClicked: false
                });
                return true;
            }
        }
    });
});
//noinspection JSLint