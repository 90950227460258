define('apollo/pods/components/file-picker/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            var self = this;

            var fileInput = new moxie.file.FileInput({
                browse_button: this.$('button').get(0),
                multiple: true
            });

            fileInput.onchange = function (e) {
                self.sendAction('action', fileInput.files);
            };

            fileInput.init();
        }
    });
});