define('apollo/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        serializeIntoHash: function serializeIntoHash(data, type, record, options) {
            console.log('serializeIntoHash');
            var object = this.serialize(record, options);
            for (var key in object) {
                data[key] = object[key];
            }
        }
    });
});