define("apollo/pods/components/transport/carrier-section/component", ["exports", "ember"], function (exports, _ember) {
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Component.extend({

        store: service(),
        cookies: service(),

        companyType: "CARRIER",

        companies: (function () {
            var self = this;
            var principalCompanyId = self.get('cookies').read('principalId');

            return self.get('store').query('company', {
                principalCompanyId: principalCompanyId,
                companyType: self.get('companyType')
            }).then(function (items) {
                return items;
            });
        }).property(),

        actions: {
            setCarrierCompanyData: function setCarrierCompanyData(item) {
                console.log("setCarrierCompanyData..");
                var self = this;
                this.get('store').findRecord('company', item.id).then(function (company) {
                    self.get("transport").set('carrierCompany', company);
                });
            },

            setCompanies: function setCompanies() {
                this.notifyPropertyChange('companies');
            }
        }
    });
});